import {createSlice} from "@reduxjs/toolkit";
import {saveMedia, deleteMedia, fetchMediaDetail} from "./asyncThunk";

const initialState = {
    media: {
        media_id: null,
        media_name: "",
    },
    redirectPath: null,
    fieldErrorFlg: {
        media_name: false,
    },
    fieldErrorTxt: {
        media_name: '',
    }
};

const slice = createSlice({
    name: "mediaRegister",
    initialState,
    reducers: {
        changeMediaData(state, action) {
            state.media = {...state.media, [action.payload.key]: action.payload.value}
        },
        changeErrorState(state, action) {
            let field = action.payload.field
            state.fieldErrorFlg[field] = action.payload.errorFlg
            state.fieldErrorTxt[field] = action.payload.errorTxt
        },
        validateMedia(state, action) {
            validateMediaField(state)
        },
        resetState(state, action) {
            state.media = initialState.media
            state.redirectPath = initialState.redirectPath
            state.fieldErrorFlg = initialState.fieldErrorFlg
            state.fieldErrorTxt = initialState.fieldErrorTxt
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchMediaDetail.fulfilled, (state, action) => {
            state.media = action.payload.data
            // validateClientField(state)
            state.redirectPath = null
        });
        builder.addCase(fetchMediaDetail.rejected, (state, action) => {
            state.media = initialState.media
            state.redirectPath = "/pages/listui/baitai"
        });
        builder.addCase(saveMedia.fulfilled, (state, action) => {
            if (action.payload.data && action.payload.data.media_id) {
                state.redirectPath = `/pages/listui/baitai/${action.payload.data.media_id}`
            }
            else {
                state.media.prc_date = action.payload.data.prc_date
            }
        });
        builder.addCase(saveMedia.rejected, (state, action) => {
            state.redirectPath = null
        });
        builder.addCase(deleteMedia.fulfilled, (state, action) => {
            state.redirectPath = "/pages/listui/baitai"
        });
        builder.addCase(deleteMedia.rejected, (state, action) => {
            state.redirectPath = null
        });
    },
});

export const listuiMediaRegister = slice.reducer;
export const {changeMediaData, changeErrorState, resetState, validateMedia} = slice.actions;

const validateMediaField = (state) => {
    for (const [key] of Object.entries(state.fieldErrorFlg)) {
        if (state.media[key].trim() === '') {
                state.fieldErrorFlg[key] = true
                state.fieldErrorTxt[key] = "この項目は必須です。"
            } else {
                state.fieldErrorFlg[key] = false
                state.fieldErrorTxt[key] = ""
            }
    }
}

