import { createSlice } from "@reduxjs/toolkit";
import { saveAccount, getAccountDetail } from "./asyncThunk";

const emptyAccount = {
  id: null,
  username: "",
  password: "",
  email: "",
  first_name: "",
  last_name: "",
  is_superuser: false,
}

// Stateの初期状態
const initialState = {
  account: emptyAccount,
  isLoading: false,
  error: {},
};

// Sliceを生成する
const slice = createSlice({
  name: "adminAccountDetail",
  initialState,
  reducers: {
    resetState (state, action) {
      state.account = emptyAccount
      state.isLoading = false
      state.error = {}
    },
    changeAccountData (state, action) {
      state.account = { ...state.account, [action.payload.key]: action.payload.value }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveAccount.fulfilled, (state, action) => {
      state.account = action.payload.data
      state.error = {}
    });
    builder.addCase(saveAccount.rejected, (state, action) => {
      state.error = action.payload
    });
    builder.addCase(getAccountDetail.fulfilled, (state, action) => {
      state.account = action.payload.data
      state.error = {}
    });
    builder.addCase(getAccountDetail.rejected, (state, action) => {
      state.error = action.payload
    });
  },
});

// Reducerをエクスポートする
export const adminAccountDetail = slice.reducer;
// Action Creatorsをエクスポートする
export const { resetState, changeAccountData } = slice.actions;
