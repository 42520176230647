import { createSlice } from "@reduxjs/toolkit";
import { getAccount, saveAccount, delAccount } from "./asyncThunk";

const emptyAccount = {
  id: null,
  login_id: "",
  password: "",
  name: "",
  group_code: null,
  start_at: null,
  expire_at: null,
  is_strict_login: false,
  is_invalid: false
}

// Stateの初期状態
const initialState = {
  account: emptyAccount,
  isLoading: false,
  error: {},
};

// Sliceを生成する
const slice = createSlice({
  name: "mapAccountDetail",
  initialState,
  reducers: {
    resetState (state, action) {
      state.account = emptyAccount
      state.isLoading = false
      state.error = {}
    },
    changeAccountData (state, action) {
      state.account = { ...state.account, [action.payload.key]: action.payload.value }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAccount.fulfilled, (state, action) => {
      state.account = action.payload.data
      state.error = {}
    });
    builder.addCase(getAccount.rejected, (state, action) => {
    });
    builder.addCase(saveAccount.fulfilled, (state, action) => {
      state.account = action.payload.data
      state.error = {}
    });
    builder.addCase(saveAccount.rejected, (state, action) => {
      state.error = action.payload
    });
    builder.addCase(delAccount.fulfilled, (state, action) => {
      state.account = action.payload.data
      state.error = {}
    });
    builder.addCase(delAccount.rejected, (state, action) => {
      state.error = action.payload
    });
  },
});

// Reducerをエクスポートする
export const mapAccountDetail = slice.reducer;
// Action Creatorsをエクスポートする
export const { resetState, changeAccountData } = slice.actions;
