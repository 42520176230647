import React from "react";
import {
  FormControl,
  InputLabel,
  FormHelperText,
  makeStyles,
  TextField,
  Button,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import {
  changeDetail,
  changeMasterDetail,
  showItemDialog,
} from "../../../stores/chart/chartStore";
import getErrorText from "../../../errors/errors";

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    width: props.width,
    height: "100%",
    display: props.display,
    padding: "10px 0px",
  }),
  control: (props) => ({
    width: props.formWidth,
  }),
  field: {
    "& .MuiInputBase-root": {
      paddingTop: 55,
    },
  },
  label: {
    height: 50,
    width: "100%",
    transform: "translate(0, 0px) scale(1)",
    borderBottom: "1px solid rgba(0, 0, 0, 0.23)",
    zIndex: 10,
  },
  title: {
    height: 50,
    display: "inline-flex",
    alignItems: "center",
    paddingLeft: 15,
    marginBottom: 5,
  },
}));

const EditTextAreaField = (props) => {
  const {
    field,
    item,
    label,
    display,
    widthPercent,
    formWidth,
    type,
    r,
  } = props;
  const { title } = item;
  var rows = r === undefined ? 2 : r;
  const dispatch = useDispatch();
  const handleValue = (field) => (event) => {
    const itemp = {
      field: field,
      value: event.target.value,
    };
    if (type !== undefined) {
      dispatch(changeMasterDetail(itemp));
    } else {
      dispatch(changeDetail(itemp));
    }
  };
  const handleSelect = () => {
    if (
      field === "mediaList" ||
      field === "anlyCol" ||
      field === "anlyRow1" ||
      field === "anlyRow2"
    ) {
      dispatch(showItemDialog({ type: true, field: field }));
    }
  };
  var obj = { width: "100%", formWidth: "100%" };
  if (display !== undefined) {
    obj["display"] = display;
  }
  if (widthPercent !== undefined) {
    obj["width"] = widthPercent + "%";
  }
  if (formWidth !== undefined) {
    obj["formWidth"] = formWidth + "%";
  }
  const classes = useStyles(obj);

  return (
    <div className={classes.root}>
      <FormControl className={classes.control} error={item["error"] !== 0}>
        <InputLabel className={classes.label} htmlFor={label + "-" + field}>
          <div className={classes.title}>{title}</div>
          <div className={classes.title}>
            <Button variant="contained" color="primary" onClick={handleSelect}>
              選択する
            </Button>
          </div>
        </InputLabel>
        <TextField
          className={classes.field}
          id={label + "-" + field}
          value={item["value"]}
          onChange={handleValue(field)}
          variant="outlined"
          disabled={true}
          multiline
          rows={rows}
        ></TextField>
        <FormHelperText>
          &nbsp;{getErrorText(title, item["error"])}
        </FormHelperText>
      </FormControl>
    </div>
  );
};

export default EditTextAreaField;
