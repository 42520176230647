import { createSlice } from "@reduxjs/toolkit";
import {
  menuIds,
  getMenuId,
  getSuperMenuId,
  superMenuIds,
} from "../../components/labels";
import Cookies from "universal-cookie";
const initialState = {
  side: 0,
  isChange: false,
  show: false,
  path: "",
};

const slice = createSlice({
  name: "HRog-menu",
  initialState,
  reducers: {
    goToTheSide: (state, action) => {
      const side = action.payload;
      const is_superuser = new Cookies("auth").get("is_superuser", {
        path: "/",
      });
      if (is_superuser === undefined || is_superuser === undefined) {
        return state;
      }

      var service = "listui";
      if (is_superuser === "true") {
        if (side === 0) {
          service = "admin";
        } else if (side <= 3) {
          service = "listui";
        } else if (side <= 6) {
          service = "listlight";
        } else if (side <= 9) {
          service = "chart";
        } else {
          service = "map";
        }
        state.path = "/pages/" + service + "/" + superMenuIds[side];
      } else {
        if (side <= 2) {
          service = "listui";
        } else if (side <= 5) {
          service = "listlight";
        } else if (side <= 8) {
          service = "chart";
        } else {
          service = "map";
        }
        state.path = "/pages/" + service + "/" + menuIds[side];
      }

      state.side = side;
      return state;
    },
    goToMenu: (state, action) => {
      const path = action.payload;
      const is_superuser = new Cookies("auth").get("is_superuser", {
        path: "/",
      });
      if (is_superuser === undefined || is_superuser === undefined) {
        return state;
      }
      var side = 0;
      if (is_superuser === "true") {
        side = getSuperMenuId(path);
      } else {
        side = getMenuId(path);
      }
      state.path = path;
      state.side = side;
      state.show = true;
      return state;
    },
    turnOffMenu: (state, action) => {
      state.show = false;
      return state;
    },
  },
});
export const { goToTheSide, turnOffMenu, goToMenu } = slice.actions;
export const menu = slice.reducer;
