import React from "react";
import {
  makeStyles,
  TextField,
  InputAdornment,
  Button,
  Backdrop,
  CircularProgress,
  LinearProgress,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CalendarToday from "@material-ui/icons/CalendarToday";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { selectChange, resetState } from "../../../stores/chart/logStore";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { downloadLog, checkLog } from "../../../stores/chart/asyncThunk";
import { setMessages } from "../../../stores/alertSnackbarStore";
import { downloadFromResponse } from "../../../helper/fileDownload";
import { unwrapResult } from "@reduxjs/toolkit";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: theme.spacing(3),
  },
  searchConatiner: {
    display: "flex",
  },
  search: {
    backgroundColor:'white',
  },
  action: {
    padding: theme.spacing(1),
  },
  progress: {
    padding: theme.spacing(1),
    height: 4,
  },
}));

const LogInput = ({ title, value, onClick }) => (
  <TextField
    label={title}
    variant="outlined"
    value={value}
    onClick={onClick}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <CalendarToday />
        </InputAdornment>
      ),
    }}
  />
);

const ChartLogPage = (props) => {
  const dispatch = useDispatch();
  const { startDate, endDate, search } = useSelector((state) => state.chartLog);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [log, setLog] = React.useState(null);
  const handleChange = (label, event) => {
    let value = null;
    if (label === "search") {
      value = event.target.value;
    } else {
      value = event;
    }
    dispatch(selectChange({ field: label, value: value }));
  };
  const handleDownload = () => {
    setOpen(true);

    dispatch(
      downloadLog({
        startDate: startDate,
        endDate: endDate,
        search: search,
      })
    )
      .then(unwrapResult)
      .then((result) => {
        console.log(result);
        const { data } = result;
        const { id, pool_id } = data;
        var d = { id: id, pool_id: pool_id };
        setLog(d);
        var interval = setInterval(() => {
          dispatch(checkLog(d))
            .then(unwrapResult)
            .then((res) => {
              const { status } = res;
              if (status !== 202) {
                setOpen(false);
                clearInterval(interval);
                downloadFromResponse(res);
                dispatch(
                  setMessages([
                    {
                      severity: "success",
                      message: "ダウンロードに成功しました。",
                    },
                  ])
                );
              }
            })
            .catch((error) => {
              clearInterval(interval);
              setOpen(false);
              console.error(error);
              dispatch(
                setMessages([
                  {
                    severity: "error",
                    message: "ダウンロードに失敗しました。",
                  },
                ])
              );
            });
        }, 60000);
      })
      .catch((error) => {
        setOpen(false);
        console.error(error);
        dispatch(
          setMessages([
            { severity: "error", message: "ダウンロードに失敗しました。" },
          ])
        );
      });
  };

  React.useEffect(() => {
    return () => {
      dispatch(resetState());
      // dispatch(removeLog(log));
      clearInterval();
    };
  }, [dispatch, log]);

  return (
    <div className={classes.root}>
      <div className={classes.searchConatiner}>
        <div className={classes.search}>
          <TextField
            label="ユーザー名"
            variant="outlined"
            value={search}
            onChange={(e) => {
              handleChange("search", e);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className={classes.search}>
          <DatePicker
            title="ログ開始日"
            dateFormat="yyyy/MM/dd"
            selected={startDate}
            onChange={(date) => handleChange("startDate", date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            maxDate={endDate}
            monthsShown={2}
            customInput={<LogInput />}
          />
        </div>
        <div className={classes.search}>
          <DatePicker
            title="ログ終了日"
            dateFormat="yyyy/MM/dd"
            selected={endDate}
            onChange={(date) => handleChange("endDate", date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            maxDate={new Date()}
            monthsShown={2}
            customInput={<LogInput />}
          />
        </div>
      </div>
      <div className={classes.progress}>
        <LinearProgress style={{ display: open ? "block" : "none" }} />
      </div>
      <div className={classes.action}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<CloudDownloadIcon />}
          onClick={handleDownload}
        >
          ダウンロード
        </Button>
      </div>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};
export default ChartLogPage;
