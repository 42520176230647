import {
  getGroupCdList,
  getAllMedia,
  registerAccount,
  getChartAccountList,
  getAccount,
  updateAccount,
  getChartMediaList,
  getChartMedia,
  updateChartMedia,
  createMaster,
  getLog,
  getLogStatus,
} from "../../api/chartApi";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchGroupCd = createAsyncThunk(
  "chart/fetchGroupCd",
  async (data, { rejectWithValue }) => {
    const resp = await getGroupCdList();
    if (resp.status < 200 || resp.status >= 300) {
      return rejectWithValue(resp);
    }
    return resp;
  }
);

export const fetchAllMedia = createAsyncThunk(
  "chart/fetchAllMedia",
  async (data, { rejectWithValue }) => {
    const resp = await getAllMedia();
    if (resp.status < 200 || resp.status >= 300) {
      return rejectWithValue(resp);
    }
    return resp;
  }
);
export const fetchAllMediaForDetail = createAsyncThunk(
  "chart/masters/fetchAllMedia",
  async (data, { rejectWithValue }) => {
    const resp = await getAllMedia();
    if (resp.status < 200 || resp.status >= 300) {
      return rejectWithValue(resp);
    }
    return resp;
  }
);
export const doRegistration = createAsyncThunk(
  "chart/register",
  async (data, { rejectWithValue }) => {
    const resp = await registerAccount(data);
    if (resp.status < 200 || resp.status >= 300) {
      return rejectWithValue(resp);
    }
    return resp;
  }
);
export const getAccountList = createAsyncThunk(
  "chart/account/list",
  async (params, { rejectWithValue }) => {
    const resp = await getChartAccountList(params);
    if (resp.status < 200 || resp.status >= 300) {
      return rejectWithValue(resp);
    }
    return resp;
  }
);
export const getAccountDetail = createAsyncThunk(
  "chart/account/getDetaill",
  async (userId, { rejectWithValue }) => {
    const resp = await getAccount(userId);
    if (resp.status < 200 || resp.status >= 300) {
      return rejectWithValue(resp);
    }
    return resp;
  }
);
export const updateAccountDetail = createAsyncThunk(
  "chart/account/updateAccountDetail",
  async (arg, { rejectWithValue }) => {
    const { id, data } = arg;
    const resp = await updateAccount(id, data);
    if (resp.status < 200 || resp.status >= 300) {
      return rejectWithValue(resp);
    }
    return resp;
  }
);
export const getMediaList = createAsyncThunk(
  "chart/masters/list",
  async (text, { rejectWithValue }) => {
    const resp = await getChartMediaList(text);
    if (resp.status < 200 || resp.status >= 300) {
      return rejectWithValue(resp);
    }
    return resp;
  }
);
export const getMedia = createAsyncThunk(
  "chart/masters/item",
  async (id, { rejectWithValue }) => {
    const resp = await getChartMedia(id);
    if (resp.status < 200 || resp.status >= 300) {
      return rejectWithValue(resp);
    }
    return resp;
  }
);
export const saveMedia = createAsyncThunk(
  "chart/masters/item/save",
  async (arg, { rejectWithValue }) => {
    const { id, data } = arg;
    const resp = await updateChartMedia(id, data);
    if (resp.status < 200 || resp.status >= 300) {
      return rejectWithValue(resp);
    }
    return resp;
  }
);

export const masterRegistration = createAsyncThunk(
  "chart/masters/register",
  async (data, { rejectWithValue }) => {
    const resp = await createMaster(data);
    if (resp.status < 200 || resp.status >= 300) {
      return rejectWithValue(resp);
    }
    return resp;
  }
);
export const downloadLog = createAsyncThunk(
  "chart/log/download",
  async (data, { rejectWithValue }) => {
    const resp = await getLog(data);
    if (resp.status < 200 || resp.status >= 300) {
      return rejectWithValue(resp);
    }
    return resp;
  }
);
export const checkLog = createAsyncThunk(
  "chart/log/download/status",
  async (data, { rejectWithValue }) => {
    const resp = await getLogStatus(data);
    if (resp.status < 200 || resp.status >= 300) {
      return rejectWithValue(resp);
    }
    return resp;
  }
);
