import React from "react";
import { Route, Redirect } from "react-router-dom";
import Cookies from "universal-cookie";
import { useDispatch, useSelector } from "react-redux";
import { checkTabs } from "../components/labels";
import { setOffShow } from "../stores/header/headerStore";
import EmptyPage from "../pages/EmptyPage";
import { turnOffMenu, goToMenu } from "../stores/menu/menuStore";

const HRogRouter = (props) => {
  const { pathname } = props["location"];
  const cookies = new Cookies("auth");
  const token = cookies.get("token", { path: "/" });
  const dispatch = useDispatch();
  const header = useSelector((state) => state.header);
  const { isChange, show } = header;
  if (pathname === "/") {
    return <Route render={(props) => <Redirect to="/pages/login" />} />;
  }
  if (checkTabs(pathname)) {
    if (!isChange && !show) {
      dispatch(goToMenu(pathname));
    }
  } else {
    dispatch(setOffShow());
  }

  return (
    <Route
      render={({ location }) => {
        return token ? (
          <EmptyPage></EmptyPage>
        ) : (
          <Redirect
            to={{
              pathname: "/pages/login",
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export const PrivateRoute = ({ children, ...rest }) => {
  const cookies = new Cookies("auth");
  const token = cookies.get("token", { path: "/" });
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.menu);
  const { show } = menu;
  const { computedMatch } = rest;
  const { url } = computedMatch;
  if (token && checkTabs(url)) {
    if (!show) {
      dispatch(goToMenu(url));
    }
  } else {
    dispatch(turnOffMenu());
  }
  return (
    <Route
      {...rest}
      render={({ location }) => {
        return token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/pages/login",
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default HRogRouter;
