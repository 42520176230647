import { createSlice } from "@reduxjs/toolkit";
import { fetchAccounts } from "./asyncThunk";

// Stateの初期状態
const initialState = {
  pageNum: 0,
  pageSize: 10,
  datas: [],
  total: 0,
  search: "",
  expireAt: null,
  sort: "name",
  asc: "asc",
  isLoading: false,
  error: null
};

// Sliceを生成する
const slice = createSlice({
  name: "mapAccountList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAccounts.fulfilled, (state, action) => {
      state.pageNum = action.meta.arg.page
      state.pageSize = action.meta.arg.limit
      state.search = action.meta.arg.search
      state.expireAt = action.meta.arg.expireAt
      state.sort = action.meta.arg.order
      state.asc = action.meta.arg.asc
      state.total = action.payload.data.total
      state.datas = action.payload.data.items
      state.error = null
    });
    builder.addCase(fetchAccounts.rejected, (state, action) => {
      state = { ...initialState }
      state.error = "データの取得に失敗しました"
    });
  },
});

// Reducerをエクスポートする
export const mapAccountList = slice.reducer;
