// Stateの初期状態
import {createSlice} from "@reduxjs/toolkit";
import {sendLoginInfo} from "../auth/asyncThunk";

const initialState = {
    username: "",
    email: "",
    token: "",
    refresh: "",
    fullname: "",
    errorFlg: false,
    isSuperUser: false,
};

// Sliceを生成する
const slice = createSlice({
    name: "login",
    initialState,
    reducers: {
        changeErrorFlg(state, action){
            state.errorFlg = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(sendLoginInfo.fulfilled, (state, action) => {
            state.username = action.payload.data.username
            state.token = action.payload.data.access
            state.refresh = action.payload.data.refresh
            state.email = action.payload.data.email
            state.fullname = action.payload.data.fullname
            state.errorFlg = false
            state.isSuperUser = action.payload.data.is_superuser
        });

        builder.addCase(sendLoginInfo.rejected, (state, action) => {
            state.errorFlg = true
        });
    },
});

// Reducerをエクスポートする
export const login = slice.reducer;
export const {changeErrorFlg} = slice.actions;