import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchMasters } from "../../../stores/listlight/asyncThunk";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
  },
  search: {
    padding: theme.spacing(1),
    
  },
  action: {
    padding: theme.spacing(1),
  },
  actionLink: {
    textDecoration: "none",
    color: "unset",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  table: {
    overflow: "auto",
    height: "calc(100vh - 200px)",
    width: "calc(100vw - 193px)",
    backgroundColor: theme.palette.background.paper,
  },
  tableCol: {
    minWidth: "100px",
  },
  pagenation: {
    backgroundColor: theme.palette.background.paper,
    width: "calc(100vw - 193px)",
  },
  button:  {
    backgroundColor:"#3f51b5",'&:hover':{
      backgroundColor: "#3f51b5",
      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)",
    },
    border: "solid #3f51b5",
    color: "white",
  },
  searchfield:{
    backgroundColor: 'white',
  }
  
}));

const MasterListPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const viewData = useSelector((state) => state.listlightMasterList);
  const classes = useStyles();

  // Similar to componentDidMount and componentDidUpdate:
  React.useEffect(() => {
    dispatch(fetchMasters({ page: 0, limit: 10 }));
  }, [dispatch]);

  const handleChangeFilter = (event) => {
    dispatch(
      fetchMasters({
        page: 0,
        limit: viewData.pageSize,
        search: event.target.value,
      })
    );
  };

  const handleChangePage = (event, newPage) => {
    dispatch(
      fetchMasters({
        page: newPage,
        limit: viewData.pageSize,
        search: viewData.search,
      })
    );
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(
      fetchMasters({
        page: viewData.pageNum,
        limit: parseInt(event.target.value, 10),
        search: viewData.search,
      })
    );
  };

  const handleClickRow = (id, event) => {
    history.push(`/pages/listlight/masters/${id}`);
  };

  return (
    <div className={classes.root}>
      <TableContainer className={classes.content}>
        <div className={classes.search}>
          <OutlinedInput 
            className={classes.searchfield}
            placeholder="テキストを入力して検索"
            onChange={handleChangeFilter}
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            }
          />
        </div>
        <div className={classes.action}>
          <Button className={classes.button}
            variant="contained"
            onClick={() => history.push(`/pages/listlight/masters/new`)}
          >
            <AddIcon />
            新規登録
          </Button>
        </div>
        <div className={classes.table}>
          <Table aria-label="customized table" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableColId} align="center">
                  ID
                </TableCell>
                <TableCell className={classes.tableCol} align="center">
                  マスタ名
                </TableCell>
                <TableCell className={classes.tableCol} align="center">
                  媒体リスト
                </TableCell>
                <TableCell className={classes.tableCol} align="center">
                  都道府県
                </TableCell>
                <TableCell className={classes.tableCol} align="center">
                  週数
                </TableCell>
                <TableCell className={classes.tableCol} align="center">
                  バージョン番号
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {viewData.datas.map((row) => (
                <TableRow
                  hover
                  key={row.id}
                  onClick={(event) => handleClickRow(row.id, event)}
                >
                  <TableCell align="center">{row.id}</TableCell>
                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="left">{row.media_names}</TableCell>
                  <TableCell align="left">{row.prefectures}</TableCell>
                  <TableCell align="left">{row.week_count}週間</TableCell>
                  <TableCell align="left">{row.version}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          className={classes.pagenation}
          component="div"
          rowsPerPageOptions={[10, 20, 30]}
          count={viewData.total}
          rowsPerPage={viewData.pageSize}
          page={viewData.pageNum}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          labelRowsPerPage="表示件数："
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
};

export default MasterListPage;
