import axios from "../components/AxiosCustomInstance";
import moment from "moment";
import Cookies from "universal-cookie";

const api_prefix = `${process.env.REACT_APP_API_URL}/api`;

export const getGroupCdList = async () => {
  const resp = await axios
    .get(`${api_prefix}/chart-groupCd`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return resp;
};

export const getAllMedia = async () => {
  const resp = await axios
    .get(`${api_prefix}/chart-analysis/allmedia`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return resp;
};

export const registerAccount = async (data) => {
  const resp = await axios
    .post(`${api_prefix}/chart/add`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return resp;
};
export const getChartAccountList = async (params) => {
  let expireParam = "";
  if (params.expireAt) {
    const fromDate = new Date(
      params.expireAt.getFullYear(),
      params.expireAt.getMonth(),
      1
    );
    const toDate = new Date(
      params.expireAt.getFullYear(),
      params.expireAt.getMonth() + 1,
      0
    );
    const from = moment(fromDate).format("YYYY/MM/DD");
    const to = moment(toDate).format("YYYY/MM/DD");
    expireParam = encodeURI(`&expire_at_before=${to}&expire_at_after=${from}`);
  }

  const resp = await axios
    .get(`${api_prefix}/chart?text=${params.text}${expireParam}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return resp;
};

export const getAccount = async (userId) => {
  const resp = await axios
    .get(`${api_prefix}/chart/${userId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return resp;
};
export const updateAccount = async (userId, data) => {
  const resp = await axios
    .put(`${api_prefix}/chart/${userId}`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return resp;
};

export const getChartMediaList = async (text) => {
  const resp = await axios
    .get(`${api_prefix}/chart-analysis/list?text=${text}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return resp;
};

export const getChartMedia = async (id) => {
  const resp = await axios
    .get(`${api_prefix}/chart-analysis/item/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return resp;
};
export const updateChartMedia = async (id, data) => {
  const resp = await axios
    .put(`${api_prefix}/chart-analysis/item/${id}`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return resp;
};
export const createMaster = async (data) => {
  const resp = await axios
    .post(`${api_prefix}/chart-analysis`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return resp;
};
export const getLog = async (params) => {
  let start = "start=";
  let end = "end=";
  let name = "name=";
  if (params.startDate) {
    const from = moment(params.startDate);
    if (from.isValid()) start = `start=${from.format("YYYY/MM/DD")}`;
  }
  if (params.endDate) {
    const to = moment(params.endDate);
    if (to.isValid()) end = `end=${to.format("YYYY/MM/DD")}`;
  }
  if (params.search) {
    name = `name=${params.search}`;
  }
  const username = new Cookies("auth").get("username", { path: "/" });
  let user_name = "username=" + username;
  let arrays = [name, start, end, user_name];
  let query = arrays.join("&");
  const resp = await axios
    .get(`${api_prefix}/chart-log?${encodeURI(query)}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return resp;
};
export const getLogStatus = async (data) => {
  const username = new Cookies("auth").get("username", { path: "/" });
  const resp = await axios
    .get(
      `${api_prefix}/chart-log/status?id=${data.id}&pool_id=${data.pool_id}&username=${username}`,
      {
        responseType: "blob",
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return resp;
};
