export const headerLabels = [
  "HRogポータル",
  "HRogリストUI",
  "Hotリスト",
  "HRogチャート",
  "HRogマップ",
];
export const sideLabels = ["マスタ", "アカウント"];
export const menuLabels = [
  "アカウント",
  "アカウント",
  "納品ファイル",
  "媒体",
  "マスタ",
  "アカウント",
  "ログ出力",
  "マスタ",
  "アカウント",
  "ログ出力",
  "アカウント",
  "ログ出力",
];
export const menuIds = [
  "clients",
  "nouhinfile",
  "baitai",
  "masters",
  "accounts",
  "logs",
  "masters",
  "accounts",
  "logs",
  "accounts",
  "logs",
];
export const superMenuIds = [
  "accounts",
  "clients",
  "nouhinfile",
  "baitai",
  "masters",
  "accounts",
  "logs",
  "masters",
  "accounts",
  "logs",
  "accounts",
  "logs",
];
export const getMenuId = (path) => {
  if (path.includes("listui")) {
    if (path.includes("clients")) {
      return 0;
    }
    if (path.includes("nouhinfile")) {
      return 1;
    }
    return 2;
  }
  if (path.includes("listlight")) {
    if (path.includes("masters")) {
      return 3;
    }
    if (path.includes("accounts")) {
      return 4;
    }
    return 5;
  }
  if (path.includes("chart")) {
    if (path.includes("masters")) {
      return 6;
    }
    if (path.includes("accounts")) {
      return 7;
    }
    return 8;
  }
  if (path.includes("map")) {
    return path.includes("accounts") ? 9 : 10;
  }
};
export const getSuperMenuId = (path) => {
  if (path.includes("admin")) {
    return 0;
  }
  if (path.includes("listui")) {
    if (path.includes("clients")) {
      return 1;
    }
    if (path.includes("nouhinfile")) {
      return 2;
    }
    return 3;
  }
  if (path.includes("listlight")) {
    if (path.includes("masters")) {
      return 4;
    }
    if (path.includes("accounts")) {
      return 5;
    }
    return 6;
  }
  if (path.includes("chart")) {
    if (path.includes("masters")) {
      return 7;
    }
    if (path.includes("accounts")) {
      return 8;
    }
    return 9;
  }
  if (path.includes("map")) {
    return path.includes("accounts") ? 10 : 11;
  }
};

export const excludeItems = ["/pages/chart/register", "/pages/login"];
const items = [
  "/pages/listui/nouhinfile",
  "/pages/listui/clients",
  "/pages/listui/baitai",
  "/pages/chart/masters",
  "/pages/chart/accounts",
  "/pages/chart/logs",
  "/pages/map/masters",
  "/pages/map/accounts",
  "/pages/map/logs",
  "/pages/listlight/masters",
  "/pages/listlight/accounts",
  "/pages/listlight/logs",
];
const includeItems2 = [
  "media",
  "clients",
  "masters",
  "accounts",
  "register",
  "logs",
  "nouhinfile",
  "baitai",
];
export const getHeaderPath = () => "";
const includeItems = ["chart", "map", "listlight", "listui", "admin"];

export const checkTabs = (path) => {
  // var check = false;
  var ps = path.split("/");
  if (ps[0] !== "") {
    return false;
  }
  if (ps[1] === "") {
    return false;
  } else {
    if (ps[1] !== "pages") {
      return false;
    }
  }
  if (ps[2] === "") {
    return false;
  } else {
    if (!includeItems.includes(ps[2])) {
      return false;
    }
  }
  if (ps[3] === "") {
    return false;
  } else {
    if (!includeItems2.includes(ps[3])) {
      return false;
    }
  }
  return true;
};

export const checkHeader = (path) => {
  return items.includes(path);
};
export const getHeaderId = (path) => {
  var headerId = 0;
  var side = 0;
  if (path.includes("/pages/listui")) {
    headerId = 0;
    if (path.includes("nouhinfile")) {
      side = 0;
    }
    if (path.includes("clients")) {
      side = 1;
    }
  } else if (path.includes("/pages/chart")) {
    headerId = 1;
    if (path.includes("masters")) {
      side = 0;
    }
    if (path.includes("accounts")) {
      side = 1;
    }
    if (path.includes("register")) {
      side = 1;
    }
  } else if (path.includes("/pages/map")) {
    headerId = 2;
    if (path.includes("masters")) {
      side = 0;
    }
    if (path.includes("logs")) {
      side = 1;
    }
    if (path.includes("accounts")) {
      side = 2;
    }
  } else if (path.includes("/pages/listlight")) {
    headerId = 3;
    if (path.includes("masters")) {
      side = 0;
    }
    if (path.includes("accounts")) {
      side = 1;
    }
  } else if (path.includes("/pages/admin")) {
    headerId = 4;
    side = 1;
  }
  return { headerId: headerId, side: side };
};
