import { createSlice } from "@reduxjs/toolkit";
import { getMaster, saveMaster, delMaster } from "./asyncThunk";
import { Master } from "../../types/listlight";

const emptyMaster: Master = {
  id: null,
  name: "",
  version: 1,
  media_names: "",
  prefectures: "",
  week_count: 4
};

// Stateの初期状態
const initialState = {
  master: emptyMaster,
  isLoading: false,
  afterSave: false,
  error: {},
};



// Sliceを生成する
const slice = createSlice({
  name: "masterDetail",
  initialState,
  reducers: {
    resetState (state, action) {
      state.master = emptyMaster
      state.isLoading = false
      state.afterSave = false
      state.error = {}
    },
    changeMasterData (state, action) {
      state.master = { ...state.master, [action.payload.key]: action.payload.value }
    },
    removeMedia (state, action) {
      const medias = state.master.media_names.split(',');
      const currentIndex = medias.indexOf(action.payload);
      if (currentIndex > -1) {
        medias.splice(currentIndex, 1);
        state.master = { ...state.master, media_names: medias.join() }
      }
    },
    removePrefecture (state, action) {
      const prefectures = state.master.prefectures.split(',');
      const currentIndex = prefectures.indexOf(action.payload);
      if (currentIndex > -1) {
        prefectures.splice(currentIndex, 1);
        state.master = { ...state.master, prefectures: prefectures.join() }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMaster.fulfilled, (state, action) => {
      state.master = action.payload.data
      state.error = {}
    });
    builder.addCase(getMaster.rejected, (state, action) => {
    });
    builder.addCase(saveMaster.fulfilled, (state, action) => {
      state.master = action.payload.data
      state.afterSave = true
      state.error = {}
    });
    builder.addCase(saveMaster.rejected, (state, action) => {
      state.error = action.payload
    });
    builder.addCase(delMaster.fulfilled, (state, action) => {
    });
    builder.addCase(delMaster.rejected, (state, action) => {
      state.error = action.payload
    });
  },
});

// Reducerをエクスポートする
export const masterDetail = slice.reducer;
export const { resetState, changeMasterData, removeMedia, removePrefecture } = slice.actions;
