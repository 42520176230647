import React from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  Grid,
  makeStyles,
  InputLabel,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import * as moment from "moment";
import {
  changeDateField,
  setExpiredTime,
} from "../../../stores/chart/chartStore";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {},
  rootInline: {
    display: "inline-block",
    width: "50%",
  },
  choice: {
    paddingLeft: 10,
  },
  container: {
    height: 102,
  },
}));

const EditDateField = (props) => {
  const { field, item, label, isInline, expiredChoice } = props;
  const { title, value, noExpired } = item;
  var classes = useStyles();
  const dispatch = useDispatch();
  var root = classes.root;
  if (isInline) {
    root = classes.rootInline;
  }
  const handleValue = (field) => (event) => {
    const itemp = {
      field: field,
      value: event === null ? new Date() : moment(event, "YYYY/MM/DD").toDate(),
    };
    dispatch(changeDateField(itemp));
  };
  const handleExpriredTime = () => {
    dispatch(setExpiredTime(field));
  };
  return (
    <div className={root}>
      <InputLabel htmlFor={label + "-" + field} className={classes.title}>
        {title}
      </InputLabel>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="flex-start" className={classes.container}>
          <KeyboardDatePicker
            margin="normal"
            id={label + "-" + field + "-picker-dialog"}
            format="yyyy/MM/dd"
            inputVariant="outlined"
            value={value}
            disabled={noExpired}
            onChange={handleValue(field)}
            maxDate={new Date("3000/01/01")}
          />
          {expiredChoice ? (
            <FormControlLabel
              className={classes.choice}
              control={
                <Checkbox checked={noExpired} onChange={handleExpriredTime} />
              }
              label="なし"
            />
          ) : (
            ""
          )}
        </Grid>
      </MuiPickersUtilsProvider>
    </div>
  );
};
export default EditDateField;
