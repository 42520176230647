import React, { useState } from "react";
import "../style/Login.scss";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Button,
  Container,
  InputLabel,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { sendLoginInfo } from "../stores/auth/asyncThunk";
import Cookies from "universal-cookie";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {setMessages} from "../stores/alertSnackbarStore";
import {changeErrorFlg} from "../stores/auth/loginStore";
import mainLogo from "../images/HRog.svg";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 90,
    padding: "10px 3px",
  },
  image: {
    height: "100%",
    width: "100%",
  },
}))

const LoginPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [showPass, setShowPass] = React.useState(false);
  const viewData = useSelector((state) => state.login);
  const cookies = new Cookies("auth");


  const [loginInfo, setLoginInfo] = useState({
    username: "",
    password: "",
  });
  const handleShowPassword = () => {
    setShowPass(!showPass);
  };
  React.useEffect(() => {
    if (viewData.token) {
      cookies.set("token", viewData.token, { path: "/" });
      cookies.set("refresh", viewData.refresh, { path: "/" });
      cookies.set("username", viewData.username, { path: "/" });
      cookies.set("email", viewData.email, { path: "/" });
      cookies.set("fullname", viewData.fullname, { path: "/" });
      cookies.set("is_superuser", viewData.isSuperUser, { path: "/" });
      window.location.reload();
      // history.push("/pages/listlight/masters");
      return;
    }
  }, [viewData, cookies, history]);

  React.useEffect(() => {
    if (viewData.errorFlg){
      dispatch(setMessages([{severity: "error", message: "ログインに失敗しました！"}]))
      dispatch(changeErrorFlg(false))
    }
  }, [viewData, dispatch])

  const handleChange = (event, field) => {
    let newLoginInfo = { ...loginInfo };
    newLoginInfo[field] = event.target.value;
    setLoginInfo(newLoginInfo);
  };

  const handleLogin = (event) => {
    if (loginInfo["username"] && loginInfo["password"]) {
      dispatch(sendLoginInfo(loginInfo));
    }
  };
  const handleEnter = (event) => {
    if (event.keyCode === 13) {
      handleLogin(event);
    }
  };
  var classN = "LoginForm";
  return (
    <div className={classN}>
      <div className={classes.logo}>
        <img className={classes.image} src={mainLogo} alt="HRog Portal Logo"></img>
      </div>
      <Container maxWidth={false} className="Container">
        <div className="PageField">
          <FormControl variant="outlined" className="FormField">
            <InputLabel htmlFor="login-username">ログインID</InputLabel>
            <OutlinedInput
              id="login-username"
              value={loginInfo["username"]}
              onChange={(event) => {
                handleChange(event, "username");
              }}
              onKeyDown={(event) => {
                handleEnter(event);
              }}
              labelWidth={110}
            />
          </FormControl>
        </div>
        <div className="PageField">
          <FormControl variant="outlined" className="FormField">
            <InputLabel htmlFor="login-password">パスワード</InputLabel>
            <OutlinedInput
              id="login-password"
              value={loginInfo["password"]}
              type={showPass ? "text" : "password"}
              onChange={(event) => {
                handleChange(event, "password");
              }}
              onKeyDown={(event) => {
                handleEnter(event);
              }}
              labelWidth={50}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleShowPassword}
                    onMouseDown={(event) => {
                      event.preventDefault();
                    }}
                    edge="end"
                  >
                    {showPass ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
        <div className="PageField">
          <Button
            variant="contained"
            color="primary"
            onClick={(event) => {
              handleLogin();
            }}
          >
            ログイン
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default LoginPage;
