import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchAccounts } from "../../stores/admin/asyncThunk";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
  },
  action: {
    padding: theme.spacing(1),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  table: {
    overflow: "auto",
    height: "calc(100vh - 52px - 16px)",
    width: "calc(100vw - 193px)",
    backgroundColor: theme.palette.background.paper,
  },
  tableCol: {
    minWidth: "100px",
  },
  button:  {
    backgroundColor:"#3f51b5",'&:hover':{
      backgroundColor: "#3f51b5",
      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)",
    },
    border: "solid #3f51b5",
    color: "white",
  }
}));

const headCells = [
  { id: "username", label: "ログインID" },
  { id: "email", label: "メールアドレス" },
  { id: "last_name", label: "姓" },
  { id: "first_name", label: "名" },
  { id: "is_superuser", label: "スーパーユーザー" },
];

const AdminAccountListPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const viewData = useSelector((state) => state.adminAccountList);
  const classes = useStyles();

  // Similar to componentDidMount and componentDidUpdate:
  React.useEffect(() => {
    dispatch(fetchAccounts());
  }, [dispatch]);

  const handleClickRow = (id, event) => {
    history.push(`/pages/admin/accounts/item/${id}`);
  };
  return (
    <div className={classes.root}>
      <TableContainer className={classes.content}>
        <div className={classes.action}>
          <Button className={classes.button}
            variant="contained"
            onClick={() => history.push(`/pages/admin/accounts/new`)}
          >
            <AddIcon />
            新規登録
          </Button>
        </div>
        <div className={classes.table}>
          <Table aria-label="customized table" stickyHeader>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    className={classes.tableCol}
                    align="center"
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {viewData.datas.map((row) => (
                <TableRow
                  hover
                  key={row.id}
                  onClick={(event) => handleClickRow(row.id, event)}
                >
                  <TableCell align="left">{row.username}</TableCell>
                  <TableCell align="left">{row.email}</TableCell>
                  <TableCell align="left">{row.last_name}</TableCell>
                  <TableCell align="left">{row.first_name}</TableCell>
                  <TableCell align="left">
                    {row.is_superuser ? "super" : ""}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </TableContainer>
    </div>
  );
};

export default AdminAccountListPage;
