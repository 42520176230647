import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchAccounts } from "../../../stores/listlight/asyncThunk";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import AddIcon from "@material-ui/icons/Add";
// import TextField from '@material-ui/core/TextField';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  getAccountTypeName,
  getNotifyFlagName,
  getisStrictLoginName,
  convertDateTime,
  getMasterName,
  getInvalidName,
} from "../../../helper/listlight/listlightHelper";
import jaLocale from "date-fns/locale/ja";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
  },
  tableBody: {
    backgroundColor: theme.palette.background.paper,
  },
  searchInput: {
    backgroundColor: theme.palette.background.paper,
  },
  search: {
    padding: theme.spacing(1),
  },
  action: {
    padding: theme.spacing(1),
  },
  actionLink: {
    textDecoration: "none",
    color: "unset",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  table: {
    overflow: "auto",
    height: "calc(100vh - 200px)",
    width: "calc(100vw - 193px)",
    backgroundColor: theme.palette.background.paper,
  },
  tableCol: {
    minWidth: "100px",
  },
  pagenation: {
    backgroundColor: theme.palette.background.paper,
    width: "calc(100vw - 193px)",
  },
  button:  {
    backgroundColor:"#3f51b5",'&:hover':{
      backgroundColor: "#3f51b5",
      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)",
    },
    border: "solid #3f51b5",
    color: "white",
  }
}));

const headCells = [
  { id: "company_name", label: "会社名" },
  { id: "account_id", label: "ログインID" },
  { id: "name", label: "ユーザー名" },
  { id: "tel", label: "電話番号" },
  { id: "email", label: "メールアドレス" },
  { id: "account_type", label: "アカウント種類" },
  { id: "notify_update_data", label: "通知フラグ" },
  { id: "is_strict_login", label: "複数デバイスでの同時ログイン" },
  { id: "start_at", label: "利用開始日" },
  { id: "expire_at", label: "利用期限" },
  { id: "master_id", label: "マスタ" },
  { id: "is_invalid", label: "無効フラグ" },
];

const AccountListPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const viewData = useSelector((state) => state.listlightAccountList);
  const classes = useStyles();
  const [selectedDate, handleDateChange] = useState(null);

  // Similar to componentDidMount and componentDidUpdate:
  React.useEffect(() => {
    dispatch(fetchAccounts({ page: 0, limit: 10 }));
  }, [dispatch]);

  const handleChangeFilter = (event) => {
    dispatch(
      fetchAccounts({
        page: 0,
        limit: viewData.pageSize,
        search: event.target.value,
        expireAt: viewData.expireAt,
        order: viewData.sort,
        asc: viewData.asc,
      })
    );
  };

  const handleChangeExpire = (date) => {
    handleDateChange(date);
    if (isNaN(date)) {
      return;
    }
    dispatch(
      fetchAccounts({
        page: 0,
        limit: viewData.pageSize,
        search: viewData.search,
        expireAt: date,
        order: viewData.sort,
        asc: viewData.asc,
      })
    );
  };

  const handleChangePage = (event, newPage) => {
    dispatch(
      fetchAccounts({
        page: newPage,
        limit: viewData.pageSize,
        search: viewData.search,
        expireAt: viewData.expireAt,
        order: viewData.sort,
        asc: viewData.asc,
      })
    );
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(
      fetchAccounts({
        page: viewData.pageNum,
        limit: parseInt(event.target.value, 10),
        search: viewData.search,
        expireAt: viewData.expireAt,
        order: viewData.sort,
        asc: viewData.asc,
      })
    );
  };

  const handleSort = (sortCol) => {
    const isAsc = viewData.sort === sortCol && viewData.asc === "asc";

    dispatch(
      fetchAccounts({
        page: viewData.pageNum,
        limit: viewData.pageSize,
        search: viewData.search,
        expireAt: viewData.expireAt,
        order: sortCol,
        asc: isAsc ? "desc" : "asc",
      })
    );
  };

  const sortDirection = (col) => {
    return viewData.sort === col ? viewData.asc : false;
  };

  const handleClickRow = (id, event) => {
    history.push(`/pages/listlight/accounts/${id}`);
  };

  return (
    <div className={classes.root}>
      <TableContainer className={classes.content}>
        <div className={classes.search}>
          <OutlinedInput
            placeholder="テキストを入力して検索"
            className={classes.searchInput}
            onChange={handleChangeFilter}
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            }
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={jaLocale}>
            <KeyboardDatePicker
              className={classes.searchInput}
              autoOk
              value={selectedDate}
              views={["month", "year"]}
              inputVariant="outlined"
              label="有効期限"
              format="yyyy/MM"
              onChange={(date) => handleChangeExpire(date)}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div className={classes.action}>
          <Button　className={classes.button}
            variant="contained"
            onClick={() => history.push(`/pages/listlight/accounts/new`)}
          >
            <AddIcon />
            新規登録
          </Button>
        </div>
        <div className={classes.table}>
          <Table aria-label="customized table" stickyHeader>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    className={classes.tableCol}
                    align="center"
                    sortDirection={sortDirection(headCell.id)}
                  >
                    <TableSortLabel
                      active={viewData.sort === headCell.id}
                      direction={
                        viewData.sort === headCell.id ? viewData.asc : "asc"
                      }
                      onClick={() => handleSort(headCell.id)}
                    >
                      {headCell.label}
                      {viewData.sort === headCell.id ? (
                        <span>{viewData.asc === "desc" ? " " : " "}</span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {viewData.datas.map((row) => (
                <TableRow
                  hover
                  key={row.id}
                  onClick={(event) => handleClickRow(row.id, event)}
                >
                  <TableCell align="left">{row.company_name}</TableCell>
                  <TableCell align="left">{row.account_id}</TableCell>
                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="left">{row.tel}</TableCell>
                  <TableCell align="left">{row.email}</TableCell>
                  <TableCell align="left">{getAccountTypeName(row)}</TableCell>
                  <TableCell align="left">{getNotifyFlagName(row)}</TableCell>
                  <TableCell align="left">
                    {getisStrictLoginName(row)}
                  </TableCell>
                  <TableCell align="left">
                    {convertDateTime(row.start_at)}
                  </TableCell>
                  <TableCell align="left">
                    {convertDateTime(row.expire_at)}
                  </TableCell>
                  <TableCell align="left">{getMasterName(row)}</TableCell>
                  <TableCell align="left">{getInvalidName(row)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          className={classes.pagenation}
          component="div"
          rowsPerPageOptions={[10, 20, 30]}
          count={viewData.total}
          rowsPerPage={viewData.pageSize}
          page={viewData.pageNum}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          labelRowsPerPage="表示件数："
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
};

export default AccountListPage;
