import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { downloadLog, downloadLogShokushu } from "../../../stores/map/asyncThunk";
import { setMessages } from "../../../stores/alertSnackbarStore";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import CalendarToday from '@material-ui/icons/CalendarToday';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import InputAdornment from '@material-ui/core/InputAdornment';
import { resetState, changeViewData } from "../../../stores/map/logDownloadStore";
import { TextField } from '@material-ui/core';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { unwrapResult } from '@reduxjs/toolkit';
import { downloadFromResponse } from '../../../helper/fileDownload';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(3),
  },
  searchConatiner: {
    display: 'flex',
  },
  search: {
    backgroundColor:"white",
    outlineColor: "solid 3f51b5",
    marginBottom: 10,
  },
  action: {
    padding: theme.spacing(1),
  },
}));

const MapLogDownloadPage: React.FC = () => {
  const dispatch = useDispatch()
  const viewData = useSelector(state => state.mapLogDownload)
  const classes = useStyles();
  
  // Similar to componentDidMount and componentDidUpdate:
  React.useEffect(() => {
    // component will unmount
    return () => dispatch(resetState());
  }, [dispatch]);

  const handleChange = (label, event) => {
    let value = null;
    if (label === 'search') {
      value = event.target.value
    } else {
      value = event
    }
    dispatch(changeViewData({key: label, value: value}));
  }
  
  const handleDownload = () => {
    dispatch(downloadLog(
      {
        startAt: viewData.startAt,
        endAt: viewData.endAt,
        search: viewData.search,
      }
    ))
    .then(unwrapResult)
    .then(result => {
      downloadFromResponse(result)
      dispatch(setMessages([{severity: "success", message: "ダウンロードに成功しました。"}]));
    })
    .catch(error => {
      console.error(error);
      dispatch(setMessages([{severity: "error", message: "ダウンロードに失敗しました。"}]));
    });
  }

  const handleDownloadShokushu = () => {
    dispatch(downloadLogShokushu(
      {
        startAt: viewData.startAt,
        endAt: viewData.endAt,
        search: viewData.search,
      }
    ))
    .then(unwrapResult)
    .then(result => {
      downloadFromResponse(result)
      dispatch(setMessages([{severity: "success", message: "ダウンロードに成功しました。"}]));
    })
    .catch(error => {
      console.error(error);
      dispatch(setMessages([{severity: "error", message: "ダウンロードに失敗しました。"}]));
    });
  }

  const ExampleCustomInput = ({ title, value, onClick }) => (
    <TextField
      label={title}
      variant="outlined"
      value={value}
      onClick={onClick}
      InputProps={{
        endAdornment:
          <InputAdornment position="end">
            <CalendarToday />
          </InputAdornment>
      }}
    />
  );

  return (
    <div className={classes.root}>
      <div className={classes.searchConatiner}>
      <div className={classes.search}>
        <TextField
          label="ユーザー名"
          variant="outlined"
          value={viewData.search}
          onChange={(e) => {handleChange("search", e)}}
          InputProps={{
            endAdornment:
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
          }}
        />
      </div>
      <div className={classes.search}>
        <DatePicker
          title="ログ開始日"
          dateFormat="yyyy/MM/dd"
          selected={viewData.startAt}
          onChange={date => handleChange("startAt", date)}
          selectsStart
          startDate={viewData.startAt}
          endDate={viewData.endAt}
          monthsShown={2}
          customInput={<ExampleCustomInput />}
        />
      </div>
      <div className={classes.search}>
        <DatePicker
          title="ログ終了日"
          dateFormat="yyyy/MM/dd"
          selected={viewData.endAt}
          onChange={date => handleChange("endAt", date)}
          selectsEnd
          startDate={viewData.startAt}
          endDate={viewData.endAt}
          minDate={viewData.startAt}
          monthsShown={2}
          customInput={<ExampleCustomInput />}
        />
      </div>
      </div>

      <div className={classes.action}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<CloudDownloadIcon />}
          onClick={handleDownload}
        >
          利用ログダウンロード
        </Button>
      </div>
      <div className={classes.action}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<CloudDownloadIcon />}
          onClick={handleDownloadShokushu}
        >
          職種ログダウンロード
        </Button>
      </div>
    </div>
  )
}
 
export default MapLogDownloadPage
