import axios from "../components/AxiosCustomInstance";
import moment from 'moment';

const api_prefix = `${process.env.REACT_APP_API_URL}/api/map`

export const getAccountList = async (params) => {
  let query = `pageNumber=${params.page}&pageSize=${params.limit}`;
  if (params.search) {
    query = query + `&text=${params.search}`;
  }
  if (params.order) {
    const ascParam = params.asc === "desc" ? "-" : "";
    query = query + `&orderBy=${params.order}&asc=${ascParam}`;
  }
  if (params.expireAt) {
    const fromDate = new Date(
      params.expireAt.getFullYear(),
      params.expireAt.getMonth(),
      1,
      0,
      0,
      0
    );
    const toDate = new Date(
      params.expireAt.getFullYear(),
      params.expireAt.getMonth() + 1,
      0,
      23,
      59,
      59
    );
    const from = moment(fromDate).format("YYYY-MM-DD HH:mm:ss");
    const to = moment(toDate).format("YYYY-MM-DD HH:mm:ss");
    query = query + `&expire_at_before=${to}&expire_at_after=${from}`;
  }

  return await axios.get(`${api_prefix}/accounts?${encodeURI(query)}`);
};

export const getAccountDetail = async (id) => {
  return await axios.get(`${api_prefix}/accounts/${id}`);
}

export const postAccount = async (account) => {
  return await axios.post(`${api_prefix}/accounts`, account);
}

export const putAccount = async (account) => {
  return await axios.put(`${api_prefix}/accounts/${account.id}`, account);
}

export const deleteAccount = async (id) => {
  return await axios.delete(`${api_prefix}/accounts/${id}`);
}

export const callDownloadLogs = async (params) => {
  const from = moment(params.startAt).format("YYYY-MM-DD")
  const to = moment(params.endAt).format("YYYY-MM-DD")
  let query = `&start_at=${from}&end_at=${to}`

  if (params.search) {
    query = query + `&search_text=${params.search}`
  }
  return await axios.get(`${api_prefix}/logs?${encodeURI(query)}`, { responseType : 'blob' });
}

export const callDownloadLogsShokushu = async (params) => {
  const from = moment(params.startAt).format("YYYY-MM-DD")
  const to = moment(params.endAt).format("YYYY-MM-DD")
  let query = `&start_at=${from}&end_at=${to}`

  if (params.search) {
    query = query + `&search_text=${params.search}`
  }
  return await axios.get(`${api_prefix}/logshokushu?${encodeURI(query)}`, { responseType : 'blob' });
}
