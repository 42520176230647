import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import TableContainer from "@material-ui/core/TableContainer";
import {deleteNouhinFile, fetchFileList, } from "../../../stores/listui/asyncThunk";
import {changeFileDownloadStatus} from "../../../stores/listui/asyncThunk";
import {makeStyles} from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import debounce from "@material-ui/core/utils/debounce";
import {unwrapResult} from "@reduxjs/toolkit";
import {setMessages} from "../../../stores/alertSnackbarStore";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmDialog from "../../../components/ConfirmDialog";
import Loader from "../../../components/loader";
import { CheckBox, CheckBoxOutlineBlankSharp, } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width: '100%',
    },
    search: {
        padding: theme.spacing(1),
    },
    searchBox: {
        backgroundColor: "white",
        width: '28%',
    },
    actionLink: {
        textDecoration: 'none',
        color: 'unset',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    table: {
        overflow: 'auto',
        height: 'calc(100vh - 150px)',
        width: 'calc(100vw - 220px)',
        // maxWidth: "98%",
        backgroundColor: "white",
        marginLeft: theme.spacing(1),
    },
    tableCol: {
        minWidth: '100px'
    },
    smallCol: {
        minWidth: '70px'
    },
    buttonCol: {
        minWidth: '30px'
    },
    tableFooter: {
        backgroundColor: theme.palette.background.paper,
        width: 'calc(100vw - 220px)',
        marginLeft: theme.spacing(1),
    },
    delBtn: {
        color: "red"
    },
    ellipsisCell: {
        overflow: 'hidden',
        whiteSpace: 'pre',
        textOverflow: 'ellipsis',
        maxWidth: '400px',
        lineHeight: '1em',
        maxHeight: '2em',
        direction: "rtl",
    },
    filepathCell: {
        maxWidth: '400px',
        overflowWrap: "break-word",
    }
}))

const initDialogState = {
    file_id: -1,
    message: "",
    open: false,
    button: "",
}

const DeliveryFileListPage: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch()
    // const history = useHistory()
    const viewData = useSelector(state => state.listuiFileList)
    const [isComposition, setIsComposition] = useState(false)
    const [confirmDialog, setConfirmDialog] = useState(initDialogState);
    const [loading, setLoading ] = useState(false);

    React.useEffect(() => {
        setLoading(true)
        dispatch(fetchFileList({page: 0, limit: 10})).finally(() => setLoading(false))
    }, [dispatch]);

    const handleClickRow = (id, event) => {
        // history.push(`/pages/listui/deliveryfile/${id}`)
    }

    const handleStartComposition = (event) => {
        setIsComposition(true)
    }

    const handleEndComposition = event => {
        setIsComposition(false)
        handleChangeSearch(event, true)
    }

    const handleChangeSearch = (event, force = false) => {
        if (!isComposition || force) {
            event.persist();
            triggerSearchDebounce(event)
        }
    }

    const triggerSearchDebounce = debounce((event) => {
        handleClickSearch(event)
    }, 1000);

    const handleClickSearch = event => {
        dispatch(
            fetchFileList(
                {
                    page: 0,
                    limit: viewData.pageSize,
                    // search: viewData.search
                    search: event.target.value
                }
            )
        )
    }

    const handleChangePage = (event, newPage) => {
        dispatch(
            fetchFileList(
                {
                    page: newPage,
                    limit: viewData.pageSize,
                    search: viewData.search
                }
            )
        )
    };

    const handleChangeRowsPerPage = (event) => {
        dispatch(
            fetchFileList(
                {
                    page: 0,
                    limit: parseInt(event.target.value, 10),
                    search: viewData.search
                }
            )
        )
    };

    const handleClickDelete = (event, file_id, file_path) => {
        let msg = (
            <div style={{overflowWrap: "break-word"}}>
                HRogリストUIから、<br/>
                {file_path}<br/>
                を削除します。<br/>
                よろしいですか？<br/>
                ※S3上のファイルは削除されません。<br/>
            </div>
        )
        setConfirmDialog({
            file_id: file_id,
            message: msg,
            open: true,
            button: "clickDeleteFile",
        })
    }

    const handleChangeFileDownloadStatus = (event, file_id, downloaded_flag) => {
        let msg = (
            <div style={{overflowWrap: "break-word"}}>
                {file_id}のダウンロードフラグを変更します。<br/>
                よろしいですか？
            </div>
        )
        setConfirmDialog({
            file_id: file_id,
            downloaded_flag: downloaded_flag,
            message: msg,
            open: true,
            button: "changeFileDownloadStatus",
        })
        
    }
   
    const handleYes = () => {
        //Change the downloaded flag 
        if(confirmDialog.button === "changeFileDownloadStatus"){
            var downloaded_flag = confirmDialog.downloaded_flag
        
            if(downloaded_flag === 0 ){
                downloaded_flag = 1;
            }else {
                downloaded_flag = 0;
            }
            setLoading(true)
            dispatch(changeFileDownloadStatus({fileId: confirmDialog.file_id, downloadedFlag: downloaded_flag}))
                .then(unwrapResult)
                .then(result => {
                    dispatch(setMessages([{severity: "success", message: "ダウンロードステータス変更しました。"}]))
                    dispatch(
                        fetchFileList(
                            {
                                page: viewData.pageNum,
                                limit: viewData.pageSize,
                                search: viewData.search
                            }
                        )
                    ).finally(()=>{
                        setLoading(false)
                    });
                })
                .catch(error => {
                    dispatch(setMessages([{severity: "error", message: "ダウンロードステータス変更失敗しました。"}]));
                    setLoading(false)
                });
                setConfirmDialog(initDialogState);      
                
              //Delete File.   
        }else if(confirmDialog.button === "clickDeleteFile"){
            setLoading(true)
            dispatch(deleteNouhinFile({fileId: confirmDialog.file_id}))
                .then(unwrapResult)
                .then(result => {
                    dispatch(setMessages([{severity: "success", message: "削除しました。"}]));
                    dispatch(
                        fetchFileList(
                            {
                                page: viewData.pageNum,
                                limit: viewData.pageSize,
                                search: viewData.search
                            }
                        )
                    ).finally(()=>{
                        setLoading(false)
                    });
                })
                .catch(error => {
                    dispatch(setMessages([{severity: "error", message: "削除に失敗しました。"}]));
                    setLoading(false)
                });
            setConfirmDialog(initDialogState);
        }
    }

    return (
        <div className={classes.root}>
            <TableContainer className={classes.content}>
                <div className={classes.search}
                     onCompositionStart={handleStartComposition}
                     onCompositionEnd={handleEndComposition}
                     onChange={handleChangeSearch}
                >
                    <OutlinedInput
                        className={classes.searchBox}
                        // placeholder="テキストを入力して検索"
                        placeholder="媒体名、クライアント名・ID、納品ファイルパスで検索"
                        // onChange={handleChangeSearch}
                        // onKeyDown={handleChangeSearch}
                        endAdornment={
                            <InputAdornment position="end">
                                <SearchIcon/>
                            </InputAdornment>
                        }
                    />
                </div>
                <div className={classes.table}>
                    <Table aria-label="customized table" stickyHeader>
                        <TableHead>
                            <TableRow>
                                {/*<TableCell align="center" className={classes.tableCol}>*/}
                                {/*    <Checkbox*/}
                                {/*        indeterminate={viewData.selectedList && viewData.selectedList.length > 0 && viewData.selectedList.length < viewData.pageSize}*/}
                                {/*        checked={viewData.selectedList && viewData.selectedList.length > 0 && viewData.selectedList.length === viewData.pageSize}*/}
                                {/*        onChange={handleCheckAllRow}*/}
                                {/*        inputProps={{'aria-label': 'Select all clients'}}*/}
                                {/*    />*/}
                                {/*</TableCell>*/}
                                <TableCell className={classes.smallCol} align="center">ファイルID</TableCell>
                                <TableCell className={classes.tableCol} align="center">納品ファイルパス</TableCell>
                                <TableCell className={classes.tableCol} align="center">ファイルサイズ</TableCell>
                                <TableCell className={classes.tableCol} align="center">クロール日</TableCell>
                                <TableCell className={classes.tableCol} align="center">納品日</TableCell>
                                <TableCell className={classes.tableCol} align="center">ダウンロード済</TableCell>
                                <TableCell className={classes.tableCol} align="center">媒体名</TableCell>
                                <TableCell className={classes.tableCol} align="center">クライアント名</TableCell>
                                <TableCell className={classes.tableCol} align="center">クライアントID</TableCell>
                                <TableCell className={classes.smallCol} align="center">変更</TableCell>
                                <TableCell className={classes.tableCol} align="center">作成者</TableCell>
                                <TableCell className={classes.tableCol} align="center">作成日</TableCell>
                                <TableCell className={classes.tableCol} align="center">変更日</TableCell>
                                <TableCell className={classes.buttonCol} align="center">削除</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {viewData.fileList.map((row) => (
                                    
                                <TableRow hover key={row.file_id}
                                          onClick={(event) => handleClickRow(row.file_id, event)}>
                                    {/*<TableCell padding="checkbox" align="center" style={{zIndex: 999}}>*/}
                                    {/*    <Checkbox*/}
                                    {/*        checked={isRowChecked(row.client_id)}*/}
                                    {/*        onChange={(event) => handleCheckRow(event, row.client_id)}*/}
                                    {/*    />*/}
                                    {/*</TableCell>*/}
                                    <TableCell align="center">{row.file_id}</TableCell>
                                    <TableCell align="center" className={classes.filepathCell}>{row.file_path}</TableCell>
                                    <TableCell align="center">{row.size}</TableCell>
                                    <TableCell align="center">{row.crawl_date}</TableCell>
                                    <TableCell align="center">{row.deliver_date}</TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            className={classes}
                                            onClick={(event) => handleChangeFileDownloadStatus(event,row.file_id,row.downloaded_flag)}
                                        >
                                            {row.downloaded_flag === 0 ? <CheckBoxOutlineBlankSharp/> : <CheckBox style={{fill:"green"}}/>}
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align="center">{row.media_name}</TableCell>
                                    <TableCell align="center">{row.client_username}</TableCell>
                                    <TableCell align="center">{row.client_userid}</TableCell>
                                    <TableCell align="center">{row.modified === 0 ? "なし" : "あり"}</TableCell>
                                    <TableCell align="center">{row.prc_user_cd}</TableCell>
                                    <TableCell align="center">{row.created_date}</TableCell>
                                    <TableCell align="center">{row.prc_date}</TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            className={classes.delBtn}
                                            onClick={(event) => handleClickDelete(event, row.file_id, row.file_path)}
                                        >
                                            <DeleteIcon/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
                <ConfirmDialog
                    msg={confirmDialog.message}
                    isOpen={confirmDialog.open}
                    doYes={handleYes}
                    doNo={() => {
                        setConfirmDialog({...confirmDialog, file_id: -1, open: false})
                    }}
                />
                <TablePagination
                    className={classes.tableFooter}
                    component='div'
                    rowsPerPageOptions={[10, 20, 30]}
                    colSpan={3}
                    count={viewData.total}
                    rowsPerPage={viewData.pageSize}
                    page={viewData.pageNum}
                    SelectProps={{
                        inputProps: {'aria-label': 'rows per page'},
                        native: true,
                    }}
                    labelRowsPerPage='表示件数：'
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                {loading && <Loader/> }
            </TableContainer>
        </div>
    )
}

export default DeliveryFileListPage