import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch } from "react-redux";
import { fetchGroupCd } from "../../../stores/chart/asyncThunk";
import {
  changeMasterDetail,
  changeDetail,
} from "../../../stores/chart/chartStore";
import getErrorText from "../../../errors/errors";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    height: "100%",
    padding: "10px 0px",
  }),
}));

const EditAutoComplete = (props) => {
  const { field, item, type } = props;
  const { title } = item;
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;
  const dispatch = useDispatch();
  const classes = useStyles();
  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      dispatch(fetchGroupCd()).then((res) => {
        const countries = res.payload.data;
        if (active) {
          setOptions(
            Object.keys(countries).map((key) => {
              return { name: countries[key] };
            })
          );
        }
      });
    })();

    return () => {
      active = false;
    };
  }, [loading, dispatch]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);
  const handleValue = (field) => (event, newValue) => {
    const itemp = {
      field: field,
      value: newValue != null ? newValue.name : "",
    };
    if (type !== undefined) {
      dispatch(changeMasterDetail(itemp));
    } else {
      dispatch(changeDetail(itemp));
    }
  };
  var val = { name: item["value"] };
  if (item["value"] === "") val = null;
  return (
    <div className={classes.root}>
      <Autocomplete
        id="asynchronous-demo"
        style={{ width: "50%" }}
        value={val}
        open={open}
        onChange={handleValue(field)}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        getOptionSelected={(option, value) => {
          return option.name === value.name;
        }}
        getOptionLabel={(option) => option.name}
        options={options}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            label={title}
            variant="outlined"
            error={item["error"] !== 0}
            helperText={" " + getErrorText(title, item["error"])}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </div>
  );
};

export default EditAutoComplete;
