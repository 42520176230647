import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  openAL,
  closeAL,
  changePageNumber,
  changePageSize,
} from "../../../stores/chart/chartALStore";
import {
  makeStyles,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TablePagination,
  OutlinedInput,
  InputAdornment,
  Button,
  // TableSortLabel,
  // ButtonGroup,
  // IconButton,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import { getMediaList } from "../../../stores/chart/asyncThunk";
import { mTitles, masterFields } from "../accounts/chartLabels";
import AddIcon from "@material-ui/icons/Add";
import { setMessages } from "../../../stores/alertSnackbarStore";
import { errorCover } from "../../../components/chart/errorCover";
// import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
// import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    "& .MuiTableContainer-root": {
      padding: 0,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: "0px",
    },
  },
  body: {
    backgroundColor: theme.palette.background.paper,
  },
  search: {
    padding: theme.spacing(1),
    paddingLeft: 0,
  },
  searchInput: {
    backgroundColor: theme.palette.background.paper,
  },
  action: {
    padding: theme.spacing(1),
    paddingLeft: 0,
    paddingBottom: theme.spacing(2),
  },
  actionLink: {
    textDecoration: "none",
    color: "unset",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  table: {
    overflow: "auto",
    height: "calc(100vh - 62px - 60px - 52px - 10px - 0px)",
    width: "calc(100vw - 193px)",
    backgroundColor: theme.palette.background.paper,
  },
  pagenation: {
    backgroundColor: theme.palette.background.paper,
    width: "calc(100vw - 193px)",
  },
  tableCol: {
    minWidth: "100px",
  },
  tableCol1: {
    minWidth: "300px",
  },
  sortContain: {
    display: "flex",
    flexDirection: "column",
    placeContent: "center",
  },
  sortBtn: {
    padding: 0,
    height: "25%",
  },
  sortText: {
    placeSelf: "center",
  },
  sortTitle: {
    display: "flex",
    placeContent: "space-between",
  },
}));

const ChartMasterList = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const item = useSelector((state) => state.chartAccountList);
  const { viewData, total, pageNumber, pageSize } = item;
  React.useEffect(() => {
    dispatch(openAL());
    dispatch(getMediaList(""))
      .then((res) => {
        var error = errorCover(res);
        if (error !== null)
          dispatch(
            setMessages([{ severity: error.severity, message: error.message }])
          );
      })
      .catch((error) => {
        dispatch(
          setMessages([
            { severity: "error", message: "データの取得に失敗しました。" },
          ])
        );
      });

    return () => {
      dispatch(closeAL());
    };
  }, [dispatch]);
  // const handleSort = (isAsc, index) => {
  //   let field = masterFields[index];
  //   console.log(field);
  // };
  const cells = mTitles.map((title, index) => {
    return (
      <TableCell
        key={index}
        className={index === 3 ? classes.tableCol1 : classes.tableCol}
        align="center"
      >
        {/* {index === 3 ? (
          title
        ) : (
          <div className={classes.sortTitle}>
            <div className={classes.sortText}>{title}</div>
            <div className={classes.sortContain}>
              <IconButton
                aria-label="up"
                className={classes.sortBtn}
                onClick={handleSort(true, index)}
                color="primary"
              >
                <ArrowDropUpIcon />
              </IconButton>
              <IconButton
                aria-label="down"
                className={classes.sortBtn}
                onClick={handleSort(false, index)}
                color="primary"
              >
                <ArrowDropDownIcon />
              </IconButton>
            </div>
          </div>
        )} */}
        {title}
      </TableCell>
    );
  });
  const rows =
    viewData.length === 0
      ? []
      : viewData.map((item, index) => {
          var row = [];
          for (let i = 0; i < masterFields.length; i++) {
            var field = masterFields[i];
            var f = item[field];
            if (f === undefined) f = "";
            if (field === "anlyWeeks") {
              f = f + "週";
            }
            if (field === "dlMaxWeek") {
              f = f + "週間";
            }
            if (field === "dlArrowFlg") {
              f = +f === 0 ? "不可" : "可";
            }
            var cell = (
              <TableCell key={field + index} align="center">
                {f}
              </TableCell>
            );
            row.push(cell);
          }
          return (
            <TableRow
              hover
              key={index}
              onClick={(event) => handleClickRow(item["mstId"], event)}
            >
              {row}
            </TableRow>
          );
        });
  const handleChangePage = (event, newPage) => {
    dispatch(changePageNumber(newPage));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(changePageSize(parseInt(event.target.value, 10)));
  };

  const handleChangeFilter = (event) => {
    dispatch(getMediaList(event.target.value));
  };
  const handleRegister = () => {
    history.push(`/pages/chart/masters/register`);
  };
  const handleClickRow = (id, event) => {
    history.push(`/pages/chart/masters/item/${id}`);
  };
  return (
    <div className={classes.root}>
      <TableContainer className={classes.content}>
        <div className={classes.search}>
          <OutlinedInput
            className={classes.searchInput}
            placeholder="テキストを入力して検索"
            onChange={handleChangeFilter}
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            }
          />
        </div>
        <div className={classes.action}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleRegister}
            startIcon={<AddIcon />}
          >
            新規登録
          </Button>
        </div>
        <div className={classes.table}>
          <Table aria-label="customized table" stickyHeader>
            <TableHead>
              <TableRow>{cells}</TableRow>
            </TableHead>
            <TableBody className={classes.body}>{rows}</TableBody>
          </Table>
        </div>
        <TablePagination
          className={classes.pagenation}
          component="div"
          rowsPerPageOptions={[10, 20, 30]}
          count={total}
          rowsPerPage={pageSize}
          page={pageNumber}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          labelRowsPerPage="表示件数："
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
};

export default ChartMasterList;
