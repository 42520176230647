import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import MediaSelectDialog from './MediaSelectDialog';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import { Paper } from '@material-ui/core';
import PrefectureSelectDialog from './PrefectureSelectDialog';
import { saveMaster } from "../../stores/listlight/asyncThunk";
import { useDispatch, useSelector } from 'react-redux';
import { Master } from "../../types/listlight"
import { resetDialogState } from "../../stores/listlight/accountDetailStore";

const useStyles = makeStyles((theme) => ({
  chipContainer: {
    minHeight: '50px',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  weekLabel: {
    verticalAlign: 'bottom'
  },
  error: {
    color: 'red'
  }
}));

const MasterDetailDialog: React.FC = (props) => {
  const { onClose, open } = props;
  const viewData = useSelector(state => state.listlightAccountDetail)
  const dispatch = useDispatch()
  const classes = useStyles();
  const [master, setMaster] = React.useState({
    name: '',
    medias: [],
    prefectures: [],
    week: 4
  });
  const [openMedia, setOpenMedia] = React.useState(false);
  const [openPrefecture, setOpenPrefecture] = React.useState(false);

  // Similar to componentDidMount and componentDidUpdate:
  React.useEffect(() => {
    if (viewData.masterDialog.isSaveSuccess) {
      handleClose();
    }
  });

  const handleChange = (prop) => (event) => {
    setMaster({ ...master, [prop]: event.target.value });
  };

  const handleMediaClose = (values) => {
    setOpenMedia(false);
    if (values === null) {
      return;
    }
    setMaster({ ...master, medias: values });
  };

  const handleDelMedia = (media, e) => {
    const currentIndex = master.medias.indexOf(media);
    const values = [...master.medias];

    if (currentIndex === -1) {
      return;
    }
    values.splice(currentIndex, 1);
    setMaster({ ...master, medias: values });
  };

  const handlePrefectureClose = (values) => {
    setOpenPrefecture(false);
    if (values === null) {
      return;
    }
    setMaster({ ...master, prefectures: values });
  };

  const handleDelPrefecture = (prefecture, e) => {
    const currentIndex = master.prefectures.indexOf(prefecture);
    const values = [...master.prefectures];

    if (currentIndex > -1) {
      return;
    }
    values.splice(currentIndex, 1);
    setMaster({ ...master, prefectures: values });
  };

  const handleClose = () => {
    resetState();
    onClose(null);
  };
  
  const resetState = () => {
    dispatch(resetDialogState());
    setMaster({
      name: '',
      medias: [],
      prefectures: [],
      week: 4
    });
  };

  const handleCommit = () => {
    const registerData: Master = {
      name: master.name,
      media_names: master.medias.join(),
      prefectures: master.prefectures.join(),
      version: 1,
      week_count: master.week      
    }
    dispatch(saveMaster({master: registerData}))
  };

  const getError = () => {
    if(viewData.masterDialog.error){
      return "error";
    } else {
      return null;
    }
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} scroll='paper'>
      <DialogTitle id="simple-dialog-title">マスタ作成</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          {getError() &&
            <Grid item xs={12}>
              <span className={classes.error}>{getError()}</span>
            </Grid>
          }
          <Grid item xs={12}>
            <FormLabel component="legend">マスタ名</FormLabel>
            <TextField
              variant="outlined"
              fullWidth
              value={master.name}
              onChange={handleChange('name')}
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel component="legend">媒体リスト</FormLabel>
            <Paper variant="outlined" className={classes.chipContainer}>
              {master.medias.map((media) => (
                <Chip key={media} label={media} onDelete={(e) => {handleDelMedia(media, e)}}/>
              ))}
            </Paper>
            <MediaSelectDialog onClose={handleMediaClose} open={openMedia} selectedMedias={master.medias}/>
            <div>
              <Button variant="contained" onClick={() => { setOpenMedia(true) }}>媒体選択</Button>
            </div>
          </Grid>
          <Grid item xs={12}>
            <FormLabel component="legend">都道府県</FormLabel>
            <Paper variant="outlined" className={classes.chipContainer}>
              {master.prefectures.map((prefecture) => (
                <Chip key={prefecture} label={prefecture} onDelete={(e) => {handleDelPrefecture(prefecture, e)}}/>
              ))}
            </Paper>
            <PrefectureSelectDialog onClose={handlePrefectureClose} open={openPrefecture} selectedPrefectures={master.prefectures}/>
            <div>
              <Button variant="contained" onClick={() => { setOpenPrefecture(true) }}>都道府県選択</Button>
            </div>
          </Grid>
          <Grid item xs={6}>
            <FormLabel component="legend">利用できる期間</FormLabel>
            <TextField
              type="number"
              variant="outlined"
              value={master.week}
              onChange={handleChange('week')}
            />
            <span className={classes.weekLabel}>週間</span>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          キャンセル
        </Button>
        <Button onClick={handleCommit} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MasterDetailDialog
