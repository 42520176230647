import React, {useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import TableContainer from "@material-ui/core/TableContainer";
import {fetchClientList} from "../../../stores/listui/asyncThunk";
import {changeSelectedList} from "../../../stores/listui/clientListStore";
import {makeStyles} from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import AddIcon from "@material-ui/icons/Add";
import debounce from "@material-ui/core/utils/debounce";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        width: "100%",
    },
    search: {
        padding: theme.spacing(1),
    },
    searchBox: {
        backgroundColor: "white",
    },
    action: {
        padding: theme.spacing(1),
        paddingTop: 0,
    },
    actionLink: {
        textDecoration: "none",
        color: "unset",
    },
    content: {
        flexGrow: 1,
        padding: 0,
        paddingLeft: theme.spacing(1),
    },
    table: {
        overflow: "auto",
        height: "calc(100vh - 72px - 52px - 52px - 16px)",
        width: "calc(100vw - 200px)",
        // width: "98%",
        backgroundColor: "white",
        marginLeft: theme.spacing(1),
    },
    tableCol: {
        minWidth: "100px",
    },
    tableFooter: {
        width: "calc(100vw - 200px)",
        marginLeft: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
    },
    ellipsisCell: {
        overflow: "hidden",
        whiteSpace: "pre",
        textOverflow: "ellipsis",
        maxWidth: "400px",
        lineHeight: "1em",
        maxHeight: "2em",
    },
}));

const ClientListPage: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const search = useRef(null)
    const viewData = useSelector(state => state.lisuiClientList)
    const [isComposition, setIsComposition] = useState(false)
    const [paginationInfo] = useState({
        pageNum: history.location.state && history.location.state.pageNum ? history.location.state.pageNum : 0,
        pageSize: history.location.state && history.location.state.pageSize? history.location.state.pageSize : 10,
        search: history.location.state && history.location.state.search? history.location.state.search : ""
    })

    React.useEffect(() => {
        dispatch(fetchClientList({
            page: paginationInfo.pageNum,
            limit: paginationInfo.pageSize,
            search: paginationInfo.search
        }))
            .then(() => {
                if (search && search.current)
                    search.current.value = paginationInfo.search
            })
    }, [dispatch, paginationInfo]);

    const handleClickRow = (id, event) => {
        history.push({
            pathname: `/pages/listui/clients/${id}`,
            state: {
                pageNum: viewData.pageNum,
                pageSize: viewData.pageSize,
                search: viewData.search
            }
        })
    }

    const handleStartComposition = (event) => {
        setIsComposition(true);
    };

    const handleEndComposition = (event) => {
        setIsComposition(false);
        handleChangeSearch(event, true);
    };

    const handleChangeSearch = (event, force = false) => {
        if (!isComposition || force) {
            event.persist();
            triggerSearchDebounce(event);
        }
    };

    const triggerSearchDebounce = debounce((event) => {
        handleClickSearch(event);
    }, 1000);

    const handleClickSearch = (event) => {
        dispatch(
            fetchClientList({
                page: 0,
                limit: viewData.pageSize,
                // search: viewData.search
                search: event.target.value,
            })
        );
        dispatch(changeSelectedList({selectedList: []}));
    };

    const handleChangePage = (event, newPage) => {
        dispatch(
            fetchClientList({
                page: newPage,
                limit: viewData.pageSize,
                search: viewData.search,
            })
        );
        dispatch(changeSelectedList({selectedList: []}));
    };

    const handleChangeRowsPerPage = (event) => {
        dispatch(
            fetchClientList({
                page: 0,
                limit: parseInt(event.target.value, 10),
                search: viewData.search,
            })
        );
        dispatch(changeSelectedList({selectedList: []}));
    };

    // const isRowChecked = (clientID) => {
    //     return viewData.selectedList.includes(clientID)
    // }

    // const handleCheckRow = (event, clientID) => {
    //     let newSelected = [...viewData.selectedList]
    //     let index = newSelected.indexOf(clientID)
    //     if (index > -1) {
    //         newSelected.splice(index, 1)
    //     } else {
    //         newSelected.push(clientID)
    //     }
    //     dispatch(changeSelectedList({selectedList: newSelected}))
    // }

    // const handleCheckAllRow = (event) => {
    //     if (viewData.clientList.length > 0 && viewData.selectedList.length < viewData.pageSize) {
    //         let allIdArray = viewData.clientList.map(client => client.client_id)
    //         dispatch(changeSelectedList({selectedList: allIdArray}))
    //     }
    //     if (viewData.clientList.length > 0 && viewData.selectedList.length === viewData.pageSize) {
    //         dispatch(changeSelectedList({selectedList: []}))
    //     }
    // }

    const handleClickNew = (event) => {
        history.push({
            pathname: `/pages/listui/clients/register`,
            state: {
                pageNum: viewData.pageNum,
                pageSize: viewData.pageSize,
                search: viewData.search
            }
        })
    }

    return (
        <div className={classes.root}>
            <TableContainer className={classes.content}>
                <div
                    className={classes.search}
                    onCompositionStart={handleStartComposition}
                    onCompositionEnd={handleEndComposition}
                    onChange={handleChangeSearch}
                >
                    <OutlinedInput
                        inputRef={search}
                        className={classes.searchBox}
                        placeholder="テキストを入力して検索"
                        endAdornment={
                            <InputAdornment position="end">
                                <SearchIcon/>
                            </InputAdornment>
                        }
                    />
                </div>
                <div className={classes.action}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClickNew}
                        startIcon={<AddIcon/>}
                    >
                        新規登録する
                    </Button>
                </div>
                <div className={classes.table}>
                    <Table aria-label="customized table" stickyHeader>
                        <TableHead>
                            <TableRow>
                                {/*<TableCell align="center" className={classes.tableCol}>*/}
                                {/*    <Checkbox*/}
                                {/*        indeterminate={viewData.selectedList && viewData.selectedList.length > 0 && viewData.selectedList.length < viewData.pageSize}*/}
                                {/*        checked={viewData.selectedList && viewData.selectedList.length > 0 && viewData.selectedList.length === viewData.pageSize}*/}
                                {/*        onChange={handleCheckAllRow}*/}
                                {/*        inputProps={{'aria-label': 'Select all clients'}}*/}
                                {/*    />*/}
                                {/*</TableCell>*/}
                                <TableCell className={classes.tableCol} align="center">
                                    ログインID
                                </TableCell>
                                <TableCell className={classes.tableCol} align="center">
                                    ユーザーパスワード
                                </TableCell>
                                <TableCell className={classes.tableCol} align="center">
                                    ユーザー名
                                </TableCell>
                                <TableCell className={classes.tableCol} align="center">
                                    ディレクトリ名
                                </TableCell>
                                <TableCell className={classes.tableCol} align="center">
                                    納品媒体
                                </TableCell>
                                <TableCell className={classes.tableCol} align="center">
                                    作成者
                                </TableCell>
                                <TableCell className={classes.tableCol} align="center">
                                    作成日
                                </TableCell>
                                <TableCell className={classes.tableCol} align="center">
                                    更新日
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {viewData.clientList.map((row) => (
                                <TableRow
                                    hover
                                    key={row.client_id}
                                    onClick={(event) => handleClickRow(row.client_id, event)}
                                >
                                    {/*<TableCell padding="checkbox" align="center" style={{zIndex: 999}}>*/}
                                    {/*    <Checkbox*/}
                                    {/*        checked={isRowChecked(row.client_id)}*/}
                                    {/*        onChange={(event) => handleCheckRow(event, row.client_id)}*/}
                                    {/*    />*/}
                                    {/*</TableCell>*/}
                                    <TableCell align="center">{row.user_id}</TableCell>
                                    <TableCell align="center">{row.user_pass}</TableCell>
                                    <TableCell align="center">{row.user_name}</TableCell>
                                    <TableCell align="center">{row.dir_name}</TableCell>
                                    <TableCell align="left" className={classes.ellipsisCell}></TableCell>
                                    <TableCell align="center">{row.prc_user_cd}</TableCell>
                                    <TableCell align="center">{row.created_date}</TableCell>
                                    <TableCell align="center">{row.prc_date}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    className={classes.tableFooter}
                    component="div"
                    rowsPerPageOptions={[10, 20, 30]}
                    colSpan={3}
                    count={viewData.total}
                    rowsPerPage={viewData.pageSize}
                    page={viewData.pageNum}
                    SelectProps={{
                        inputProps: {"aria-label": "rows per page"},
                        native: true,
                    }}
                    labelRowsPerPage="表示件数："
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </TableContainer>
        </div>
    );
};

export default ClientListPage;
