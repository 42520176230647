import axios from "../components/AxiosCustomInstance";

const api_prefix = `${process.env.REACT_APP_API_URL}/api/admin`;

export const getAccountList = async () => {
  return await axios.get(`${api_prefix}/sysusers/`);
};

export const postAccount = async (account) => {
  return await axios.post(`${api_prefix}/sysusers/`, account);
};
export const putAccount = async (params) => {
  const resp = await axios
    .put(`${api_prefix}/sysusers/${params["id"]}/`, params)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return resp;
};
export const getAccount = async (id) => {
  const resp = await axios
    .get(`${api_prefix}/sysusers/${id}/`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return resp;
};
