import { createSlice } from "@reduxjs/toolkit";
import { fetchAllMediaForDetail } from "./asyncThunk";

const initialState = {
  mediaFetched: false,
  
  objList: {
    mediaList: [],
  },
  selectObj: {
    mediaList: [],
  },
  rememberObj: {
    mediaList: [],
  },
  allObj: {
    mediaList: false,
  },
};

// Sliceを生成する
const slice = createSlice({
  name: "listLightMediaSelectList",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllMediaForDetail.fulfilled, (state, action) => {
      const { data } = action.payload;
      for (let f in data) {
        state.objList[f] = data[f];
      }
      state.mediaFetched = true;
      return state;
    });
    builder.addCase(fetchAllMediaForDetail.rejected, (state, action) => {
      state.mediaFetched = false;
      return state;
    });
  },
});

export const listLightMediaSelectList = slice.reducer;
