import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAccountList, postAccount, putAccount, getAccount } from "../../api/adminApi";

export const fetchAccounts = createAsyncThunk(
  "admin/fetchAccounts",
  async (arg: {}, { rejectWithValue }) => {
    try {
      return await getAccountList();
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const saveAccount = createAsyncThunk(
  "admin/saveAccount",
  async (arg: { account: any }, { rejectWithValue }) => {
    try {
      return await postAccount(arg.account);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateAccount = createAsyncThunk(
  "admin/updateAccount",
  async (params, { rejectWithValue }) => {
    try {
      return await putAccount(params);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getAccountDetail = createAsyncThunk(
  "admin/getAccount",
  async (id, { rejectWithValue }) => {
    try {
      return await getAccount(id);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
