import { createSlice } from "@reduxjs/toolkit";
import {
  getAccountDetail,
  updateAccountDetail,
  getMedia,
  saveMedia,
  fetchAllMediaForDetail,
  masterRegistration,
  fetchGroupCd,
} from "./asyncThunk";
import { checkError } from "../../errors/errors";
import * as moment from "moment";
const initialState = {
  item: {
    userNm: {
      value: "",
      checkTypes: [1, 2],
      error: 0,
      mod: false,
      title: "ユーザー名",
      labelWidth: 100,
    },
    userId: {
      value: "",
      checkTypes: [1, 2],
      error: 0,
      mod: false,
      title: "ユーザーID",
      labelWidth: 100,
    },
    password: {
      value: "",
      checkTypes: [1, 2],
      error: 0,
      mod: false,
      title: "パスワード",
      labelWidth: 100,
    },
    groupCd: {
      value: "",
      checkTypes: [1],
      error: 0,
      mod: false,
      title: "グループコード",
      labelWidth: 150,
    },
    acType: {
      value: "",
      checkTypes: [1],
      error: 0,
      mod: false,
      title: "アカウント種類",
    },
    sdate: {
      value: new Date(),
      checkTypes: [1, 5],
      error: 0,
      mod: true,
      noExpired: false,
      title: "利用開始日",
    },
    edate: {
      value: new Date(),
      checkTypes: [1, 5],
      error: 0,
      mod: true,
      noExpired: true,
      title: "利用期限",
    },
    acStatus: {
      value: "",
      checkTypes: [1],
      error: 0,
      mod: false,
      title: "ステータス",
    },
    ignoreSession: {
      value: "",
      checkTypes: [1],
      error: 0,
      mod: false,
      title: "複数デバイスでの同時ログイン",
    },
  },
  mitem: {
    mstNm: {
      value: "",
      checkTypes: [1, 2],
      error: 0,
      mod: false,
      title: "マスタ名",
      labelWidth: 70,
    },
    groupCd: {
      value: "",
      checkTypes: [1, 2],
      error: 0,
      mod: false,
      title: "グループコード",
      labelWidth: 120,
    },
    startDate: {
      value: moment().subtract(1, "week").day("Monday").toDate(),
      checkTypes: [1, 5, 6],
      error: 0,
      mod: true,
      noExpired: false,
      title: "利用開始日",
      labelWidth: 90,
    },
    dlMaxWeek: {
      value: "",
      checkTypes: [1, 20, 21],
      error: 0,
      mod: false,
      title: "利用期限",
      labelWidth: 90,
    },
    anlyWeeks: {
      value: "",
      checkTypes: [1, 20, 21],
      error: 0,
      mod: false,
      title: "集計期間",
      labelWidth: 90,
    },
    dlArrowFlg: {
      value: "",
      checkTypes: [1, 2],
      error: 0,
      mod: false,
      title: "ダウンロード可否",
      labelWidth: 150,
    },
    mediaList: {
      value: "",
      checkTypes: [1],
      error: 0,
      mod: false,
      title: "媒体名",
      labelWidth: 70,
    },
    anlyCol: {
      value: "",
      checkTypes: [1],
      error: 0,
      mod: false,
      title: "列の集計項目",
      labelWidth: 120,
    },
    anlyRow1: {
      value: "",
      checkTypes: [1],
      error: 0,
      mod: false,
      title: "行の集計項目1",
      labelWidth: 120,
    },
    anlyRow2: {
      value: "",
      checkTypes: [1],
      error: 0,
      mod: false,
      title: "行の集計項目2",
      labelWidth: 120,
    },
  },
  options: {
    author: {
      value: "",
      title: "作成者",
    },
    prcDt: {
      value: "",
      title: "作成日",
    },
    upDt: {
      value: "",
      title: "変更日",
    },
  },
  moptions: {
    author: {
      value: "",
      title: "作成者",
    },
    prcDate: {
      value: "",
      title: "作成日",
    },
    upDate: {
      value: "",
      title: "変更日",
    },
  },
  statusList: [
    {
      value: 0,
      label: "利用",
    },
    {
      value: 1,
      label: "未利用",
    },
  ],
  multiLogins: [
    {
      value: 1,
      label: "可",
    },
    {
      value: 0,
      label: "不可",
    },
  ],
  downloadOption: [
    {
      value: 1,
      label: "可",
    },
    {
      value: 0,
      label: "不可",
    },
  ],
  acTypeArr: [
    { label: "free", value: 0 },
    { label: "paid", value: 1 },
    { label: "demo", value: 9 },
  ],
  groupCdArr: [],
  isNotice: false,
  noticeType: 0,
  message: "",
  onDetail: false,
  mediaFetched: false,
  lastModDate: null,
  openDialog: {
    mediaList: false,
    anlyCol: false,
    anlyRow1: false,
    anlyRow2: false,
  },
  objList: {
    mediaList: [],
    anlyCol: [],
    anlyRow1: [],
    anlyRow2: [],
  },
  selectObj: {
    mediaList: [],
    anlyCol: [],
    anlyRow1: [],
    anlyRow2: [],
  },
  rememberObj: {
    mediaList: [],
    anlyCol: [],
    anlyRow1: [],
    anlyRow2: [],
  },
  allObj: {
    mediaList: false,
    anlyCol: false,
    anlyRow1: false,
    anlyRow2: false,
  },
};

const slice = createSlice({
  name: "chart/detail",
  initialState,
  reducers: {
    openDetail: (state, action) => {
      state.onDetail = true;
      return state;
    },
    checkErrorForAll: (state, action) => {
      for (let field in state.item) {
        const { value } = state.item[field];
        state.item[field]["error"] = checkError(
          value,
          state.item[field]["checkTypes"]
        );
      }
      return state;
    },
    checkMasterErrorForAll: (state, action) => {
      for (let field in state.mitem) {
        const { value } = state.mitem[field];
        state.mitem[field]["error"] = checkError(
          value,
          state.mitem[field]["checkTypes"]
        );
      }
      return state;
    },
    clearNoticeDetail: (state, action) => {
      state.isNotice = false;
      state.noticeType = 0;
      state.message = 0;
      return state;
    },
    closeDetail: (state, action) => {
      state = initialState;
      return state;
    },
    changeDateField: (state, action) => {
      const { field, value } = action.payload;
      state.item[field]["error"] = checkError(
        value,
        state.item[field]["checkTypes"]
      );
      state.item[field]["value"] = value;
      return state;
    },
    setExpiredTime: (state, action) => {
      state.item[action.payload]["noExpired"] = !state.item[action.payload][
        "noExpired"
      ];
      return state;
    },
    selectAllItem: (state, action) => {
      const { field, value } = action.payload;
      state.allObj[field] = value;
      return state;
    },
    changeDetail: {
      reducer: (state, action) => {
        const { field, value } = action.payload;
        state.item[field]["error"] = checkError(
          value,
          state.item[field]["checkTypes"]
        );
        state.item[field]["value"] = value;
        state.item[field]["mod"] = true;
        return state;
      },
    },
    changeMasterDetail: {
      reducer: (state, action) => {
        const { field, value } = action.payload;
        state.mitem[field]["error"] = checkError(
          value,
          state.mitem[field]["checkTypes"]
        );
        state.mitem[field]["mod"] = true;
        state.mitem[field]["value"] = value;
        return state;
      },
    },
    showMediaDialog: (state, action) => {
      const { type, array } = action.payload;
      state.mediaDialog = type;
      if (type === false) {
        state.selectedMedia = state.selectedMedia.filter(
          (v) => !array.includes(v)
        );
      }
      return state;
    },
    showItemDialog: (state, action) => {
      const { type, field, isConfirm } = action.payload;
      state.openDialog[field] = type;
      if (type === true) {
        if (state.mediaFetched)
          state.selectObj[field] = state.rememberObj[field];
      } else {
        if (isConfirm !== undefined && isConfirm === true) {
          state.rememberObj[field] = state.selectObj[field];
        }
        state.selectObj[field] = [];
      }
      return state;
    },
    colSelect: (state, action) => {
      const { array, field } = action.payload;
      state.selectObj[field] = array;
      return state;
    },
    refreshSelect: (state, action) => {
      const { field } = action.payload;
      let a = state.mitem[field].value.split(",");
      state.selectObj[field] = a.filter((value) => value !== "");
      return state;
    },
    setCheckTypes: (state, action) => {
      const { field, checkTypes } = action.payload;
      state.item[field]["checkTypes"] = checkTypes;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAccountDetail.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.item = putItemObj(state.item, data);
      state.options = putItemObj(state.options, data);
      return state;
    });
    builder.addCase(getAccountDetail.rejected, (state, action) => {
      state.isNotice = true;
      return state;
    });
    builder.addCase(updateAccountDetail.fulfilled, (state, action) => {
      return state;
    });
    builder.addCase(updateAccountDetail.rejected, (state, action) => {
      return state;
    });
    builder.addCase(getMedia.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.mitem = putItemObj(state.mitem, data);
      state.moptions = putItemObj(state.moptions, data);
      if (moment(data["lastModDate"], "YYYY/MM/DD").isValid()) {
        state.lastModDate = data["lastModDate"];
        state.moptions["upDate"]["value"] = state.lastModDate
      }
      for (let f in state.objList) {
        let a = state.mitem[f].value.split(",");
        state.selectObj[f] = a.filter((value) => value !== "");
        state.rememberObj[f] = a.filter((value) => value !== "");
      }
      return state;
    });
    builder.addCase(getMedia.rejected, (state, action) => {
      return state;
    });
    builder.addCase(fetchAllMediaForDetail.fulfilled, (state, action) => {
      const { data } = action.payload;
      for (let f in data) {
        state.objList[f] = data[f];
      }
      state.mediaFetched = true;
      return state;
    });
    builder.addCase(fetchAllMediaForDetail.rejected, (state, action) => {
      state.mediaFetched = false;
      return state;
    });
    builder.addCase(saveMedia.fulfilled, (state, action) => {
      return state;
    });
    builder.addCase(saveMedia.rejected, (state, action) => {
      return state;
    });
    builder.addCase(masterRegistration.fulfilled, (state, action) => {
      return state;
    });
    builder.addCase(masterRegistration.rejected, (state, action) => {
      return state;
    });
    builder.addCase(fetchGroupCd.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.groupCdArr = data.map((v) => {
        return { label: v, value: v };
      });
      return state;
    });
    builder.addCase(fetchGroupCd.rejected, (state, action) => {
      return state;
    });
  },
});

const putItemObj = (obj, item) => {
  for (let field in obj) {
    obj[field]["value"] = item[field];
    if (field === "acStatus") {
      obj[field]["value"] = parseInt(item[field]);
      if (Number.isNaN(obj[field]["value"])) {
        obj[field]["value"] = "";
        obj[field]["error"] = 1;
      }
    }
    if (field === "ignoreSession") {
      obj[field]["value"] = parseInt(item[field]);
      if (Number.isNaN(obj[field]["value"])) {
        obj[field]["value"] = "";
        obj[field]["error"] = 1;
      }
    }
    if (field === "dlArrowFlg") {
      obj[field]["value"] = parseInt(item[field]);
      if (Number.isNaN(obj[field]["value"])) {
        obj[field]["value"] = "";
        obj[field]["error"] = 1;
      }
    }
    if (field === "dlMaxWeek") {
      obj[field]["value"] = parseInt(item[field]);
      if (Number.isNaN(obj[field]["value"])) {
        obj[field]["value"] = "";
        obj[field]["error"] = 1;
      }
    }
    if (field === "edate") {
      if (item[field] === "3000/01/01") {
        obj[field]["value"] = new Date();
        obj[field]["noExpired"] = true;
      } else {
        obj[field]["noExpired"] = false;
      }
    }
    if (field === "startDate") {
      if (moment(obj[field]["value"], "YYYY/MM/DD").isValid()) {
        obj[field]["value"] = moment(
          obj[field]["value"],
          "YYYY/MM/DD"
        ).toDate();
      } else {
        obj[field]["value"] = "";
      }
    }
  }
  for (let field in obj) {
    if (obj[field]["value"] === undefined) {
      obj[field]["value"] = "";
    }
  }
  return obj;
};
export const chartDetail = slice.reducer;

export const {
  openDetail,
  closeDetail,
  changeDetail,
  changeDateField,
  setExpiredTime,
  clearNoticeDetail,
  changeMasterDetail,
  showItemDialog,
  colSelect,
  refreshSelect,
  selectAllItem,
  checkErrorForAll,
  checkMasterErrorForAll,
  setCheckTypes,
} = slice.actions;
