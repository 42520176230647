import {createSlice} from "@reduxjs/toolkit";
import {deleteNouhinFile, fetchFileList} from "./asyncThunk";

const initialState = {
    pageNum: 0,
    pageSize: 10,
    fileList: [],
    selectedList: [],
    total: 0,
    search: "",
    isLoading: false,
    error: null
};

const slice = createSlice({
    name: "fileList",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(fetchFileList.fulfilled, (state, action) => {
            state.pageNum = action.meta.arg.page
            state.pageSize = action.meta.arg.limit
            state.search = action.meta.arg.search
            state.total = action.payload.data.count
            state.fileList = action.payload.data.results
            state.selectedList = []
        });
        builder.addCase(fetchFileList.rejected, (state, action) => {
            state.fileList = []
            state.selectedList = []
            state.error = "納品ファイル情報を取得できません。"
            state.search = action.meta.arg.search
            state.pageNum = action.meta.arg.page
            state.pageSize = action.meta.arg.limit
        });
        builder.addCase(deleteNouhinFile.fulfilled, (state, action) => {
        });
    },
});

export const listuiFileList = slice.reducer;