import { createSlice } from "@reduxjs/toolkit";

export type SnackbarMessage = {
  severity: string,
  message: string,
}

// Stateの初期状態
const initialState = {
  messages: [],
  opend: false,
};

// Sliceを生成する
const slice = createSlice({
  name: "alertSnackBar",
  initialState,
  reducers: {
    setMessages (state, action) {
      state.messages = action.payload
      state.opend = true
    },
    closeSnackBar (state, action) {
      state.opend = false
    },
  },
});

// Reducerをエクスポートする
export const alertSnackBar = slice.reducer;
// Action Creatorsをエクスポートする
export const { setMessages, closeSnackBar } = slice.actions;
