import { createSlice } from "@reduxjs/toolkit";
import { fetchMasters } from "./asyncThunk";

// Stateの初期状態
const initialState = {
  pageNum: 0,
  pageSize: 10,
  datas: [],
  total: 0,
  search: "",
  isLoading: false
};

// Sliceを生成する
const slice = createSlice({
  name: "masterList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMasters.fulfilled, (state, action) => {
      state.pageNum = action.meta.arg.page
      state.pageSize = action.meta.arg.limit
      state.search = action.meta.arg.search
      state.total = action.payload.data.count
      state.datas = action.payload.data.results
    });
    builder.addCase(fetchMasters.rejected, (state, action) => {
    });
  },
});

// Reducerをエクスポートする
export const masterList = slice.reducer;
