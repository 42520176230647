import { createSlice } from "@reduxjs/toolkit";
import { fetchAccounts } from "./asyncThunk";

// Stateの初期状態
const initialState = {
  datas: [],
  isLoading: false,
  error: null
};

// Sliceを生成する
const slice = createSlice({
  name: "adminAccountList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAccounts.fulfilled, (state, action) => {
      state.datas = action.payload.data
      state.error = null
    });
    builder.addCase(fetchAccounts.rejected, (state, action) => {
      state = { ...initialState }
      state.error = "データの取得に失敗しました"
    });
  },
});

// Reducerをエクスポートする
export const adminAccountList = slice.reducer;
