import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccountList } from "../../../stores/chart/asyncThunk";
import {
  openAL,
  closeAL,
  changePageNumber,
  changePageSize,
} from "../../../stores/chart/chartALStore";
import {
  makeStyles,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TablePagination,
  OutlinedInput,
  InputAdornment,
  Button,
} from "@material-ui/core";
import { titles, userFields } from "./chartLabels";
import { useHistory } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import { setMessages } from "../../../stores/alertSnackbarStore";
import { errorCover } from "../../../components/chart/errorCover";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import jaLocale from "date-fns/locale/ja";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    "& .MuiTableContainer-root": {
      padding: 0,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: "0px",
    },
  },
  body: {
    backgroundColor: theme.palette.background.paper,
  },
  search: {
    padding: theme.spacing(1),
    paddingLeft: 0,
  },
  searchInput: {
    backgroundColor: theme.palette.background.paper,
  },
  action: {
    padding: theme.spacing(1),
    paddingLeft: 0,
    paddingBottom: theme.spacing(2),
  },
  actionLink: {
    textDecoration: "none",
    color: "unset",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  table: {
    overflow: "auto",
    height: "calc(100vh - 62px - 60px - 52px - 10px - 0px)",
    width: "calc(100vw - 193px)",
    backgroundColor: theme.palette.background.paper,
  },
  tableCol: {
    minWidth: "100px",
  },
  pagenation: {
    backgroundColor: theme.palette.background.paper,
    width: "calc(100vw - 193px)",
  },
}));

const ChartAccountList = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const item = useSelector((state) => state.chartAccountList);
  const { viewData, total, pageNumber, pageSize, acTypeArr } = item;
  const [filterText, setFilterText] = useState("");
  const [selectedDate, handleDateChange] = useState(null);

  React.useEffect(() => {
    dispatch(openAL());
    dispatch(getAccountList({ text: "", expireAt: null }))
      .then((res) => {
        var error = errorCover(res);
        if (error !== null)
          dispatch(
            setMessages([{ severity: error.severity, message: error.message }])
          );
      })
      .catch((error) => {
        dispatch(
          setMessages([
            { severity: "error", message: "データの取得に失敗しました。" },
          ])
        );
      });

    return () => {
      dispatch(closeAL());
    };
  }, [dispatch]);
  const cells = titles.map((title, index) => {
    return (
      <TableCell key={index} className={classes.tableCol} align="center">
        {title}
      </TableCell>
    );
  });
  const rows =
    viewData.length === 0
      ? []
      : viewData.map((item, index) => {
          var row = [];
          for (let i = 0; i < userFields.length; i++) {
            var field = userFields[i];
            var f = item[field];
            if (field === "ignoreSession") {
              f = +item[field] === 0 ? "不可" : "可";
            }
            if (field === "acType") {
              for (let index = 0; index < acTypeArr.length; index++) {
                const element = acTypeArr[index];
                if (element["value"] === +f) {
                  f = element["label"];
                  break;
                }
              }
            }
            if (field === "acStatus") {
              f = +item[field] === 1 ? "未利用" : "利用";
            }

            var cell = (
              <TableCell key={field + index} align="center">
                {f}
              </TableCell>
            );
            row.push(cell);
          }
          return (
            <TableRow
              hover
              key={index}
              onClick={(event) => handleClickRow(item["userId"], event)}
            >
              {row}
            </TableRow>
          );
        });
  const handleChangePage = (event, newPage) => {
    dispatch(changePageNumber(newPage));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(changePageSize(parseInt(event.target.value, 10)));
  };

  const handleChangeFilter = (event) => {
    setFilterText(event.target.value);
    dispatch(
      getAccountList({ text: event.target.value, expireAt: selectedDate })
    );
  };
  const handleChangeExpire = (date) => {
    handleDateChange(date);
    if (isNaN(date)) {
      return;
    }
    dispatch(getAccountList({ text: filterText, expireAt: date }));
  };
  const handleRegister = () => {
    history.push("/pages/chart/register");
  };
  const handleClickRow = (id, event) => {
    history.push(`/pages/chart/accounts/${id}`);
  };
  return (
    <div className={classes.root}>
      <TableContainer className={classes.content}>
        <div className={classes.search}>
          <OutlinedInput
            value={filterText}
            placeholder="テキストを入力して検索"
            className={classes.searchInput}
            onChange={handleChangeFilter}
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            }
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={jaLocale}>
            <KeyboardDatePicker
              className={classes.searchInput}
              autoOk
              value={selectedDate}
              views={["month", "year"]}
              inputVariant="outlined"
              label="有効期限"
              format="yyyy/MM"
              onChange={(date) => handleChangeExpire(date)}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div className={classes.action}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleRegister}
            startIcon={<AddIcon />}
          >
            新規登録
          </Button>
        </div>
        <div className={classes.table}>
          <Table aria-label="customized table" stickyHeader>
            <TableHead>
              <TableRow>{cells}</TableRow>
            </TableHead>
            <TableBody className={classes.body}>{rows}</TableBody>
          </Table>
        </div>
        <TablePagination
          className={classes.pagenation}
          component="div"
          rowsPerPageOptions={[10, 20, 30]}
          count={total}
          rowsPerPage={pageSize}
          page={pageNumber}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          labelRowsPerPage="表示件数："
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
};

export default ChartAccountList;
