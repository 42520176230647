import React from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { makeStyles, Button, Container, Paper } from "@material-ui/core";
import * as moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  openDetail,
  closeDetail,
  checkMasterErrorForAll,
} from "../../../stores/chart/chartStore";
import {
  getMedia,
  saveMedia,
  masterRegistration,
  fetchAllMediaForDetail,
} from "../../../stores/chart/asyncThunk";
import EditTextField from "../../../components/chart/edit/EditTextField";
import EditRadioField from "../../../components/chart/edit/EditRadioField";
import EditTextAreaField from "../../../components/chart/edit/EditTextAreaField";
import SubdirectoryArrowLeftIcon from "@material-ui/icons/SubdirectoryArrowLeft";
import ColDialog from "../../../components/chart/ColDialog";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { setMessages } from "../../../stores/alertSnackbarStore";
import { errorCover } from "../../../components/chart/errorCover";
import Cookies from "universal-cookie";
import MasterDateField from "../../../components/chart/edit/MasterDateField";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    overflow: "auto",
  },
  container: {
    padding: 70,
  },
  paper: {
    padding: 75,
    paddingBottom: 0,
  },
  pTab: {
    display: "inline",
    margin: 10,
  },
  info: {
    padding: 10,
    textAlign: "right",
  },
  btnGroup: {
    textAlign: "center",
    padding: "30px 0px",
    "& button": {
      margin: "0px 15px",
    },
  },
  back: {
    "& button": {
      backgroundColor: theme.palette.background.paper,
      "&:hover": {
        backgroundColor: theme.palette.background.paper,
      },
    },
  },
  textColor: {
    color: "#3f51b5",
  },
  button:  {
    backgroundColor:"#3f51b5",'&:hover':{
      backgroundColor: "#3f51b5",
      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)",
    },
    border: "solid #3f51b5",

  }
}));

const ChartMasterDetail = (props) => {
  const { id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const isRegister = pathname === "/pages/chart/masters/register";
  const state = useSelector((state) => state.chartDetail);
  const { mitem, moptions, downloadOption, lastModDate } = state;
  const [confirm, setConfirm] = React.useState({ open: false, message: "" });
  React.useEffect(() => {
    dispatch(fetchAllMediaForDetail());
    dispatch(openDetail());
    if (!isRegister) {
      dispatch(getMedia(id))
        .then((res) => {
          var error = errorCover(res);
          if (error !== null)
            dispatch(
              setMessages([
                { severity: error.severity, message: error.message },
              ])
            );
        })
        .catch((error) => {
          dispatch(
            setMessages([
              { severity: "error", message: "データの取得に失敗しました。" },
            ])
          );
        });
    } else {
      dispatch(closeDetail());
    }
    return () => {
      dispatch(closeDetail());
    };
  }, [id, dispatch, isRegister]);
  var arrays = [];
  for (let field in mitem) {
    if (field === "mstNm") {
      arrays.push(
        <EditTextField
          key={field}
          type="master"
          item={mitem[field]}
          label={"masters-detail"}
          field={field}
          display="inline-block"
          widthPercent={60}
          formWidth={65}
        ></EditTextField>
      );
    } else if (field === "groupCd") {
      arrays.push(
        <EditTextField
          key={field}
          type="master"
          item={mitem[field]}
          label={"masters-detail"}
          field={field}
          display="inline-block"
          widthPercent={40}
        ></EditTextField>
      );
    } else if (field === "startDate") {
      let maxDate = isRegister
        ? moment().subtract(1, "week").day("Monday").toDate()
        : moment(lastModDate, "YYYY/MM/DD").toDate();
      arrays.push(
        <MasterDateField
          key={field}
          type="master"
          item={mitem[field]}
          label={"masters-detail"}
          field={field}
          display="inline-block"
          widthPercent={35}
          formWidth={65}
          disabled={false}
          lastModDate={maxDate}
        ></MasterDateField>
      );
    } else if (field === "dlMaxWeek") {
      arrays.push(
        <EditTextField
          key={field}
          type="master"
          item={mitem[field]}
          label={"masters-detail"}
          field={field}
          display="inline-block"
          widthPercent={22}
          formWidth={60}
          suffix="週間"
          isShort={true}
        ></EditTextField>
      );
    } else if (field === "anlyWeeks") {
      arrays.push(
        <EditTextField
          key={field}
          type="master"
          item={mitem[field]}
          label={"masters-detail"}
          field={field}
          display="inline-block"
          widthPercent={22}
          formWidth={60}
          suffix="週間"
          isShort={true}
        ></EditTextField>
      );
    } else if (field === "dlArrowFlg") {
      arrays.push(
        <EditRadioField
          key={field}
          type="master"
          isInline={true}
          label={"masters-detail"}
          arrays={downloadOption}
          item={mitem[field]}
          widthPercent={21}
          field={field}
        ></EditRadioField>
      );
    } else if (field === "mediaList") {
      arrays.push(
        <EditTextAreaField
          key={field}
          type="master"
          item={mitem[field]}
          label={"masters-detail"}
          field={field}
        ></EditTextAreaField>
      );
    } else if (field === "anlyCol" || field === "anlyRow1") {
      arrays.push(
        <EditTextAreaField
          key={field}
          type="master"
          item={mitem[field]}
          label={"masters-detail"}
          field={field}
          r={4}
        ></EditTextAreaField>
      );
    } else if (field === "anlyRow2") {
      arrays.push(
        <EditTextAreaField
          key={field}
          type="master"
          item={mitem[field]}
          label={"masters-detail"}
          field={field}
          r={8}
        ></EditTextAreaField>
      );
    } else {
      arrays.push(
        <EditTextField
          key={field}
          type="master"
          item={mitem[field]}
          label={"masters-detail"}
          field={field}
        ></EditTextField>
      );
    }
  }

  var option = [];
  if (!isRegister)
    for (let field in moptions) {
      var o = moptions[field];
      var s = "";
      if (moment(o["value"], "YYYY/MM/DD").isValid()) {
        s = moment(o["value"], "YYYY/MM/DD").format("YYYY/MM/DD");
      } else {
        s = o["value"];
      }
      s = o["title"] + "：" + s;
      option.push(
        <p className={classes.pTab} key={field}>
          {s}
        </p>
      );
    }
  const handleCancel = () => {
    history.push("/pages/chart/masters");
  };
  const handleYes = () => {
    var obj = {};
    for (let field in mitem) {
      var i = mitem[field];
      if (i["error"] !== 0) return;
      var val = i["value"];
      var v = "";
      v = val;
      if (v !== "") obj[field] = v;
    }
    if (moment(obj["startDate"], "YYYY/MM/DD").isValid()) {
      let start1 = moment(obj["startDate"], "YYYY/MM/DD");
      obj["startDate"] = start1.format("YYYY/MM/DD");
    }
    var request = {};
    if (isRegister) {
      const fullname = new Cookies("auth").get("fullname", { path: "/" });
      obj["author"] = fullname;
      dispatch(masterRegistration(obj))
        .then((res) => {
          var error = errorCover(res, "作成しました。");
          if (error !== null) {
            const { severity, message } = error;
            dispatch(setMessages([{ severity: severity, message: message }]));
            if (severity === "success") history.push("/pages/chart/masters");
          }
        })
        .catch((error) => {
          dispatch(
            setMessages([
              { severity: "error", message: "作成に失敗しました。" },
            ])
          );
        });
      setConfirm({ ...confirm, open: false });
      return;
    }
    request["items"] = obj;
    setConfirm({ ...confirm, open: false });
    dispatch(saveMedia({ id: id, data: request }))
      .then((res) => {
        var error = errorCover(res, "保存しました。");
        if (error !== null) {
          const { severity, message } = error;
          dispatch(setMessages([{ severity: severity, message: message }]));
          if (severity === "success") history.push("/pages/chart/masters");
        }
      })
      .catch((error) => {
        dispatch(
          setMessages([{ severity: "error", message: "保存に失敗しました。" }])
        );
      });
  };
  const openDialog = () => {
    var failed = false;
    if (!isRegister)
      for (let field in mitem) {
        let i = mitem[field];
        if (i["error"] !== 0) {
          failed = true;
          break;
        }
      }
    else
      for (let field in mitem) {
        let i = mitem[field];
        if (i["error"] !== 0 || i["mod"] === false) {
          failed = true;
          break;
        }
      }

    let start = moment(mitem["startDate"].value, "YYYY/MM/DD");
    if (start.isAfter(moment(lastModDate, "YYYY/MM/DD"))) {
      failed = true;
    }
    if (failed) {
      dispatch(checkMasterErrorForAll());
      return;
    }
    setConfirm({
      message: `${isRegister ? "作成" : "更新"}してもよろしいですか？`,
      open: true,
    });
  };

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <div className={classes.back}>
          <Button className={classes.button}
            variant="outlined"
            color="primary"
            onClick={handleCancel}
            startIcon={<SubdirectoryArrowLeftIcon />}
          >
            一覧に戻る
          </Button>
        </div>
        <div className={classes.info}>{option}</div>
        <Paper className={classes.paper} variant="outlined">
          {arrays}
          <div className={classes.btnGroup}>
            <Button
              className={classes.textColor}
              variant="contained"
              onClick={handleCancel}
            >
              キャンセル
            </Button>
            <Button
              variant="contained"
              color= 'primary'
              onClick={openDialog}
            >
              {isRegister ? "登録する":"保存する"}
            </Button>
          </div>
        </Paper>
      </Container>
      <ColDialog></ColDialog>
      <ConfirmDialog
        msg={confirm.message}
        isOpen={confirm.open}
        doYes={handleYes}
        doNo={() => {
          setConfirm({ ...confirm, open: false });
        }}
      />
    </div>
  );
};

export default ChartMasterDetail;
