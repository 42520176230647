import {createSlice} from "@reduxjs/toolkit";
import {fetchClientList} from "./asyncThunk";

const initialState = {
    pageNum: 0,
    pageSize: 10,
    clientList: [],
    selectedList: [],
    total: 0,
    search: "",
    isLoading: false,
    error: null
};

const slice = createSlice({
    name: "clientList",
    initialState,
    reducers: {
        changeSelectedList:{
            reducer(state, action) {
                state.selectedList = action.payload.selectedList
            },
        },
        changeSearch:{
            reducer(state, action) {
                state.search = action.payload.search
            },
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchClientList.fulfilled, (state, action) => {
            state.pageNum = action.meta.arg.page
            state.pageSize = action.meta.arg.limit
            state.search = action.meta.arg.search
            state.total = action.payload.data.count
            state.clientList = action.payload.data.results
            state.selectedList = []
        });
        builder.addCase(fetchClientList.rejected, (state, action) => {
            state.clientList = []
            state.selectedList = []
            state.error = "クライアント情報を取得できません。"
            state.search = action.meta.arg.search
            state.pageNum = action.meta.arg.page
            state.pageSize = action.meta.arg.limit
        });
    },
});

export const clientList = slice.reducer;
export const { changeSelectedList, changeSearch } = slice.actions;