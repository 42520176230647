import { createSlice } from "@reduxjs/toolkit";
import { isNullorBlank, checkError } from "../../errors/errors";
import { fetchGroupCd, fetchAllMedia, doRegistration } from "./asyncThunk";
import * as moment from "moment";
const initialState = {
  step1: {
    item: {
      username: {
        value: "",
        checkTypes: [1, 2],
        error: 0,
        mod: false,
      },
      userId: {
        value: "",
        checkTypes: [1, 2],
        error: 0,
        mod: false,
      },
      password: {
        value: "",
        checkTypes: [1, 2],
        error: 0,
        mod: false,
      },
      groupCd: {
        value: "",
        checkTypes: [1, 2],
        error: 0,
        mod: false,
      },
      acType: {
        value: "",
        checkTypes: [1],
        error: 0,
        mod: false,
      },
      startDate: {
        value: new Date(),
        checkTypes: [1, 5],
        error: 0,
        mod: true,
        noExpired: false,
      },
      endDate: {
        value: new Date(),
        checkTypes: [1, 5],
        error: 0,
        mod: true,
        noExpired: true,
      },
      ignoreSession: {
        value: false,
        checkTypes: [],
        error: 0,
        mod: true,
      },
    },
    acTypeArr: [
      { label: "free", value: 0 },
      { label: "paid", value: 1 },
      { label: "demo", value: 9 },
    ],
    isDone: false,
  },
  step2: {
    item: {
      canDownload: {
        value: false,
        checkTypes: [],
        error: 0,
        mod: true,
      },
      anlyWeeks: {
        value: 15,
        checkTypes: [1, 20, 21, 22, 3],
        error: 0,
        mod: true,
      },
      mstNm: {
        value: "",
        checkTypes: [1],
        error: 0,
        mod: false,
      },
      startDate: {
        value: moment().subtract(15, "week").startOf("isoWeek").toDate(),
        checkTypes: [1, 5],
        error: 0,
        mod: true,
        noExpired: false,
      },
      // endDate: {
      //   value: new Date(),
      //   checkTypes: [1, 5],
      //   error: 0,
      //   mod: true,
      //   noExpired: false,
      // },
      mediaList: {
        value: [],
        checkTypes: [],
        error: 0,
        mod: true,
      },
    },
    isDone: false,
  },
  step3: {
    item: {
      anlyCol: {
        value: "",
        error: 0,
        mod: true,
        isDefault: true,
        checkTypes: [30],
      },
      anlyRow1: {
        value: "",
        error: 0,
        mod: true,
        isDefault: true,
        checkTypes: [30],
      },
      anlyRow2: {
        value: "",
        error: 0,
        mod: true,
        isDefault: true,
        checkTypes: [30],
      },
      colSet: {
        value: "",
        error: 0,
        mod: true,
        isDefault: true,
        checkTypes: [30],
      },
      dlFileNms: {
        value: "",
        error: 0,
        mod: true,
        isDefault: true,
        checkTypes: [30],
      },
      dlMaxFileCountForExecType: {
        value: 8,
        error: 0,
        mod: true,
        isDefault: true,
        checkTypes: [30, 20, 21],
        lbw: 250,
      },
      ord: {
        value: 0,
        error: 0,
        mod: true,
        isDefault: true,
        checkTypes: [30, 20, 21],
      },
      tableNm: {
        value: "",
        error: 0,
        mod: true,
        isDefault: true,
        checkTypes: [30],
      },
    },
    isDone: true,
  },
  step: 0,
  stepLabels: [
    "Basic Information",
    "Main Analysis Information",
    "Additional Information",
  ],
  error: "",
  isError: false,
  notiType: 0,
  isLoad: false,
  isFetchedGroupCd: false,
  isFetchedAllMedia: false,
  done: false,
  groupCd: [],
  media: [],
  isGroupCd: false,
};

const registerSlice = createSlice({
  name: "register-chart-account",
  initialState,
  reducers: {
    updateToStep1: {
      reducer: (state, action) => {
        console.log(action);
      },
    },
    handleValueChange: {
      reducer: (state, action) => {
        const payload = action.payload;
        const field = payload["field"];
        const step = payload["step"];
        const value = payload["value"];
        const error = payload["error"];
        if (!isNullorBlank(value)) {
          state[step]["item"][field]["mod"] = true;
          if (field === "groupCd" && state["isFetchedGroupCd"]) {
            if (state["groupCd"].includes(value)) {
              state["isGroupCd"] = true;
            } else {
              state["isGroupCd"] = false;
            }
          }
        }
        state[step]["item"][field]["value"] = value;
        state[step]["item"][field]["error"] = error;
        if (field === "groupCd" && value === "free") {
          state[step]["item"]["acType"]["mod"] = true;
          state[step]["item"]["acType"]["value"] = 0;
        }
        if (state[step]["item"][field]["noExpired"] !== null) {
          state[step]["item"][field]["noExpired"] = payload["noExpired"];
        }
        if (state[step]["item"][field]["isDefault"] !== undefined) {
          if (payload["isDefault"] !== undefined) {
            state[step]["item"][field]["isDefault"] = payload["isDefault"];
          }
        }
        if (step === "step2" && error === 0) {
          if (field === "anlyWeeks") {
            state[step]["item"]["startDate"]["value"] = moment()
              .subtract(value, "week")
              .startOf("isoWeek")
              .toDate();
          }
          if (field === "endDate") {
            const weeks = state[step]["item"]["anlyWeeks"]["value"];
            state[step]["item"]["startDate"]["value"] = moment(value)
              .subtract(weeks, "week")
              .startOf("isoWeek")
              .toDate();
          }
        }

        return state;
      },
      prepare: (value) => {
        value["error"] = checkError(value["value"], value["checkTypes"]);
        return { payload: value };
      },
    },
    nextStep: (state, action) => {
      if (action.payload >= 0 && action.payload <= 2) {
        state.step = action.payload;
      }
      return state;
    },
    checkStepError: {
      reducer: (state, action) => {
        const step = action.payload;
        var noError = true;
        const item = state[step]["item"];
        for (let f in item) {
          if (step === "step3") {
            noError =
              noError &&
              (item[f]["isDefault"] === true || item[f]["error"] === 0);
          } else noError = noError && item[f]["error"] === 0 && item[f]["mod"];
        }
        state[step]["isDone"] = noError;
        return state;
      },
    },
    resetError: (state, action) => {
      state.isError = false;
      state.error = "";
      state.notiType = 0;
      return state;
    },
    showLoad: (state, action) => {
      state.isLoad = true;
      return state;
    },
    reset: (state, action) => {
      state = initialState;
      return state;
    },
    changeMultiLogin: (state, action) => {
      var check = !state.step1.item.ignoreSession.value;
      state.step1.item.ignoreSession.value = check;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGroupCd.fulfilled, (state, action) => {
      state.groupCd = action.payload.data;
      state.isFetchedGroupCd = true;
      return state;
    });
    builder.addCase(fetchGroupCd.rejected, (state, action) => {
      state.isFetchedGroupCd = false;
      return state;
    });
    builder.addCase(fetchAllMedia.fulfilled, (state, action) => {
      state.media = action.payload.data.mediaList;
      state.isFetchedAllMedia = true;
      return state;
    });
    builder.addCase(fetchAllMedia.rejected, (state, action) => {
      state.isFetchedAllMedia = false;
      return state;
    });
    builder.addCase(doRegistration.fulfilled, (state, action) => {
      state.done = true;
      state.isLoad = false;
      return state;
    });
    builder.addCase(doRegistration.rejected, (state, action) => {
      state.done = false;
      state.isLoad = false;
      return state;
    });
  },
});

export const registerChart = registerSlice.reducer;

export const {
  updateToStep1,
  nextStep,
  handleValueChange,
  checkStepError,
  resetError,
  showLoad,
  reset,
  changeMultiLogin,
} = registerSlice.actions;
