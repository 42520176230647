export const titles = [
  "ログインID",
  // "ユーザーバスワード",
  "ユーザー名",
  "グループコード",
  "ステータス",
  "アカウント種類",
  "利用開始日",
  "利用期限",
  "複数デバイスでの同時ログイン",
  "作成者",
  "作成日",
  "変更日",
];
export const userFields = [
  "userId",
  // "password",
  "userNm",
  "groupCd",
  "acStatus",
  "acType",
  "sdate",
  "edate",
  "ignoreSession",
  "author",
  "prcDt",
  "upDt",
];

export const mTitles = [
  "グループコード",
  "マスタ名",
  "利用開始日",
  "利用期間",
  "媒体",
  "集計期間",
  "ダウンロード可否",
  "マスタ表示順",
  "作成日",
  "変更日",
];

export const masterFields = [
  "groupCd",
  "mstNm",
  "startDate",
  "dlMaxWeek",
  "mediaList",
  "anlyWeeks",
  "dlArrowFlg",
  "ord",
  "prcDate",
  "upDate",
];
