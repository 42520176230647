import React from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  makeStyles,
  FormHelperText,
} from "@material-ui/core";
import {
  changeDetail,
  changeMasterDetail,
} from "../../../stores/chart/chartStore";
import { useDispatch } from "react-redux";
import getErrorText from "../../../errors/errors";

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    padding: props.padding,
    display: props.inLine,
    width: props.width,
  }),
  group: (props) => ({
    flexDirection: "row",
    placeContent: props.placeContent,
  }),
  control: (props) => ({
    width: props.controlWidth,
  }),
}));

const EditRadioField = (props) => {
  const dispatch = useDispatch();
  const { item, arrays, isInline, field, widthPercent, type } = props;
  const { title, value, error } = item;

  const handleChange = (field) => (event) => {
    const itemp = {
      field: field,
      value: parseInt(event.target.value),
    };
    if (type !== undefined) {
      dispatch(changeMasterDetail(itemp));
    } else {
      dispatch(changeDetail(itemp));
    }
  };
  var choices = arrays.map((i, index) => {
    return (
      <FormControlLabel
        key={index}
        value={i["value"]}
        control={<Radio color="primary" />}
        label={i["label"]}
      />
    );
  });
  var obj = { padding: 10 };
  if (isInline) {
    obj["inLine"] = "inline-block";
  }
  if (widthPercent !== undefined) {
    obj["width"] = widthPercent + "%";
    obj["padding"] = 0;
    obj["placeContent"] = "space-between";
    obj["controlWidth"] = "100%";
  }
  const classes = useStyles(obj);
  return (
    <div className={classes.root}>
      <FormControl
        className={classes.control}
        component="fieldset"
        error={error !== 0}
      >
        <FormLabel component="legend">{title}</FormLabel>
        <RadioGroup
          aria-label={title}
          name={title}
          value={value}
          className={classes.group}
          onChange={handleChange(field)}
        >
          {choices}
        </RadioGroup>
        <FormHelperText>&nbsp;{getErrorText(title, error)}</FormHelperText>
      </FormControl>
    </div>
  );
};

export default EditRadioField;
