import React from 'react'
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { resetState, changeAccountData } from "../../../stores/listlight/accountDetailStore";
import { setMessages } from "../../../stores/alertSnackbarStore";
import { getAccount, saveAccount, delAccount, fetchMasters } from "../../../stores/listlight/asyncThunk";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import MasterDetailDialog from '../../../components/listlight/MasterDetailDialog';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { ACCOUNT_TYPE } from '../../../helper/listlight/listlightHelper';
import { unwrapResult } from '@reduxjs/toolkit';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

const useStyles = makeStyles((theme) => ({
  content: {
    margin: theme.spacing(1),
    padding: theme.spacing(3),
  },
  back: {
    padding: theme.spacing(1),
  },
  backLink: {
    textDecoration: 'none',
    color: 'unset',
  },
  action: {
    textAlign: 'center',
    '& button': {
      margin: theme.spacing(2),
      minWidth: '100px',
    }
  },
  button:  {
    backgroundColor: 'white',
    border: "2px solid #3f51b5",
    color: "#3f51b5",
  }
}));

const MODE = {
  REGISTER: 1 ,
  UPDATE: 2,
  DELETE: 3
}

const AccountDetailPage: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({message: "", open: false, mode: MODE.REGISTER});
  const { id } = useParams();
  const dispatch = useDispatch()
  const viewData = useSelector(state => state.listlightAccountDetail)
  const classes = useStyles();
  const history = useHistory()
  const isRegister = id === 'new';

  // Similar to componentDidMount and componentDidUpdate:
  React.useEffect(() => {
    dispatch(fetchMasters({page:0, limit:1000}))
    if (id !== "new") {
      dispatch(getAccount({id: id}))
      .then(unwrapResult)
      .catch(error => {
        history.push(`/pages/listlight/accounts`)
        dispatch(setMessages([{severity: "error", message: "データの取得に失敗しました。"}]));
      });
    }

    // component will unmount
    return () => dispatch(resetState());
  }, [dispatch, history, id]);

  const handleMasterChange = (label, event) => {
    if (event.target.value !== 'new') {
      handleChange(label, event);
      return;
    }
    setOpen(true);
  };

  const handleChange = (label, event) => {
    let value = null;
    if (event.target.type === 'checkbox') {
      value = event.target.checked
    } else if (event.target.type === 'radio') {
      value = event.target.value === 'true' ? true : false
    } else {
      value = event.target.value
    }
    dispatch(changeAccountData({key: label, value: value}));
  }

  const clickSave = (event) => {
    if (viewData.account.id) {
      setConfirmDialog({message: "更新してもよろしいですか？", open: true, mode: MODE.UPDATE})
    } else {
      setConfirmDialog({message: "登録してもよろしいですか？", open: true, mode: MODE.REGISTER})
    }
  }

  const clickDelete = (event) => {
    setConfirmDialog({message: "削除してもよろしいですか？", open: true, mode: MODE.DELETE})
  }

  const handleYes = () => {
    setConfirmDialog({...confirmDialog, open: false})
    if (confirmDialog.mode === MODE.DELETE) {
      dispatch(delAccount({account: viewData.account}))
        .then(unwrapResult)
        .then(result => {
          history.push(`/pages/listlight/accounts`)
          dispatch(setMessages([{severity: "success", message: "削除しました。"}]));
        })
        .catch(error => {
          console.log(error);
          dispatch(setMessages([{severity: "error", message: "削除に失敗しました。"}]));
        });
    } else {
      dispatch(saveAccount({account: viewData.account}))
      .then(unwrapResult)
      .then(result => {
        if (id === "new") {
          history.push(`/pages/listlight/accounts/${result.data.id}`)
        }
        dispatch(setMessages([{severity: "success", message: "保存しました。"}]));
      })
      .catch(error => {
        console.log(error);
        dispatch(setMessages([{severity: "error", message: "保存に失敗しました。"}]));
      });
    }
  }

  const getData = (label) => {
    return viewData.account[label] ? viewData.account[label] : "";
  }
  
  const getBooleanData = (label) => {
    return viewData.account[label] ? true : false;
  }

  const getBooleanDataAsString = (label) => {
    return viewData.account[label] ? "true" : "false";
  }

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <div>
      <div className={classes.back}>
        <Button className={classes.button} variant="contained" onClick={() => history.push(`/pages/listlight/accounts`)}>
          <KeyboardReturnIcon />
          一覧に戻る
        </Button>
      </div>
      <Paper variant="outlined" className={classes.content}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormLabel component="legend">会社名</FormLabel>
            <TextField
              variant="outlined"
              fullWidth
              value={getData("company_name")}
              onChange={(e) => {handleChange("company_name", e)}}
            />
          </Grid>
          <Grid item xs={6}>
            <FormLabel component="legend">ユーザー名</FormLabel>
            <TextField
              variant="outlined"
              fullWidth
              value={getData("name")}
              onChange={(e) => {handleChange("name", e)}}
            />
          </Grid>
          <Grid item xs={6}>
            <FormLabel component="legend">ログインID</FormLabel>
            <TextField
              variant="outlined"
              fullWidth
              value={getData("account_id")}
              onChange={(e) => {handleChange("account_id", e)}}
            />
          </Grid>
          {!viewData.account.id &&
            <Grid item xs={6}>
              <FormLabel component="legend">ユーザーパスワード</FormLabel>
              <TextField
                variant="outlined"
                fullWidth
                value={getData("password")}
                onChange={(e) => {handleChange("password", e)}}
              />
            </Grid>
          }
          <Grid item xs={6}>
            <FormLabel component="legend">電話番号</FormLabel>
            <TextField
              variant="outlined"
              fullWidth
              value={getData("tel")}
              onChange={(e) => {handleChange("tel", e)}}
            />
          </Grid>
          <Grid item xs={6}>
            <FormLabel component="legend">メールアドレス</FormLabel>
            <TextField
              variant="outlined"
              fullWidth
              value={getData("email")}
              onChange={(e) => {handleChange("email", e)}}
            />
          </Grid>
          <Grid item xs={6}>
            <FormLabel component="legend">マスタ選択</FormLabel>
            <Select
              variant="outlined"
              fullWidth
              value={getData("master_id")}
              onChange={(e) => {handleMasterChange("master_id", e)}}>

              {viewData.masters.map((master) => (
                <MenuItem key={master.id} value={master.id}>
                  {master.id} - {master.name}
                </MenuItem>
              ))}
              <MenuItem key='new' value='new'>
                新規作成
              </MenuItem>
            </Select>
            <MasterDetailDialog open={open} onClose={handleClose}/>
          </Grid>
          <Grid item xs={6}>
            <FormLabel component="legend">アカウント種類</FormLabel>
            <Select
              variant="outlined"
              fullWidth
              value={getData("account_type")}
              onChange={(e) => {handleChange("account_type", e)}}>
              {Object.entries(ACCOUNT_TYPE).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={3} >
                <FormLabel component="legend">利用開始日</FormLabel>
                <TextField
                  type="datetime-local"
                  variant="outlined"
                  value={getData("start_at")}
                  onChange={(e) => {handleChange("start_at", e)}}
                />
              </Grid>
              <Grid item xs={3} >
                <FormLabel component="legend">利用期限</FormLabel>
                <TextField
                  type="datetime-local"
                  variant="outlined"
                  value={getData("expire_at")}
                  onChange={(e) => {handleChange("expire_at", e)}}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <FormLabel component="legend">通知フラグ</FormLabel>
                <RadioGroup row value={getBooleanDataAsString("notify_update_data")} onChange={(e) => {handleChange("notify_update_data", e)}}>
                  <FormControlLabel value="true" control={<Radio color="primary"/>} label="利用" />
                  <FormControlLabel value="false" control={<Radio color="primary"/>} label="未利用" />
                </RadioGroup>
              </Grid>
              <Grid item xs={3}>
                <FormLabel component="legend">複数デバイスでの同時ログイン</FormLabel>
                <RadioGroup row value={getBooleanDataAsString("is_strict_login")} onChange={(e) => {handleChange("is_strict_login", e)}}>
                  <FormControlLabel value="false" control={<Radio color="primary"/>} label="可" />
                  <FormControlLabel value="true" control={<Radio color="primary"/>} label="不可" />
                </RadioGroup>
              </Grid>
              <Grid item xs={3}>
                <FormLabel component="legend">無効フラグ</FormLabel>
                <Switch
                  checked={getBooleanData("is_invalid")}
                  onChange={(e) => {handleChange("is_invalid", e)}}
                  color="primary"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.action}>
              <Button variant="contained" color="primary" onClick={clickSave}>
              {isRegister ? "登録する": "保存する"}
              </Button>
              {viewData.account.id &&
                <Button variant="contained" color="secondary" onClick={clickDelete}>削除</Button>
              }
            </div>
          </Grid>
        </Grid>
        <ConfirmDialog
          msg={confirmDialog.message}
          isOpen={confirmDialog.open}
          doYes={handleYes}
          doNo={() => {setConfirmDialog({...confirmDialog, open: false})}}
        />
        <ul>
          {Object.entries(viewData.error).map(([key, value]) => (
            <li key={key}>
              {key} : {value}
            </li>
          ))}
        </ul>
      </Paper>
    </div>
  )
}
 
export default AccountDetailPage
