import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { useDispatch } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
import * as moment from "moment";
import { changeMasterDetail } from "../../../stores/chart/chartStore";

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    width: props.width,
    height: "100%",
    display: props.display,
    padding: "10px 0px",
  }),
  container: (props) => ({
    display: "block",
  }),
  title: {
    marginBottom: 5,
  },
  form: {
    margin: 0,
  },
}));

const MasterDateField = (props) => {
  const {
    field,
    item,
    label,
    display,
    widthPercent,
    formWidth,
    lastModDate,
  } = props;
  var maxDate = lastModDate;
  const { title, value } = item;
  const dispatch = useDispatch();
  const handleValue = (field) => (event) => {
    const itemp = {
      field: field,
      value: event === null ? new Date() : moment(event, "YYYY/MM/DD").toDate(),
    };
    dispatch(changeMasterDetail(itemp));
  };
  var obj = { width: "100%", formWidth: "100%" };
  if (display !== undefined) {
    obj["display"] = display;
  }
  if (widthPercent !== undefined) {
    obj["width"] = widthPercent + "%";
  }
  if (formWidth !== undefined) {
    obj["formWidth"] = formWidth + "%";
  }
  const classes = useStyles(obj);
  const id = label + "-" + field + "-picker-dialog";
  const shouldDisable = (date) => {
    return moment(date, "YYYY/MM/DD").day() !== 1;
  };
  let node = document.getElementById(id);
  if (node !== null) {
    node.setAttribute("disabled", true);
    node.classList.add("Mui-disabled");
    node.parentElement.classList.add("Mui-disabled");
  }

  return (
    <div className={classes.root}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="flex-start" className={classes.container}>
          <KeyboardDatePicker
            className={classes.form}
            margin="normal"
            id={id}
            format="yyyy/MM/dd"
            inputVariant="outlined"
            value={value}
            label={title}
            shouldDisableDate={shouldDisable}
            onChange={handleValue(field)}
            maxDate={maxDate}
          />
        </Grid>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default MasterDateField;
