import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {
    deleteMedia,
    fetchMediaDetail,
    saveMedia
} from "../../../stores/listui/asyncThunk";
import TextField from "@material-ui/core/TextField";
import ConfirmDialog from "../../../components/ConfirmDialog";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import "../../../style/listui/ClientRegisterPage.scss"
import DeleteIcon from '@material-ui/icons/Delete';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import {makeStyles} from "@material-ui/core/styles";
import {setMessages} from "../../../stores/alertSnackbarStore";
import {unwrapResult} from "@reduxjs/toolkit";
import {changeMediaData, validateMedia, resetState, changeErrorState} from "../../../stores/listui/mediaRegisterStore";

const useStyles = makeStyles((theme) => ({
    control: (props) => ({
        width: "100%",
    }),
    headerGroup: {
        height: 50,
        width: "100%",
        transform: "translate(0, 0px) scale(1)",
        borderBottom: "1px solid rgba(0, 0, 0, 0.23)",
        zIndex: 10,
        paddingLeft: "0 !important",
        paddingTop: 7,
    },
    headerGroup_Label: {
        height: 50,
        display: "inline-flex",
        alignItems: "center",
        paddingLeft: 15,
        marginBottom: 5,
    },
    headerGroup_Button: {
        height: 50,
        display: "inline-flex",
        alignItems: "center",
        paddingLeft: 10,
        marginBottom: 5,
    },
    field: {
        "& .MuiInputBase-root": {
            paddingTop: 65,
        },
    },
    portalBtn: {
        color: "#3f51b5"
    },
    idField:{
        paddingLeft: 20,
        fontSize: 14,
    },
}))

const MODE = {
    REGISTER: 1,
    UPDATE: 2,
    DELETE: 3
}

const MediaRegisterPage: React.FC = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const history = useHistory()
    const viewData = useSelector(state => state.lisuiMediaRegister)
    const {id} = useParams()
    const [confirmDialog, setConfirmDialog] = useState({message: "", open: false, mode: MODE.REGISTER});
    const [IsSaveClick, setIsSaveClick] = useState(false)
    const [paginationInfo] = useState({
        pageNum: history.location.state && history.location.state.pageNum ? history.location.state.pageNum : 0,
        pageSize: history.location.state && history.location.state.pageSize? history.location.state.pageSize : 10,
        search: history.location.state && history.location.state.search? history.location.state.search : ""
    })

    React.useEffect(() => {
        if (id !== 'register') {
            dispatch(fetchMediaDetail({id: id}))
                .then(unwrapResult)
                .catch(error => {
                    dispatch(setMessages([{severity: "error", message: "データの取得に失敗しました。"}]));
                })
        }
        // component will unmount
        return () => dispatch(resetState());
    }, [dispatch, id]);

    React.useEffect(() => {
        if (viewData.redirectPath && viewData.redirectPath.trim() !== '') {
            history.push({
                pathname: viewData.redirectPath,
                state: {
                    pageNum: paginationInfo.pageNum,
                    pageSize: paginationInfo.pageSize,
                    search: paginationInfo.search
                }
            })
        }
    }, [viewData.redirectPath, history, paginationInfo]);

    const getData = (label) => {
        return viewData.media[label] ? viewData.media[label] : "";
    }

    const getErrorFlg = (field) => {
        return viewData.fieldErrorFlg[field] ? viewData.fieldErrorFlg[field] : false
    }

    const getErrorTxt = (field) => {
        return viewData.fieldErrorTxt[field] ? viewData.fieldErrorTxt[field] : ''
    }

    const handleChange = (label, event) => {
        validateField(label, event.target.value)
        dispatch(changeMediaData({key: label, value: event.target.value}));
    }

    const handleClickBack = () => {
        history.push({
            pathname: "/pages/listui/baitai",
            state: {
                pageNum: paginationInfo.pageNum,
                pageSize: paginationInfo.pageSize,
                search: paginationInfo.search
            }
        })
    }

    const handleClickDelete = () => {
        setConfirmDialog({message: "削除してもよろしいですか？", open: true, mode: MODE.DELETE})
    }

    const handleYes = () => {
        setConfirmDialog({...confirmDialog, open: false})
        if (confirmDialog.mode === MODE.DELETE) {
            dispatch(deleteMedia({mediaId: viewData.media.media_id}))
                .then(unwrapResult)
                .then(result => {
                    dispatch(setMessages([{severity: "success", message: "削除しました。"}]));
                })
                .catch(error => {
                    dispatch(setMessages([{severity: "error", message: "削除に失敗しました。"}]));
                });
        } else {
            dispatch(saveMedia({media: viewData.media}))
                .then(unwrapResult)
                .then(result => {
                    dispatch(setMessages([{severity: "success", message: "保存しました。"}]));
                })
                .catch(error => {
                    dispatch(setMessages([{severity: "error", message: "保存に失敗しました。"}]));
                });
        }
    }

    const handleClickCallApi = () => {
        dispatch(validateMedia())
        setIsSaveClick(true)
    }

    const isFormValid = () => {
        for (const [key, value] of Object.entries(viewData.fieldErrorFlg)) {
            if (key && value) return false
        }
        return true
    }

    const doClickSave = () => {
        if (isFormValid()) {
            if (id !== 'register') {
                setConfirmDialog({message: "更新してもよろしいですか？", open: true, mode: MODE.UPDATE})
            } else {
                setConfirmDialog({message: "登録してもよろしいですか？", open: true, mode: MODE.REGISTER})
            }
        } else {
            dispatch(setMessages([{severity: "error", message: "項目が必要です。"}]));
        }
    }

    if (IsSaveClick) {
        doClickSave()
        setIsSaveClick(false)
    }

    const validateField = (key, value) => {
        if (value.trim() === '') {
            triggerChangeError(key, true, "この項目は必須です。")
        } else {
            triggerChangeError(key, false, "")
        }
    }

    const triggerChangeError = (field, errorFlg, errorTxt) => {
        dispatch(changeErrorState({field: field, errorFlg: errorFlg, errorTxt: errorTxt}))
    }

    return (
        <div className="ClientRegister">
            <div className="BackBtn">
                <Button
                    variant="outlined"
                    onClick={handleClickBack}
                    startIcon={<KeyboardReturnIcon/>}
                >
                    一覧に戻る
                </Button>
            </div>
            <div className="ContentContainer">
                {id !== 'register' &&
                <div className="SubInfo">
                    <span>作成者：{getData("prc_user_cd")}</span>
                    <span>作成日：{getData("created_date")}</span>
                    <span>更新日：{getData("prc_date")}</span>
                </div>
                }

                <Paper variant="outlined" className="FormContent">
                    {id !== 'register' &&
                    <div className="DeleteBtn">
                        <Button
                            variant="outlined"
                            onClick={handleClickDelete}
                            startIcon={<DeleteIcon/>}
                        >削除する</Button>
                    </div>
                    }
                    <Grid container spacing={5}>
                        {id !== 'register' &&
                        <div className={classes.idField}>
                            媒体ID：{id}
                        </div>
                        }

                        <Grid item xs={12}>
                            <TextField variant="outlined" color="primary" label="媒体名" value={getData("media_name")}
                                       onChange={(e) => {
                                           handleChange("media_name", e)
                                       }} error={getErrorFlg('media_name')} helperText={getErrorTxt('media_name')}/>
                        </Grid>
                    </Grid>
                    <div className="ControlArea">
                        <Button className={classes.portalBtn} variant="contained"
                                onClick={handleClickBack}>キャンセル</Button>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleClickCallApi}
                        >{id === "register" ? "登録する" : "保存する"}</Button>
                    </div>
                    <ConfirmDialog
                        msg={confirmDialog.message}
                        isOpen={confirmDialog.open}
                        doYes={handleYes}
                        doNo={() => {
                            setConfirmDialog({...confirmDialog, open: false})
                        }}
                    />
                </Paper>
            </div>
        </div>
    )
}

export default MediaRegisterPage;