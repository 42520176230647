import React from "react";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  makeStyles,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import {
  changeDetail,
  changeMasterDetail,
} from "../../../stores/chart/chartStore";
import getErrorText, { getShortErrorText } from "../../../errors/errors";

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    width: props.width,
    height: "100%",
    display: props.display,
    padding: "10px 0px",
  }),
  control: (props) => ({
    width: props.formWidth,
  }),
  title: {
    marginBottom: 5,
  },
  suffix: (props) => ({
    display: "inline-block",
    verticalAlign: "bottom",
    paddingBottom: 25,
    width: props.suffix,
    marginLeft: props.suffixMargin,
  }),
}));

const EditTextField = (props) => {
  const {
    field,
    item,
    label,
    display,
    widthPercent,
    formWidth,
    suffix,
    type,
    isShort,
    disabled,
  } = props;
  const { title, labelWidth } = item;
  const dispatch = useDispatch();
  const handleValue = (field) => (event) => {
    const itemp = {
      field: field,
      value: event.target.value,
    };
    if (type !== undefined) {
      dispatch(changeMasterDetail(itemp));
    } else {
      dispatch(changeDetail(itemp));
    }
  };
  var obj = { width: "100%", formWidth: "100%" };
  if (display !== undefined) {
    obj["display"] = display;
  }
  if (widthPercent !== undefined) {
    obj["width"] = widthPercent + "%";
  }
  if (formWidth !== undefined) {
    obj["formWidth"] = formWidth + "%";
  }
  if (suffix !== undefined) {
    if (formWidth !== undefined) {
      obj["suffix"] = 100 - formWidth - 5 + "%";
      obj["suffixMargin"] = "5%";
    }
  }
  var off = false;
  if (disabled !== undefined) {
    off = disabled;
  }
  const classes = useStyles(obj);

  return (
    <div className={classes.root}>
      <FormControl
        className={classes.control}
        variant="outlined"
        error={item["error"] !== 0}
        disabled={off}
      >
        <InputLabel htmlFor={label + "-" + field}>{title}</InputLabel>
        <OutlinedInput
          id={label + "-" + field}
          value={item["value"]}
          onChange={handleValue(field)}
          labelWidth={labelWidth}
        />
        <FormHelperText>
          &nbsp;
          {isShort
            ? getShortErrorText(title, item["error"])
            : getErrorText(title, item["error"])}
        </FormHelperText>
      </FormControl>
      <div className={classes.suffix}>{suffix}</div>
    </div>
  );
};

export default EditTextField;
