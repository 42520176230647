import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux'
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {fetchMediaSelectList} from "../../stores/listui/asyncThunk";
import SearchIcon from "@material-ui/icons/Search";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DialogActions from "@material-ui/core/DialogActions";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
// import withStyles from "@material-ui/core/styles/withStyles";
import debounce from "@material-ui/core/utils/debounce";

const useStyles = makeStyles((theme) => ({
    root: {
        // display: 'flex',
        "& .MuiDialog-paper": {
            width: '100%',
            height: "100%",
        }
    },
    search: {
        padding: theme.spacing(1),
    },
    searchBox: {
        backgroundColor: "white",
    },
    listMedia: {
        "& .MuiListItem-root": {
            display: "inline-flex",
            width: "fit-content",
        }
    },
    content: {
        height: "100%"
    }
}))

const SelectMediaDialog: React.FC = (props) => {
    const {selectedValues, onClose, selectValueFn, open} = props;
    const classes = useStyles()
    const dispatch = useDispatch()
    const viewData = useSelector(state => state.listuiMediaSelectList)
    const [selectedList, setSelectedList] = useState(selectedValues)
    const [isComposition, setIsComposition] = useState(false)

    // Similar to componentDidMount and componentDidUpdate:
    React.useEffect(() => {
        dispatch(fetchMediaSelectList({
            page: 0,
            limit: 1000,
        }))
    }, [dispatch]);

    React.useEffect(() => {
        if (!open) {
            setSelectedList(selectedValues)
        }

    }, [open, selectedValues, selectedList]);

    const triggerSearchDebounce = debounce((event) => {
        doSearch(event)
    }, 1000);

    const handleStartComposition = (event) => {
        setIsComposition(true)
    }

    const handleEndComposition = event => {
        setIsComposition(false)
        handleChangeSearch(event, true)
    }

    const handleChangeSearch = (event, force=false) => {
        if (!isComposition || force) {
            event.persist();
            triggerSearchDebounce(event)
        }
    }

    const doSearch = (event) => {
        dispatch(fetchMediaSelectList({
            page: 0,
            limit: 1000,
            search: event.target.value
        }))
    }

    const isChecked = (mediaID) => {
        return selectedList.includes(mediaID)
    }

    const handleChange = (event, id) => {
        let newSelected = [...selectedList]
        if (newSelected.indexOf(id) === -1) {
            newSelected.push(id)
        } else {
            newSelected.splice(newSelected.indexOf(id), 1)
        }
        setSelectedList(newSelected)
    }

    const handleClose = () => {
        onClose(null);
        dispatch(fetchMediaSelectList({
            page: 0,
            limit: 1000,
        }))
    };

    const handleSave = () => {
        handleClose();
        let sortedSelectedList = selectedList.sort()
        let newObjList = []
        sortedSelectedList.map(id => {
            let mediaObj = viewData.mediaFull.find(x => x.media_id === id);
            if (mediaObj) newObjList.push(mediaObj)
            return null
        })
        selectValueFn(newObjList)
    }

    // const CustomDialogTitle = function (props) {
    //     const {classes, ...other} = props;
    const CustomDialogTitle = (
        <DialogTitle disableTypography id="simple-dialog-title">
            <Typography variant="h5" color="primary" style={{fontWeight: "bold"}}>納品媒体</Typography>
            <div
                className={classes.search}
                onCompositionStart={handleStartComposition}
                onCompositionEnd={handleEndComposition}
                onChange={handleChangeSearch}
            >
                <OutlinedInput
                    error={false}
                    autoFocus
                    // value={search}
                    className={classes.searchBox}
                    placeholder="テキストを入力して検索"
                    // onKeyDown={handleSearchEnter}
                    endAdornment={
                        <InputAdornment position="end">
                            <SearchIcon/>
                        </InputAdornment>
                    }
                />
            </div>
        </DialogTitle>
    );
    // };

    return (
        <Dialog
            className={classes.root}
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
            scroll='paper'
            fullWidth
            maxWidth="md"
        >
            {/* <CustomDialogTitle id="simple-dialog-title">納品媒体</CustomDialogTitle> */}
            {CustomDialogTitle}
            <DialogContent className={classes.content}>
                <div className={classes.listMedia}>
                    {viewData.media.length > 0 && viewData.media.map((row) => {
                        const labelId = `checkbox-list-label-${row.media_id}`;

                        return (
                            <ListItem button key={row.media_id} onClick={event => handleChange(event, row.media_id)}>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={isChecked(row.media_id)}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{'aria-labelledby': labelId}}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={row.media_name}/>
                            </ListItem>
                        )
                    })}
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={handleClose}
                >キャンセル</Button>
                <Button
                    variant="contained"
                    onClick={handleSave}
                >OK</Button>
            </DialogActions>
        </Dialog>
    )
}

export default SelectMediaDialog
