import { createAsyncThunk } from '@reduxjs/toolkit';
import { 
  getAccountList, 
  getAccountDetail, 
  postAccount, 
  putAccount,
  deleteAccount,
  callDownloadLogs,
  callDownloadLogsShokushu,
} from '../../api/mapApi'

export const fetchAccounts = createAsyncThunk(
  'map/fetchAccounts',
  async (arg, { rejectWithValue }) => {
    try {
      return await getAccountList(arg);
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
);

export const getAccount = createAsyncThunk(
  'map/getAccount',
  async (arg: { id: number}, { rejectWithValue }) => {
    try {
      return await getAccountDetail(arg.id);
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
);

export const saveAccount = createAsyncThunk(
  'map/saveAccount',
  async (arg: { account: any }, { rejectWithValue }) => {
    try {
      if (arg.account.id) {
        return await putAccount(arg.account);
      } else {
        return await postAccount(arg.account);
      }
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
);

export const delAccount = createAsyncThunk(
  'map/delAccount',
  async (arg: { account: any }, { rejectWithValue }) => {
    try {
      return await deleteAccount(arg.account.id);
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
);

export const downloadLog = createAsyncThunk(
  'map/downloadLog',
  async (arg, { rejectWithValue }) => {
    try {
      return await callDownloadLogs(arg);
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
);

export const downloadLogShokushu = createAsyncThunk(
  'map/downloadLogShokushu',
  async (arg, { rejectWithValue }) => {
    try {
      return await callDownloadLogsShokushu(arg);
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
);
