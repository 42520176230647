import {createSlice} from "@reduxjs/toolkit";
import {deleteClient, fetchClientDetail, saveClient} from "./asyncThunk";

// export const msgType = {
//     SUCCESS: "success",
//     ERROR: "error"
// }

const emptyClient = {
    client_id: null,
    user_id: '',
    user_pass: '',
    user_name: '',
    dir_name: '',
    last_login_date: '',
    prc_user_cd: '',
    created_date: '',
    prc_date: '',
    media: []
}

// const fieldErrMsg = {
//     user_id: 'この項目は必須です。',
//     user_pass: 'この項目は必須です。',
//     user_name: 'この項目は必須です。',
//     dir_name: 'この項目は必須です。',
//     media: 'この項目は必須です。'
// }

const initialState = {
    client: emptyClient,
    redirectPath: null,
    fieldErrorFlg: {
        user_id: false,
        user_pass: false,
        user_name: false,
        dir_name: false,
        media: false
    },
    fieldErrorTxt: {
        user_id: '',
        user_pass: '',
        user_name: '',
        dir_name: '',
        media: ''
    }
};

const slice = createSlice({
    name: "clientRegister",
    initialState,
    reducers: {
        changeClientData(state, action) {
            state.client = {...state.client, [action.payload.key]: action.payload.value}
        },
        changeErrorState(state, action) {
            let field = action.payload.field
            state.fieldErrorFlg[field] = action.payload.errorFlg
            state.fieldErrorTxt[field] = action.payload.errorTxt
        },
        validateClient(state, action) {
            validateClientField(state)
        },
        resetState(state, action) {
            state.client = initialState.client
            state.redirectPath = initialState.redirectPath
            state.fieldErrorFlg = initialState.fieldErrorFlg
            state.fieldErrorTxt = initialState.fieldErrorTxt
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchClientDetail.fulfilled, (state, action) => {
            state.client = action.payload.data
            // validateClientField(state)
            state.redirectPath = null
        });
        builder.addCase(fetchClientDetail.rejected, (state, action) => {
            state.client = emptyClient
            state.redirectPath = "/pages/listui/clients"
        });
        builder.addCase(saveClient.fulfilled, (state, action) => {
            if (action.payload.data && action.payload.data.client_id) {
                state.redirectPath = `/pages/listui/clients/${action.payload.data.client_id}`
            }
        });
        builder.addCase(saveClient.rejected, (state, action) => {
            state.redirectPath = null
        });
        builder.addCase(deleteClient.fulfilled, (state, action) => {
            state.redirectPath = "/pages/listui/clients"
        });
        builder.addCase(deleteClient.rejected, (state, action) => {
            state.redirectPath = null
        });
    },
});

export const clientRegister = slice.reducer;
export const {changeClientData, changeErrorState, resetState, validateClient} = slice.actions;

const validateClientField = (state) => {
    for (const [key] of Object.entries(state.fieldErrorFlg)) {
        if (key !== 'media') {
            if (state.client[key].trim() === '') {
                state.fieldErrorFlg[key] = true
                state.fieldErrorTxt[key] = "この項目は必須です。"
            } else {
                state.fieldErrorFlg[key] = false
                state.fieldErrorTxt[key] = ""
            }
        } else {
            if (Object.keys(state.client[key]).length === 0) {
                state.fieldErrorFlg[key] = true
                state.fieldErrorTxt[key] = "この項目は必須です。"
            } else {
                state.fieldErrorFlg[key] = false
                state.fieldErrorTxt[key] = ""
            }
        }
    }
}

