import { saveAs } from "file-saver";

export const downloadFromResponse = (response) => {
  const blob = new Blob([response.data], {
    type: response.data.type
  });

  //レスポンスヘッダからファイル名を取得します
  const contentDisposition = response.headers["content-disposition"];
  const fileName = getFileName(contentDisposition)

  //ダウンロードします
  saveAs(blob, fileName);
}

function getFileName(contentDisposition){
    let fileName = contentDisposition.substring(contentDisposition.indexOf("filename=") + 9,
                                                contentDisposition.length
                                                );
    //デコードするとスペースが"+"になるのでスペースへ置換します
    fileName = decodeURI(fileName).replace(/\+/g, " ");

    return fileName;
}
