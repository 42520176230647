import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import Cookies from "universal-cookie";
import { history } from "../stores/store";
import { setMessages } from "../stores/alertSnackbarStore";
import { store } from "../index";
import { turnOffMenu } from "../stores/menu/menuStore";

const cookies = new Cookies("auth");

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "content-type": "application/json",
  },
  responseType: "json",
});

const refreshAuthLogic = (failedRequest) => {
  return axiosInstance
    .post("api/refresh/", {
      refresh: cookies.get("refresh", { path: "/" }),
    })
    .then((tokenRefreshResponse) => {
      cookies.remove("token", { path: "/" });
      cookies.remove("refresh", { path: "/" });
      cookies.set("token", tokenRefreshResponse.data.access, { path: "/" });
      cookies.set("refresh", tokenRefreshResponse.data.refresh, { path: "/" });
      failedRequest.response.config.headers["Authorization"] =
        "Bearer " + tokenRefreshResponse.data.access;
      return Promise.resolve();
    })
    .catch((error) => {
      cookies.remove("token", { path: "/" });
      cookies.remove("refresh", { path: "/" });
      cookies.remove("username", { path: "/" });
      cookies.remove("email", { path: "/" });
      cookies.remove("fullname", { path: "/" });
      //pushToLogin();
      history.push("/pages/login");
      store.dispatch(turnOffMenu());
      store.dispatch(
        setMessages([
          {
            severity: "error",
            message:
              "セッションの有効期限が切れています！もう一度ログインしてください！",
          },
        ])
      );
      return Promise.reject(error);
    });
};

createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic);

axiosInstance.interceptors.request.use(function (config) {
  const token = cookies.get("token", { path: "/" });
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

export default axiosInstance;
