import moment from 'moment';

const STRICT_LOGIN = {
  true: '不可',
  false: '可'
}

const INVALID = {
  true: '無効',
  false: ''
}

export const getisStrictLoginName = (key) => {
  return STRICT_LOGIN[Boolean(key.is_strict_login).toString()]
}

export const getInvalidName = (key) => {
  return INVALID[Boolean(key.is_invalid).toString()]
}

export const convertDateTime = (value) => {
  return moment(value).format("YYYY/MM/DD HH:mm");
}

export const getDefaultLogStartAt = () => {
  var date = new Date();
  return new Date(date.getFullYear(), date.getMonth() -1, 1);
}

export const getDefaultLogEndAt = () => {
  var date = new Date();
  return new Date(date.getFullYear(), date.getMonth(), 0);
}
