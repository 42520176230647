import React from "react";
import { ConnectedRouter } from "connected-react-router";
import "./App.css";
import { history } from "./stores/store";
import ClientRegisterPage from "./pages/listui/client/ClientRegisterPage";
import ClientListPage from "./pages/listui/client/ClientListPage";
import DeliveryFileListPage from "./pages/listui/deliveryfile/DeliveryFileListPage";
import { Switch } from "react-router-dom";
import HRogRouter, { PrivateRoute } from "./routers/HRogRouter";
import AccountListPage from "./pages/listlight/account/AccountListPage";
import AccountDetailPage from "./pages/listlight/account/AccountDetailPage";
import LoginPage from "./pages/LoginPage";
import MasterListPage from "./pages/listlight/master/MasterListPage";
import MasterDetailPage from "./pages/listlight/master/MasterDetailPage";
import SideBar from "./components/SideBar";
import AlertSnackbar from "./components/AlertSnackbar";
import ChartAccountList from "./pages/chart/accounts/ChartAccountList";
import ChartAccountDetailPage from "./pages/chart/accounts/ChartAccountDetailPage";
import LoginRoute from "./routers/LoginRouter";
import ChartMasterList from "./pages/chart/masters/ChartMasterList";
import ChartMasterDetail from "./pages/chart/masters/ChartMasterDetail";
import MapAccountListPage from "./pages/map/account/MapAccountListPage";
import MapAccountDetailPage from "./pages/map/account/MapAccountDetailPage";
import MapLogDownloadPage from "./pages/map/log/MapLogDownloadPage";
import AdminAccountListPage from "./pages/admin/AdminAccountListPage";
import AdminAccountDetailPage from "./pages/admin/AdminAccountDetailPage";
import ChartLogPage from "./pages/chart/logs/ChartLogPage";
import HotLogPage from "./pages/listlight/log/HotLogPage";
import MediaListPage from "./pages/listui/media/MediaListPage";
import MediaRegisterPage from "./pages/listui/media/MediaRegisterPage";

function App() {
  return (
    <div className="App">
      <ConnectedRouter history={history}>
        {/* <Header></Header> */}
        <AlertSnackbar></AlertSnackbar>
        <div className="frame">
          <SideBar></SideBar>
          <Switch>
            <LoginRoute exact path="/pages/login">
              <LoginPage></LoginPage>
            </LoginRoute>
            {/* <PrivateRoute exact path="/pages/logout">
              <LogoutPage></LogoutPage>
            </PrivateRoute> */}
            <PrivateRoute exact path="/pages/chart/register">
              {/* <Register></Register> */}
              <ChartAccountDetailPage></ChartAccountDetailPage>
            </PrivateRoute>
            <PrivateRoute exact path="/pages/listui/clients/:id">
              <ClientRegisterPage></ClientRegisterPage>
            </PrivateRoute>
            <PrivateRoute exact path="/pages/listui/clients">
              <ClientListPage></ClientListPage>
            </PrivateRoute>
            <PrivateRoute exact path="/pages/listui/nouhinfile">
              <DeliveryFileListPage></DeliveryFileListPage>
            </PrivateRoute>
            <PrivateRoute exact path="/pages/listui/baitai">
              <MediaListPage/>
            </PrivateRoute>
            <PrivateRoute exact path="/pages/listui/baitai/:id">
              <MediaRegisterPage/>
            </PrivateRoute>
            <PrivateRoute exact path="/pages/listlight/accounts">
              <AccountListPage></AccountListPage>
            </PrivateRoute>
            <PrivateRoute exact path="/pages/listlight/accounts/:id">
              <AccountDetailPage></AccountDetailPage>
            </PrivateRoute>
            <PrivateRoute exact path="/pages/listlight/masters">
              <MasterListPage></MasterListPage>
            </PrivateRoute>
            <PrivateRoute exact path="/pages/listlight/logs">
              <HotLogPage></HotLogPage>
            </PrivateRoute>
            <PrivateRoute exact path="/pages/listlight/masters/:id">
              <MasterDetailPage></MasterDetailPage>
            </PrivateRoute>
            <PrivateRoute exact path="/pages/chart/accounts">
              <ChartAccountList></ChartAccountList>
            </PrivateRoute>
            <PrivateRoute exact path="/pages/chart/masters">
              <ChartMasterList></ChartMasterList>
            </PrivateRoute>
            <PrivateRoute exact path="/pages/chart/masters/item/:id">
              <ChartMasterDetail></ChartMasterDetail>
            </PrivateRoute>
            <PrivateRoute exact path="/pages/chart/masters/register">
              <ChartMasterDetail></ChartMasterDetail>
            </PrivateRoute>
            <PrivateRoute exact path="/pages/chart/accounts/:id">
              <ChartAccountDetailPage></ChartAccountDetailPage>
            </PrivateRoute>
            <PrivateRoute exact path="/pages/map/accounts">
              <MapAccountListPage></MapAccountListPage>
            </PrivateRoute>
            <PrivateRoute exact path="/pages/map/accounts/:id">
              <MapAccountDetailPage></MapAccountDetailPage>
            </PrivateRoute>
            <PrivateRoute exact path="/pages/map/logs">
              <MapLogDownloadPage></MapLogDownloadPage>
            </PrivateRoute>
            <PrivateRoute exact path="/pages/admin/accounts">
              <AdminAccountListPage></AdminAccountListPage>
            </PrivateRoute>
            <PrivateRoute exact path="/pages/admin/accounts/item/:id">
              <AdminAccountDetailPage></AdminAccountDetailPage>
            </PrivateRoute>
            <PrivateRoute exact path="/pages/admin/accounts/new">
              <AdminAccountDetailPage></AdminAccountDetailPage>
            </PrivateRoute>
            <PrivateRoute exact path="/pages/chart/logs">
              <ChartLogPage></ChartLogPage>
            </PrivateRoute>
            {/*All other route*/}
            <HRogRouter path="*" />
          </Switch>
        </div>
        {/* <ChartDialog></ChartDialog> */}
      </ConnectedRouter>
    </div>
  );
}

export default App;
