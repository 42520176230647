import React from "react";
import {
  makeStyles,
  TextField,
  InputAdornment,
  Button,
  LinearProgress,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import SearchIcon from "@material-ui/icons/Search";
import { unwrapResult } from "@reduxjs/toolkit";
import CalendarToday from "@material-ui/icons/CalendarToday";
import {
  selectChange,
  resetState,
} from "../../../stores/listlight/hotLogStore";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { setMessages } from "../../../stores/alertSnackbarStore";
import { downloadLog } from "../../../stores/listlight/asyncThunk";
import { downloadFromResponse } from "../../../helper/fileDownload";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: theme.spacing(3),
  },
  searchConatiner: {
    display: "flex",
  },
  search: {
    //padding: theme.spacing(1),
    backgroundColor:'white',
  },
  action: {
    padding: theme.spacing(1),
  },
  progress: {
    padding: theme.spacing(1),
    height: 4,
  }
}));

const LogInput = ({ title, value, onClick }) => (
  <TextField
    label={title}
    variant="outlined"
    value={value}
    onClick={onClick}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <CalendarToday />
        </InputAdornment>
      ),
    }}
  />
);

const HotLogPage = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { startDate, endDate, search } = useSelector((state) => state.hotLog);
  const [open, setOpen] = React.useState(false);
  const handleChange = (label, event) => {
    let value = null;
    if (label === "search") {
      value = event.target.value;
    } else {
      value = event;
    }
    dispatch(selectChange({ field: label, value: value }));
  };
  React.useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, [dispatch]);

  const handleDownload = () => {
    setOpen(true);
    dispatch(
      downloadLog({
        startDate: startDate,
        endDate: endDate,
        search: search,
      })
    )
      .then(unwrapResult)
      .then((result) => {
        setOpen(false);
        downloadFromResponse(result);
        dispatch(
          setMessages([
            { severity: "success", message: "ダウンロードに成功しました。" },
          ])
        );
      })
      .catch((error) => {
        console.error(error);
        setOpen(false);
        dispatch(
          setMessages([
            { severity: "error", message: "ダウンロードに失敗しました。" },
          ])
        );
      });
  };
  return (
    <div className={classes.root}>
      <div className={classes.searchConatiner}>
        <div className={classes.search}>
          <TextField 
            label="ユーザー名"
            variant="outlined"
            value={search}
            onChange={(e) => {
              handleChange("search", e);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className={classes.search} >
          <DatePicker 
            title="ログ開始日"
            dateFormat="yyyy/MM/dd"
            selected={startDate}
            onChange={(date) => handleChange("startDate", date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            maxDate={endDate}
            monthsShown={2}
            customInput={<LogInput />}
          />
        </div>
        <div className={classes.search}>
          <DatePicker 
            title="ログ終了日"
            dateFormat="yyyy/MM/dd"
            selected={endDate}
            onChange={(date) => handleChange("endDate", date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            maxDate={new Date()}
            monthsShown={2}
            customInput={<LogInput />}
          />
        </div>
      </div>
      <div className={classes.progress}>
        <LinearProgress style={{ display: open ? "block" : "none" }} />
      </div>
      <div className={classes.action}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<CloudDownloadIcon />}
          onClick={handleDownload}
        >
          ダウンロード
        </Button>
      </div>
    </div>
  );
};

export default HotLogPage;
