import * as moment from "moment";
const getErrorText = (field, code = 0) => {
  const name = field.charAt(0).toUpperCase() + field.slice(1);
  switch (code) {
    case 1:
      return name + "が必要です";
    case 2:
      return name + "は4文字以上です";
    case 5:
      return "日付の形式が正しくありません";
    case 7:
      return name + " は月曜日のみです";
    case 10:
      return "この媒体は利用できません";
    case 11:
      return "利用不可の媒体があるので、その媒体を削除しました";
    case 20:
      return "数値形式を使用してください";
    case 21:
      return "負の数は使用しないでください";
    case 22:
      return "大きすぎる";
    case 30:
      return "nullまたはblankではありません";
    default:
      return "";
  }
};
export const getShortErrorText = (field, code = 0) => {
  switch (code) {
    case 1:
      return "必要です";
    case 2:
      return " > 4文字";
    case 5:
      return "不正な形式";
    case 7:
      return "月曜日のみです";
    case 10:
      return "この媒体は利用できません";
    case 11:
      return "利用不可の媒体があるので、その媒体を削除しました";
    case 20:
      return "数ではない";
    case 21:
      return "0未満";
    case 22:
      return "大きすぎる";
    case 30:
      return "null|blankではない";
    default:
      return "";
  }
};
export const getNote = (code = 0) => {
  switch (code) {
    case 1:
      return "このgroupCdはすでにあります";
    default:
      return "";
  }
};
export const checkError = (value, checkTypes = []) => {
  var error = 0;
  for (let index = 0; index < checkTypes.length; index++) {
    const type = checkTypes[index];
    switch (type) {
      case 1:
        if (isNullorBlank(value)) {
          error = 1;
        }
        break;
      case 2:
        if (value && value.length < 4) {
          error = 2;
        }
        break;
      case 5:
        if (!moment(value, "YYYY/MM/DD", true).isValid()) {
          error = 5;
        }
        break;
      case 6:
        if (
          moment(value, "YYYY/MM/DD", true).isAfter(
            moment("3000/01/01", "YYYY/MM/DD")
          )
        ) {
          error = 6;
        }
        break;
      case 7:
        if (
          moment(value, "YYYY/MM/DD", true).day("Monday") !==
          moment(value, "YYYY/MM/DD", true)
        )
          error = 7;
        break;
      case 20:
        if (Number.isNaN(+value)) {
          error = 20;
        }
        break;
      case 21:
        if (+value < 0) {
          error = 21;
        }
        break;
      case 22:
        if (+value > 5000) {
          error = 22;
        }
        break;
      case 30:
        if (isNullorBlank(value)) {
          error = 30;
        }
        break;
      default:
        break;
    }
    if (error > 0) {
      break;
    }
  }
  return error;
};
export const isNullorBlank = (value) => {
  return value === null || value === "";
};

export default getErrorText;
