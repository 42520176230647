import { createSlice } from "@reduxjs/toolkit";
import { getAccount, saveAccount, delAccount, fetchMasters, saveMaster } from "./asyncThunk";
import { Account } from "../../types/listlight";

const emptyAccount: Account = {
  id: null,
  name: "",
  company_name: "",
  tel: "",
  email: "",
  account_id: "",
  password: "",
  master_id: null,
  account_type: null,
  notify_update_data: false,
  is_strict_login: false,
  start_at: null,
  expire_at: null,
  is_used: true,
  is_admin: false,
  is_invalid: false
}

// Stateの初期状態
const initialState = {
  account: emptyAccount,
  masters: [],
  isLoading: false,
  afterSave: false,
  error: {},
  redirectPath: null,
  masterDialog: {
    error: null,
    isSaveSuccess: false
  }
};

// Sliceを生成する
const slice = createSlice({
  name: "accountDetail",
  initialState,
  reducers: {
    resetState (state, action) {
      state.account = emptyAccount
      state.isLoading = false
      state.afterSave = false
      state.error = {}
      state.masterDialog = {error: null, isSaveSuccess: false}
    },
    resetDialogState (state, action) {
      state.masterDialog = {error: null, isSaveSuccess: false}
    },
    changeAccountData (state, action) {
      state.account = { ...state.account, [action.payload.key]: action.payload.value }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMasters.fulfilled, (state, action) => {
      state.masters = action.payload.data.results
    });
    builder.addCase(fetchMasters.rejected, (state, action) => {
      state.masters = []
    });
    builder.addCase(saveMaster.fulfilled, (state, action) => {
      state.account.master_id = action.payload.data.id;
      state.masters.push(action.payload.data)
      state.masterDialog.isSaveSuccess = true
    });
    builder.addCase(saveMaster.rejected, (state, action) => {
      state.masterDialog.error = action.payload
    });
    builder.addCase(getAccount.fulfilled, (state, action) => {
      state.account = action.payload.data
      state.error = {}
      state.redirectPath = null
    });
    builder.addCase(getAccount.rejected, (state, action) => {
      state.redirectPath = "/pages/listlight/accounts"
    });
    builder.addCase(saveAccount.fulfilled, (state, action) => {
      state.account = action.payload.data
      state.afterSave = true
      state.error = {}
      state.redirectPath = null
    });
    builder.addCase(saveAccount.rejected, (state, action) => {
      state.error = action.payload
      state.redirectPath = null
    });
    builder.addCase(delAccount.fulfilled, (state, action) => {
      state.account = action.payload.data
      state.afterSave = true
      state.error = {}
      state.redirectPath = "/pages/listlight/accounts"
    });
    builder.addCase(delAccount.rejected, (state, action) => {
      state.error = action.payload
      state.redirectPath = null
    });
  },
});

// Reducerをエクスポートする
export const accountDetail = slice.reducer;
// Action Creatorsをエクスポートする
export const { resetState, resetDialogState, changeAccountData } = slice.actions;
