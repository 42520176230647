import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  startDate: "",
  endDate: new Date(),
  search: ""
};
const slice = createSlice({
  name: "chart/log",
  initialState,
  reducers: {
    resetState: (state, action) => {
      state = initialState;
    },
    selectChange: (state, action) => {
      state[action.payload.field] = action.payload.value;
    },
  },
});
export const { selectChange, resetState } = slice.actions;
export const chartLog = slice.reducer;
