import { createSlice } from "@reduxjs/toolkit";
import { getHeaderPath } from "../../components/labels";

const initialState = {
  titleId: 2,
  side: 1,
  titlePath: "/pages/chart/accounts",
  isChange: false,
  show: false,
  path: "/pages/chart/accounts",
};

const slice = createSlice({
  name: "header",
  initialState,
  reducers: {
    onTab: {
      reducer: (state, action) => {
        state["titleId"] = action.payload;
        state["titlePath"] = getHeaderPath(action.payload, state.side);
        state.isChange = true;
        return state;
      },
    },
    onSide: {
      reducer: (state, action) => {
        state["side"] = action.payload;
        state.isChange = true;
        state["titlePath"] = getHeaderPath(state.titleId, action.payload);
        return state;
      },
    },
    hasChanged: {
      reducer: (state, action) => {
        state.isChange = false;
        return state;
      },
    },
    setMenu: {
      reducer: (state, action) => {
        state["titleId"] = action.payload["titleId"];
        state["side"] = action.payload["side"];
        state["titlePath"] = getHeaderPath(state["titleId"], state["side"]);
        state.isChange = false;
        state.show = true;
        return state;
      },
    },
    resetMenu: {
      reducer: (state, action) => {
        state = initialState;
        return state;
      },
    },
    setOffShow: {
      reducer: (state, action) => {
        state.show = false;
        return state;
      },
    },
    setPath: {
      reducer: (state, action) => {
        state.path = action.payload;
        return state;
      },
    },
  },
});

export const {
  onTab,
  onSide,
  hasChanged,
  setMenu,
  setOffShow,
  setPath,
  resetMenu,
} = slice.actions;
export const header = slice.reducer;
