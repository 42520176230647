import {createSlice} from "@reduxjs/toolkit";
import {fetchMediaSelectList} from "./asyncThunk";

const initialState = {
    media: [],
    mediaFull: []
};

const slice = createSlice({
    name: "mediaSelectList",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(fetchMediaSelectList.fulfilled, (state, action) => {
            state.media = action.payload.data
            if (state.mediaFull.length === 0){
                state.mediaFull = action.payload.data
            }
        });
        builder.addCase(fetchMediaSelectList.rejected, (state, action) => {
            state.media = []
        });
    },
});

export const mediaSelectList = slice.reducer;