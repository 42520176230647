import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { menuLabels, headerLabels } from "./labels";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "universal-cookie";
import { Menu, MenuItem, Button } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { goToTheSide } from "../stores/menu/menuStore";
import mainLogo from "../images/HRog.svg";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 0,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    // height: "100%",
    maxWidth: 177,
  },
  tabs: {
    marginTop: 55,
    "& .Mui-selected": {
      backgroundColor: "#e4f4f4",
    },
  },
  tab: {
    "&:hover": {
      backgroundColor: "#e4f4f4",
    },
  },
  tab1: {
    "&:hover": {
      backgroundColor: "#e4f4f4",
    },
    marginBottom: 55,
  },
  tabTitle0: (props) => ({
    position: "absolute",
    // top: 0 + 36 + 90,
    top: props.tabTitle0,
    width: "100%",
    textAlign: "left",
  }),
  tabTitle1: (props) => ({
    position: "absolute",
    top: props.tabTitle1,
    width: "100%",
    textAlign: "left",
  }),
  tabTitle2: (props) => ({
    position: "absolute",
    top: props.tabTitle2,
    width: "100%",
    textAlign: "left",
  }),
  tabTitle3: (props) => ({
    position: "absolute",
    top: props.tabTitle3,
    width: "100%",
    textAlign: "left",
  }),
  tabTitle4: (props) => ({
    position: "absolute",
    top: props.tabTitle4,
    width: "100%",
    textAlign: "left",
  }),
  tabTitle5: (props) => ({
    position: "absolute",
    top: props.tabTitle5,
    width: "100%",
    textAlign: "left",
  }),
  tabContain: {
    position: "relative",
    height: "100vh",
    overflowY: "auto",
    overflowX: "hidden",
  },
  text: {
    borderTop: "1px solid #e8e8f2",
    margin: "10px 3px",
    padding: 5,
    color: "#263d81",
    fontWeight: "bolder",
    fontSize: 20,
  },
  menuButton: {
    backgroundColor: "inherit",
    boxShadow: "none",
    width: "100%",
    padding: "6px 3px",
    "& .username": {
      whiteSpace: "normal",
    },
    "& .ellipsis": {
      display: "-webkit-box",
      overflow: "hidden",
      textOverflow: "ellipsis",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
    },
    "& .username2": {
      wordBreak: "break-word",
    },
  },
  logo: {
    height: 70,
    padding: "10px 3px",
  },
  image: {
    height: "100%",
    width: "100%",
  },
}));
const options = ["Logout"];
const ITEM_HEIGHT = 48;
const SideBar = () => {
  // const classes = useStyles();
  const menu = useSelector((state) => state.menu);
  const { path, isChange, show, side } = menu;
  const history = useHistory();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  React.useEffect(() => {
    if (path) history.push(path);
  }, [path, history, dispatch, isChange]);
  const handleChange = (event, newValue) => {
    dispatch(goToTheSide(newValue));
  };
  const open = Boolean(anchorEl);
  const fullname = new Cookies("auth").get("fullname", { path: "/" });
  const is_superuser = new Cookies("auth").get("is_superuser", { path: "/" });
  var nameClass = "username";
  var buttonH = 36;
  var ex = 5;
  if (fullname) {
    if (fullname.length >= 15) {
      nameClass += " ellipsis username2";
      buttonH = 48;
      ex = 15;
    }
    // else if (fullname.length > 16) {
    //   nameClass = "username2";
    //   buttonH = 60;
    // }
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (option) => {
    setAnchorEl(null);
    if (option === "Logout") {
      const cookies = new Cookies("auth");
      cookies.remove("token", { path: "/" });
      cookies.remove("refresh", { path: "/" });
      cookies.remove("username", { path: "/" });
      cookies.remove("email", { path: "/" });
      cookies.remove("fullname", { path: "/" });
      cookies.remove("is_superuser", { path: "/" });
      document.location.href = "/";
    }
  };
  var menuL = [];
  menuLabels.forEach((v, i) => {
    if (i === 0) {
      if (is_superuser === "true") {
        menuL.push(v);
      }
    } else {
      menuL.push(v);
    }
  });

  var obj = {};
  for (let index = 0; index < headerLabels.length; index++) {
    if (index > 0) {
      let index0 = is_superuser === "true" ? 3 * index - 2 : 2 * index + 1;
      let index1 = 55 * index;
      if (is_superuser === "true") {
        if (index > 2) {
          index0 = 3 * index - 2;
        }
      } else {
        if (index > 1) {
          index0 = 3 * index;
        }
      }
      let index2 = 48 * index0 + index1 + buttonH + 90 + ex;
      obj[`tabTitle${index}`] = index2;
    } else {
      obj[`tabTitle${index}`] = 0 + buttonH + 90 + ex;
    }
  }
  const classes = useStyles(obj);

  const tabs = menuL.map((label, index) => {
    var condition = index === 2 || index === 5 || index === 8;
    if (is_superuser === "true") {
      condition = index === 0 || index === 3 || index === 6 || index === 9;
    }
    return (
      <Tab
        key={index}
        className={condition ? classes.tab1 : classes.tab}
        label={label}
        {...a11yProps(index)}
      />
    );
  });
  const t = (
    <Tabs
      orientation="vertical"
      variant="scrollable"
      value={side}
      onChange={handleChange}
      aria-label="Vertical tabs example"
      className={classes.tabs}
      TabIndicatorProps={{
        style: {
          left: "0",
          width: "3px",
          backgroundColor: "#66c6dd",
        },
      }}
    >
      {tabs}
    </Tabs>
  );

  var titles = [];
  var titleLabels = headerLabels;
  if (!(is_superuser === "true"))
    titleLabels = headerLabels.filter((v, i) => i > 0);
  for (let index = 0; index < titleLabels.length; index++) {
    const element = titleLabels[index];
    titles.push(
      <div key={index} className={classes[`tabTitle${index}`]}>
        <div className={classes.text}>{element}</div>
      </div>
    );
  }
  return (
    <div className={classes.root}>
      {!show ? (
        ""
      ) : (
        <div className={classes.tabContain}>
          <div className={classes.logo}>
            <img className={classes.image} src={mainLogo} alt="HRog Logo"></img>
          </div>
          <Button
            variant="contained"
            className={classes.menuButton}
            onClick={handleClick}
            endIcon={<ArrowDropDownIcon />}
          >
            <span className={nameClass}>{fullname ? fullname : "Default"}</span>
          </Button>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: "20ch",
              },
            }}
          >
            {options.map((option) => (
              <MenuItem
                key={option}
                selected={option === "Pyxis"}
                onClick={(e) => handleClose(option)}
              >
                {option}
              </MenuItem>
            ))}
          </Menu>
          {titles}
          {t}
        </div>
      )}
    </div>
  );
};

export default SideBar;
