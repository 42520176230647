import moment from "moment";
import axios from "../components/AxiosCustomInstance";
import { Account, Master, FetchAccountParams } from "../types/listlight";

const api_prefix = `${process.env.REACT_APP_API_URL}/api/listlight`;

export const getAccountList = async (params: FetchAccountParams) => {
  let query = `pageNum=${params.page + 1}&pageSize=${params.limit}`;
  if (params.search) {
    query = query + `&search=${params.search}`;
  }
  if (params.order) {
    const ascParam = params.asc === "desc" ? "-" : "";
    query = query + `&ordering=${ascParam + params.order}`;
  }
  if (params.expireAt) {
    const fromDate = new Date(
      params.expireAt.getFullYear(),
      params.expireAt.getMonth(),
      1,
      0,
      0,
      0
    );
    const toDate = new Date(
      params.expireAt.getFullYear(),
      params.expireAt.getMonth() + 1,
      0,
      23,
      59,
      59
    );
    const from = moment(fromDate).format("YYYY-MM-DD HH:mm:ss");
    const to = moment(toDate).format("YYYY-MM-DD HH:mm:ss");
    query = query + `&expire_at_before=${to}&expire_at_after=${from}`;
  }

  return await axios.get(`${api_prefix}/accounts?${encodeURI(query)}`);
};

export const getAccountDetail = async (id) => {
  return await axios.get(`${api_prefix}/accounts/${id}`);
};

export const postAccount = async (account: Account) => {
  return await axios.post(`${api_prefix}/accounts`, account);
};

export const putAccount = async (account: Account) => {
  return await axios.put(`${api_prefix}/accounts/${account.id}`, account);
};

export const deleteAccount = async (id) => {
  return await axios.delete(`${api_prefix}/accounts/${id}`);
};

export const getMasterList = async (page, limit, search = "") => {
  return await axios.get(
    `${api_prefix}/masters?pageNum=${
      page + 1
    }&pageSize=${limit}&search=${search}`
  );
};

export const getMasterDetail = async (id) => {
  return await axios.get(`${api_prefix}/masters/${id}`);
};

export const postMaster = async (master: Master) => {
  return await axios.post(`${api_prefix}/masters`, master);
};

export const putMaster = async (master: Master) => {
  return await axios.put(`${api_prefix}/masters/${master.id}`, master);
};

export const deleteMaster = async (id) => {
  return await axios.delete(`${api_prefix}/masters/${id}`);
};

export const getLog = async (params) => {
  let start = "start=";
  let end = "end=";
  let name = "name=";
  if (params.startDate) {
    const from = moment(params.startDate);
    if (from.isValid()) start = `start=${from.format("YYYY/MM/DD")}`;
  }
  if (params.endDate) {
    const to = moment(params.endDate);
    if (to.isValid()) end = `end=${to.format("YYYY/MM/DD")}`;
  }
  if (params.search) {
    name = `name=${params.search}`;
  }
  let arrays = [name, start, end];
  let query = arrays.join("&");
  const resp = await axios
    .get(`${api_prefix}/logs?${encodeURI(query)}`, {
      responseType: "blob",
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return resp;
};

export const getAllMedia = async () => {
  const resp = await axios
    .get(`${api_prefix}/masters/allmedia`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return resp;
};
