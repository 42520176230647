import axios from "../components/AxiosCustomInstance"

const api_prefix = `${process.env.REACT_APP_API_URL}/api/listui`

export const callGetClientListApi = async (page, limit, search="") => {
    return await axios.get(
        `${api_prefix}/clients?pageNum=${page+1}&pageSize=${limit}&search=${search}`
    );
}

export const callGetClientDetailApi = async (id: number) => {
    return await axios.get(
        `${api_prefix}/clients/${id}`
    );
}

export const callGetMediaSelectList = async (page, limit, search="") => {
    return await axios.get(
        `${api_prefix}/mediachooselist?search=${search}`
    );
}

export const callSaveClient = async (client) => {
    if (client.client_id !== null){
        return await axios.put(
            `${api_prefix}/clients/${client.client_id}/`,
            client
        );
    }

    else {
        return await axios.post(
            `${api_prefix}/clients/`,
            client
        );
    }
}

export const callDeleteClient = async (clientId) => {
    return await axios.delete(
            `${api_prefix}/clients/${clientId}/`
        );
}

export const callGetFileListApi = async (page, limit, search="") => {
    return await axios.get(
        `${api_prefix}/filedata?pageNum=${page+1}&pageSize=${limit}&search=${search}`
    );
}

export const callDeleteFiledata = async (fileId) => {
    return await axios.delete(
            `${api_prefix}/filedata/${fileId}/`
        );
}

export const callGetMediaListApi = async (page, limit, search="") => {
    return await axios.get(
        `${api_prefix}/media?pageNum=${page+1}&pageSize=${limit}&search=${search}`
    );
}

export const callGetMediaDetailApi = async (id: number) => {
    return await axios.get(
        `${api_prefix}/media/${id}`
    );
}

export const callSaveMedia = async (media) => {
    if (media.media_id !== null){
        return await axios.put(
            `${api_prefix}/media/${media.media_id}/`,
            media
        );
    }

    else {
        return await axios.post(
            `${api_prefix}/media/`,
            media
        );
    }
}

export const callDeleteMedia = async (clientId) => {
    return await axios.delete(
            `${api_prefix}/media/${clientId}/`
        );
}

export const callChangeFileDownloadStatus = async (fileId, downloadedFlag) => {
    return await axios.put(
        `${api_prefix}/filedata/changestatus/${fileId}/`,{downloaded_flag:downloadedFlag}
    );
}