import React, {useEffect} from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { Container, Paper, makeStyles, Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import {
  getAccountDetail,
  updateAccountDetail,
  doRegistration,
} from "../../../stores/chart/asyncThunk";
import {
  openDetail,
  closeDetail,
  checkErrorForAll,
  setCheckTypes,
} from "../../../stores/chart/chartStore";
import EditTextField from "../../../components/chart/edit/EditTextField";
import * as moment from "moment";
import EditRadioField from "../../../components/chart/edit/EditRadioField";
import EditDateField from "../../../components/chart/edit/EditDateField";
import EditSelectField from "../../../components/chart/edit/EditSelectField";
import SubdirectoryArrowLeftIcon from "@material-ui/icons/SubdirectoryArrowLeft";
import { setMessages } from "../../../stores/alertSnackbarStore";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { errorCover } from "../../../components/chart/errorCover";
import Cookies from "universal-cookie";
import EditAutoComplete from "../../../components/chart/edit/EditAutoComplete";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    overflow: "auto",
  },
  container: {
    padding: 70,
  },
  paper: {
    padding: 75,
    paddingBottom: 0,
  },
  pTab: {
    display: "inline",
    margin: 10,
  },
  info: {
    padding: 10,
    textAlign: "right",
  },
  btnGroup: {
    textAlign: "center",
    padding: "30px 0px",
    "& button": {
      margin: "0px 15px",
    },
  },
  back: {
    "& button": {
      backgroundColor: theme.palette.background.paper,
      "&:hover": {
        backgroundColor: theme.palette.background.paper,
      },
    },
  },
  textColor: {
    color: "#3f51b5",
  },
  button:  {
    backgroundColor: 'white',
    border: "solid #3f51b5",
  }
}));

const ChartAccountDetailPage = (props) => {
  const { id } = useParams();
  const location = useLocation();
  const { pathname } = location;
  const isRegister = pathname === "/pages/chart/register";
  const classes = useStyles();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.chartDetail);
  const { item, options, statusList, multiLogins, acTypeArr } = state;
  const [confirm, setConfirm] = React.useState({ open: false, message: "" });
  const history = useHistory();
  React.useEffect(() => {
    dispatch(openDetail());
    if (!isRegister)
      dispatch(getAccountDetail(id))
        .then((res) => {
          var error = errorCover(res);
          if (error !== null)
            dispatch(
              setMessages([
                { severity: error.severity, message: error.message },
              ])
            );
        })
        .catch((error) => {
          dispatch(
            setMessages([
              { severity: "error", message: "データの取得に失敗しました。" },
            ])
          );
        });
    return () => {
      dispatch(closeDetail());
    };
  }, [id, dispatch, isRegister]);
  
  
  useEffect(() => {
    // 新規登録の場合はパスワード必須、編集の場合は空欄可
    dispatch(setCheckTypes(
        {
          field: "password",
          checkTypes: isRegister ? [1, 2] : [2]}
    ));
  }, [isRegister, dispatch]);
  
  const handleYes = () => {
    var request = {};
    for (let field in item) {
      var i = item[field];
      if (i["error"] !== 0) return;
      var val = i["value"];
      var v = "";
      if (field === "sdate" || field === "edate") {
        if (i["noExpired"]) {
          v = "3000/01/01";
        } else {
          v = moment(val, "YYYY/MM/DD").format("YYYY/MM/DD");
        }
      } else {
        v = val.toString();
      }
      if (field === "password" && !val) { // パスワードが空欄の場合はリクエストに含めない
        continue;
      } 
      request[field] = v;
    }
    setConfirm({ ...confirm, open: false });
    if (!isRegister)
      dispatch(updateAccountDetail({ id: id, data: request }))
        .then((res) => {
          var error = errorCover(res, "保存しました。");
          if (error !== null) {
            dispatch(
              setMessages([
                { severity: error.severity, message: error.message },
              ])
            );
            if (error.severity === "success")
              history.push("/pages/chart/accounts");
          }
        })
        .catch((error) => {
          dispatch(
            setMessages([
              { severity: "error", message: "保存に失敗しました。" },
            ])
          );
        });
    else {
      const fullname = new Cookies("auth").get("fullname", { path: "/" });
      request["author"] = fullname;
      dispatch(doRegistration(request))
        .then((res) => {
          var error = errorCover(res, "作成しました。");
          if (error !== null) {
            const { severity, message } = error;
            dispatch(setMessages([{ severity: severity, message: message }]));
            if (severity === "success") history.push("/pages/chart/accounts");
          }
        })
        .catch((error) => {
          dispatch(
            setMessages([
              { severity: "error", message: "作成に失敗しました。" },
            ])
          );
        });
    }
  };
  const handleCancel = () => {
    history.push("/pages/chart/accounts");
  };
  var arrays = [];
  for (let field in item) {
    if (field === "acStatus") {
      arrays.push(
        <EditRadioField
          key={field}
          field={field}
          arrays={statusList}
          isInline={true}
          item={item[field]}
        ></EditRadioField>
      );
    } else if (field === "ignoreSession") {
      arrays.push(
        <EditRadioField
          key={field}
          isInline={true}
          arrays={multiLogins}
          item={item[field]}
          field={field}
        ></EditRadioField>
      );
    } else if (field === "sdate" || field === "edate") {
      arrays.push(
        <EditDateField
          key={field}
          item={item[field]}
          label={"account-detail"}
          field={field}
          isInline={true}
          expiredChoice={field === "edate"}
        ></EditDateField>
      );
    } else if (field === "acType") {
      arrays.push(
        <EditSelectField
          key={field}
          field={field}
          label={"account-detail"}
          array={acTypeArr}
          item={item[field]}
        ></EditSelectField>
      );
    } else if (field === "groupCd") {
      arrays.push(
        <EditAutoComplete
          key={field}
          item={item[field]}
          field={field}
        ></EditAutoComplete>
      );
    } else {
      arrays.push(
        <EditTextField
          key={field}
          item={item[field]}
          label={"account-detail"}
          field={field}
        ></EditTextField>
      );
    }
  }
  var option = [];
  if (!isRegister)
    for (let field in options) {
      var o = options[field];
      var s = "";
      if (moment(o["value"], "YYYY/MM/DD").isValid()) {
        s = moment(o["value"], "YYYY/MM/DD").format("YYYY/MM/DD");
      } else {
        s = o["value"];
      }
      s = o["title"] + "：" + s;
      option.push(
        <p className={classes.pTab} key={field}>
          {s}
        </p>
      );
    }
  const openDialog = () => {
    var failed = false;
    if (!isRegister)
      for (let field in item) {
        var i = item[field];
        if (i["error"] !== 0) {
          failed = true;
          break;
        }
      }
    else
      for (let field in item) {
        let i = item[field];
        if (i["error"] !== 0 || i["mod"] === false) {
          failed = true;
          break;
        }
      }
    if (failed) {
      dispatch(checkErrorForAll());
      return;
    }
    setConfirm({ message: "更新してもよろしいですか？", open: true });
  };
  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <div className={"BackBtn"}>
          <Button className={classes.button}
            variant="outlined"
            color="primary"
            onClick={handleCancel}
            startIcon={<SubdirectoryArrowLeftIcon />}
          >
            一覧に戻る
          </Button>
        </div>
        <div className={classes.info}>{option}</div>
        <Paper className={classes.paper} variant="outlined">
          {arrays}
          <div className={classes.btnGroup}>
            <Button
              className={classes.textColor}
              variant="contained"
              onClick={handleCancel}
            >
              キャンセル
            </Button>
            <Button
              variant="contained"
              color={"primary"}
              onClick={openDialog}
            >
              {isRegister ? "登録する" : "保存する"}
            </Button>
          </div>
        </Paper>
      </Container>
      <ConfirmDialog
        msg={confirm.message}
        isOpen={confirm.open}
        doYes={handleYes}
        doNo={() => {
          setConfirm({ ...confirm, open: false });
        }}
      />
    </div>
  );
};
export default ChartAccountDetailPage;
