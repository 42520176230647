import {createAsyncThunk} from "@reduxjs/toolkit";
import {
    callGetClientListApi,
    callGetClientDetailApi,
    callGetMediaSelectList,
    callDeleteClient,
    callSaveClient,
    callGetFileListApi,
    callDeleteFiledata,
    callGetMediaListApi,
    callGetMediaDetailApi,
    callSaveMedia,
    callDeleteMedia,
    callChangeFileDownloadStatus
} from "../../api/listuiApi";
// import Cookies from "universal-cookie"

export const fetchClientList = createAsyncThunk(
    'listui/clientList',
    async (arg: { page?: number; limit?: number; search?: string }) => {
        const {page, limit, search} = arg;
        return await callGetClientListApi(page, limit, search);
    }
)

export const fetchClientDetail = createAsyncThunk(
    'listui/clientDetail',
    async (arg: { id: number }) => {
        const {id} = arg;
        return await callGetClientDetailApi(id);
    }
)

export const fetchMediaSelectList = createAsyncThunk(
    'listui/mediaSelectList',
    async (arg: { page?: number; limit?: number; search?: string }) => {
        const {page, limit, search} = arg;
        return await callGetMediaSelectList(page, limit, search);
    }
)

export const saveClient = createAsyncThunk(
    'listui/saveClient',
    async (arg: { client: Object }, {rejectWithValue}) => {
        const {client} = arg;
        let newClient = {...client}
        let resp = await callSaveClient(newClient);
        if (resp.status >= 400) {
            return rejectWithValue(resp)
        }
        return resp;
    }
)

export const deleteClient = createAsyncThunk(
    'listui/deleteClient',
    async (arg: { clientId: number }, {rejectWithValue}) => {
        const {clientId} = arg;
        let resp = await callDeleteClient(clientId);
        if (resp.status >= 400) {
            return rejectWithValue(resp)
        }
        return resp;
    }
)

export const fetchFileList = createAsyncThunk(
    'listui/deliveryFileList',
    async (arg: { page?: number; limit?: number; search?: string }) => {
        const {page, limit, search} = arg;
        return await callGetFileListApi(page, limit, search);
    }
)

export const deleteNouhinFile = createAsyncThunk(
    'listui/deleteNouhinFile',
    async (arg: { fileId: number }, {rejectWithValue}) => {
        const {fileId} = arg;
        let resp = await callDeleteFiledata(fileId);
        if (resp.status >= 400) {
            return rejectWithValue(resp)
        }
        return resp;
    }
)

export const fetchMediaList = createAsyncThunk(
    'listui/mediaList',
    async (arg: { page?: number; limit?: number; search?: string }) => {
        const {page, limit, search} = arg;
        return await callGetMediaListApi(page, limit, search);
    }
)

export const fetchMediaDetail = createAsyncThunk(
    'listui/mediaDetail',
    async (arg: { id: number }) => {
        const {id} = arg;
        return await callGetMediaDetailApi(id);
    }
)

export const saveMedia = createAsyncThunk(
    'listui/saveMedia',
    async (arg: { media: Object }, {rejectWithValue}) => {
        const {media} = arg;
        let newMedia = {...media}
        let resp = await callSaveMedia(newMedia);
        if (resp.status >= 400) {
            return rejectWithValue(resp)
        }
        return resp;
    }
)

export const deleteMedia = createAsyncThunk(
    'listui/deleteMedia',
    async (arg: { mediaId: number }, {rejectWithValue}) => {
        const {mediaId} = arg;
        let resp = await callDeleteMedia(mediaId);
        if (resp.status >= 400) {
            return rejectWithValue(resp)
        }
        return resp;
    }
)

export const changeFileDownloadStatus = createAsyncThunk(
    'listui/changeFileDownloadStatus',
    async (arg: {fileId?: number; downloadedFlag?: number}, {rejectWithValue}) => {
        const {fileId, downloadedFlag} = arg;
        let resp = await callChangeFileDownloadStatus(fileId, downloadedFlag);
        if (resp.status >= 400) {
            return rejectWithValue(resp)
        }
        return resp;
    }
)