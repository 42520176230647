import React from "react";
import { Route, Redirect } from "react-router-dom";
import Cookies from "universal-cookie";

export const LoginRoute = ({ children, ...rest }) => {
  const cookies = new Cookies("auth");
  const token = cookies.get("token", { path: "/" });
  return (
    <Route
      render={({ location }) => {
        return !token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/pages/listui/clients",
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default LoginRoute;
