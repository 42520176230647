import {createSlice} from "@reduxjs/toolkit";
import {fetchMediaList} from "./asyncThunk";

const initialState = {
    pageNum: 0,
    pageSize: 10,
    mediaList: [],
    selectedList: [],
    total: 0,
    search: "",
    isLoading: false,
    error: null
};

const slice = createSlice({
    name: "mediaList",
    initialState,
    reducers: {
        // changeSelectedList:{
        //     reducer(state, action) {
        //         state.selectedList = action.payload.selectedList
        //     },
        // },
        // changeSearch:{
        //     reducer(state, action) {
        //         state.search = action.payload.search
        //     },
        // }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchMediaList.fulfilled, (state, action) => {
            state.pageNum = action.meta.arg.page
            state.pageSize = action.meta.arg.limit
            state.search = action.meta.arg.search
            state.total = action.payload.data.count
            state.mediaList = action.payload.data.results
            state.selectedList = []
        });
        builder.addCase(fetchMediaList.rejected, (state, action) => {
            state.mediaList = []
            state.selectedList = []
            state.search = action.meta.arg.search
            state.pageNum = action.meta.arg.page
            state.pageSize = action.meta.arg.limit
        });
    },
});

export const listuiMediaList = slice.reducer;
// export const { changeSelectedList, changeSearch } = slice.actions;