import React from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  resetState,
  changeAccountData,
} from "../../stores/admin/accountDetailStore";
import { setMessages } from "../../stores/alertSnackbarStore";
import {
  saveAccount,
  getAccountDetail,
  updateAccount,
} from "../../stores/admin/asyncThunk";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import ConfirmDialog from "../../components/ConfirmDialog";
import { unwrapResult } from "@reduxjs/toolkit";
import { errorCover } from "../../components/chart/errorCover";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  content: {
    margin: theme.spacing(1),
    padding: theme.spacing(3),
  },
  back: {
    padding: theme.spacing(1),
  },
  action: {
    textAlign: "center",
    "& button": {
      margin: theme.spacing(2),
      minWidth: "100px",
    },
  },
  returnbutton:{
    border: "2px solid #3f51b5",
    color: "#3f51b5",
    backgroundColor: 'white',
  }
}));

const MODE = {
  REGISTER: 1,
  UPDATE: 2,
  DELETE: 3,
};

const AdminAccountDetailPage: React.FC = () => {
  const [confirmDialog, setConfirmDialog] = useState({
    message: "",
    open: false,
    mode: MODE.REGISTER,
  });
  const { id } = useParams();
  const location = useLocation();
  const { pathname } = location;
  const isRegister = pathname === "/pages/admin/accounts/new";
  const [open, setOpen] = React.useState(isRegister);
  const dispatch = useDispatch();
  const viewData = useSelector((state) => state.adminAccountDetail);
  const classes = useStyles();
  const history = useHistory();

  // Similar to componentDidMount and componentDidUpdate:
  React.useEffect(() => {
    // component will unmount
    if (!isRegister)
      dispatch(getAccountDetail(id))
        .then((res) => {
          var error = errorCover(res);
          if (error !== null)
            dispatch(
              setMessages([
                { severity: error.severity, message: error.message },
              ])
            );
        })
        .catch((error) => {
          dispatch(
            setMessages([
              { severity: "error", message: "データの取得に失敗しました。" },
            ])
          );
        });
    return () => dispatch(resetState());
  }, [dispatch, isRegister, id]);

  const handleChange = (label, event) => {
    let value = null;
    if (event.target.type === "checkbox") {
      value = event.target.checked;
    } else if (event.target.type === "radio") {
      value = event.target.value === "true" ? true : false;
    } else {
      value = event.target.value;
    }
    dispatch(changeAccountData({ key: label, value: value }));
  };

  const clickSave = (event) => {
    setConfirmDialog({
      message: "登録してもよろしいですか？",
      open: true,
      mode: MODE.REGISTER,
    });
  };

  const handleYes = () => {
    setConfirmDialog({ ...confirmDialog, open: false });
    if (isRegister)
      dispatch(saveAccount({ account: viewData.account }))
        .then(unwrapResult)
        .then((result) => {
          history.push(`/pages/admin/accounts`);
          dispatch(
            setMessages([{ severity: "success", message: "保存しました。" }])
          );
        })
        .catch((error) => {
          console.log(error);
          dispatch(
            setMessages([
              { severity: "error", message: "保存に失敗しました。" },
            ])
          );
        });
    else {
      var acc = Object.assign({}, viewData.account);
      if (!open) acc["password"] = "";
      dispatch(updateAccount(acc))
        .then(unwrapResult)
        .then((result) => {
          let error = errorCover({ payload: result });
          if (error !== null)
            dispatch(
              setMessages([{ severity: error.severity, message: result.data }])
            );
          else {
            history.push(`/pages/admin/accounts`);
            dispatch(
              setMessages([{ severity: "success", message: "保存しました。" }])
            );
          }
        })
        .catch((error) => {
          dispatch(
            setMessages([
              { severity: "error", message: "保存に失敗しました。" },
            ])
          );
        });
    }
  };

  const getData = (label) => {
    return viewData.account[label] ? viewData.account[label] : "";
  };

  const getBooleanData = (label) => {
    return viewData.account[label] ? true : false;
  };

  return (
    <div className={classes.root}>
      <div className={classes.back}>
        <Button className={classes.returnbutton}
          variant="contained"
          onClick={() => history.push(`/pages/admin/accounts`)}
        >
          <KeyboardReturnIcon />
          一覧に戻る
        </Button>
      </div>
      <Paper variant="outlined" className={classes.content}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormLabel component="legend">ログインID</FormLabel>
            <TextField
              disabled={!isRegister}
              variant="outlined"
              fullWidth
              value={getData("username")}
              onChange={(e) => {
                handleChange("username", e);
              }}
            />
          </Grid>
          {isRegister ? (
            ""
          ) : (
            <Grid item xs={12}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setOpen(!open)}
              >
                バスワードを変更
              </Button>
            </Grid>
          )}
          {!open ? (
            ""
          ) : (
            <Grid item xs={12}>
              <FormLabel component="legend">ユーザーパスワード</FormLabel>
              <TextField
                variant="outlined"
                fullWidth
                value={getData("password")}
                onChange={(e) => {
                  handleChange("password", e);
                }}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <FormLabel component="legend">メールアドレス</FormLabel>
            <TextField
              variant="outlined"
              fullWidth
              value={getData("email")}
              onChange={(e) => {
                handleChange("email", e);
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <FormLabel component="legend">姓</FormLabel>
            <TextField
              variant="outlined"
              fullWidth
              value={getData("last_name")}
              onChange={(e) => {
                handleChange("last_name", e);
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <FormLabel component="legend">名</FormLabel>
            <TextField
              variant="outlined"
              fullWidth
              value={getData("first_name")}
              onChange={(e) => {
                handleChange("first_name", e);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel component="legend">スーパーユーザー</FormLabel>
            <Switch
              checked={getBooleanData("is_superuser")}
              onChange={(e) => {
                handleChange("is_superuser", e);
              }}
              color="primary"
            />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.action}>
              <Button variant="contained" color="primary" onClick={clickSave}>
                保存
              </Button>
            </div>
          </Grid>
        </Grid>
        <ConfirmDialog
          msg={confirmDialog.message}
          isOpen={confirmDialog.open}
          doYes={handleYes}
          doNo={() => {
            setConfirmDialog({ ...confirmDialog, open: false });
          }}
        />
      </Paper>
    </div>
  );
};

export default AdminAccountDetailPage;
