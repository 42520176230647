import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { closeSnackBar } from "../stores/alertSnackbarStore";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const anchorOrigin = {vertical: "top", horizontal: "center"}

export default function AlertSnackbar() {
  const viewData = useSelector(state => state.alertSnackBar)
  const dispatch = useDispatch()

  const handleClose = (event, reason) => {
    dispatch(closeSnackBar());
  };

  return (
    <div>
      {viewData.messages.map((row, index) => (
        <Snackbar 
          key={index} 
          open={viewData.opend} 
          autoHideDuration={6000} 
          onClose={handleClose} 
          anchorOrigin={anchorOrigin}
        >
          <Alert onClose={handleClose} severity={row.severity}>
            {row.message}
          </Alert>
        </Snackbar>
      ))}
    </div>
  );
}
