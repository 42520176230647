import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAccountList,
  getAccountDetail,
  postAccount,
  putAccount,
  deleteAccount,
  getMasterList,
  getMasterDetail,
  postMaster,
  putMaster,
  deleteMaster,
  getLog,
  getAllMedia,
} from "../../api/listlightApi";
import { Account, Master, FetchAccountParams } from "../../types/listlight";

export const fetchAccounts = createAsyncThunk(
  "listlight/fetchAccounts",
  async (arg: FetchAccountParams, { rejectWithValue }) => {
    try {
      return await getAccountList(arg);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAccount = createAsyncThunk(
  "listlight/getAccount",
  async (arg: { id: number }, { rejectWithValue }) => {
    try {
      return await getAccountDetail(arg.id);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const saveAccount = createAsyncThunk(
  "listlight/saveAccount",
  async (arg: { account: Account }, { rejectWithValue }) => {
    try {
      if (arg.account.id) {
        return await putAccount(arg.account);
      } else {
        return await postAccount(arg.account);
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const delAccount = createAsyncThunk(
  "listlight/delAccount",
  async (arg: { account: Account }, { rejectWithValue }) => {
    try {
      return await deleteAccount(arg.account.id);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchMasters = createAsyncThunk(
  "listlight/fetchMasters",
  async (
    arg: { page?: number, limit?: number, search?: string },
    { rejectWithValue }
  ) => {
    try {
      const { page, limit, search } = arg;
      return await getMasterList(page, limit, search);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getMaster = createAsyncThunk(
  "listlight/getMaster",
  async (arg: { id: number }, { rejectWithValue }) => {
    try {
      return await getMasterDetail(arg.id);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const saveMaster = createAsyncThunk(
  "listlight/saveMaster",
  async (arg: { master: Master }, { rejectWithValue }) => {
    try {
      if (arg.master.id) {
        return await putMaster(arg.master);
      } else {
        return await postMaster(arg.master);
      }
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const delMaster = createAsyncThunk(
  "listlight/delMaster",
  async (arg: { master: Master }, { rejectWithValue }) => {
    try {
      return await deleteMaster(arg.master.id);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const downloadLog = createAsyncThunk(
  "listlight/logs/download",
  async (data, { rejectWithValue }) => {
    const resp = await getLog(data);
    if (resp.status < 200 || resp.status >= 300) {
      return rejectWithValue(resp);
    }
    return resp;
  }
);

// export const fetchAllMedia = createAsyncThunk(
//   "chart/fetchAllMedia",
//   async (data, { rejectWithValue }) => {
//     const resp = await getAllMedia();
//     if (resp.status < 200 || resp.status >= 300) {
//       return rejectWithValue(resp);
//     }
//     return resp;
//   }
// );

export const fetchAllMediaForDetail = createAsyncThunk(
  "listlight/masters/fetchAllMedia",
  async (data, { rejectWithValue }) => {
    const resp = await getAllMedia();
    if (resp.status < 200 || resp.status >= 300) {
      return rejectWithValue(resp);
    }
    return resp;
  }
);