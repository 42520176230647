import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import getErrorText from "../../../errors/errors";
import { useDispatch } from "react-redux";
import { changeDetail } from "../../../stores/chart/chartStore";

const useStyles = makeStyles((theme) => ({
  root: {},
  control: {
    width: "50%",
  },
  title: {
    marginBottom: 5,
  },
}));

const EditSelectField = (props) => {
  const { field, item, label, array } = props;
  const { title, error, value } = item;
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleValue = (field) => (event) => {
    const itemp = {
      field: field,
      value: event.target.value,
    };
    dispatch(changeDetail(itemp));
  };
  const menu = array.map((v, i) => {
    return (
      <MenuItem value={v["value"]} key={i}>
        {v["label"]}
      </MenuItem>
    );
  });
  return (
    <div className={classes.root}>
      <InputLabel htmlFor={label + "-" + field} className={classes.title}>
        {title}
      </InputLabel>
      <FormControl
        variant="outlined"
        error={error !== 0}
        className={classes.control}
      >
        <Select
          value={value}
          id={label + "-" + field}
          displayEmpty
          onChange={handleValue(field)}
        >
          {menu}
        </Select>
        <FormHelperText>&nbsp;{getErrorText(title, error)}</FormHelperText>
      </FormControl>
    </div>
  );
};

export default EditSelectField;
