import React from 'react'
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { resetState, changeMasterData, removeMedia, removePrefecture } from "../../../stores/listlight/masterDetailStore";
import { setMessages } from "../../../stores/alertSnackbarStore";
import { getMaster, saveMaster, delMaster } from "../../../stores/listlight/asyncThunk";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles } from '@material-ui/core/styles';
import ConfirmDialog from '../../../components/ConfirmDialog';
import Chip from '@material-ui/core/Chip';
import MediaSelectDialog from '../../../components/listlight/MediaSelectDialog';
import PrefectureSelectDialog from '../../../components/listlight/PrefectureSelectDialog';
import { unwrapResult } from '@reduxjs/toolkit';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

const useStyles = makeStyles((theme) => ({
  content: {
    maxWidth: '1000px',
    margin: theme.spacing(1),
    padding: theme.spacing(3),
  },
  back: {
    padding: theme.spacing(1),
  },
  action: {
    textAlign: 'center',
    '& button': {
      margin: theme.spacing(2),
      minWidth: '100px',
    }
  },
  chipContainer: {
    minHeight: '50px',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  weekLabel: {
    verticalAlign: 'bottom'
  },
  error: {
    color: 'red'
  },
  button:  {
    backgroundColor: 'white',
    border: "2px solid #3f51b5",
    color: "#3f51b5",
  }

}));

const MODE = {
  REGISTER: 1 ,
  UPDATE: 2,
  DELETE: 3
}

const AccountDetailPage: React.FC = () => {
  const [openMedia, setOpenMedia] = useState(false);
  const [openPrefecture, setOpenPrefecture] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({message: "", open: false, mode: MODE.REGISTER});
  const { id } = useParams();
  const dispatch = useDispatch()
  const viewData = useSelector(state => state.listlightMasterDetail)
  const classes = useStyles();
  const history = useHistory()
  const isRegister = id === 'new';

  // Similar to componentDidMount and componentDidUpdate:
  React.useEffect(() => {
    if (id !== "new") {
      dispatch(getMaster({id: id}))
      .then(unwrapResult)
      .catch(error => {
        history.push(`/pages/listlight/masters`)
        dispatch(setMessages([{severity: "error", message: "データの取得に失敗しました。"}]));
      });
    }

    // component will unmount
    return () => dispatch(resetState());
  }, [dispatch, history, id]);

  const handleChange = (prop) => (event) => {
    dispatch(changeMasterData({key: prop, value: event.target.value}));
  };

  const handleDelMedia = (media, e) => {
    dispatch(removeMedia(media));
  };

  const handleDelPrefecture = (prefecture, e) => {
    dispatch(removePrefecture(prefecture));
  };

  const handleMediaClose = (values) => {
    setOpenMedia(false);
    if (values === null) {
      return;
    }
    dispatch(changeMasterData({key: 'media_names', value: values.join()}));
  };

  const handlePrefectureClose = (values) => {
    setOpenPrefecture(false);
    if (values === null) {
      return;
    }
    dispatch(changeMasterData({key: 'prefectures', value: values.join()}));
  };
  
  const clickSave = (event) => {
    if (viewData.master.id) {
      setConfirmDialog({message: "更新してもよろしいですか？", open: true, mode: MODE.UPDATE})
    } else {
      setConfirmDialog({message: "登録してもよろしいですか？", open: true, mode: MODE.REGISTER})
    }
  };

  const clickDelete = (event) => {
    setConfirmDialog({message: "削除してもよろしいですか？", open: true, mode: MODE.DELETE})
  };

  const handleYes = () => {
    setConfirmDialog({...confirmDialog, open: false})
    if (confirmDialog.mode === MODE.DELETE) {
      dispatch(delMaster({master: viewData.master}))
        .then(unwrapResult)
        .then(result => {
          history.push(`/pages/listlight/masters`)
          dispatch(setMessages([{severity: "success", message: "削除しました。"}]));
        })
        .catch(error => {
          console.log(error);
          dispatch(setMessages([{severity: "error", message: "削除に失敗しました。"}]));
        });
    } else {
      dispatch(saveMaster({master: viewData.master}))
      .then(unwrapResult)
      .then(result => {
        if (id === "new") {
          history.push(`/pages/listlight/masters/${result.data.id}`)
        }
        dispatch(setMessages([{severity: "success", message: "保存しました。"}]));
      })
      .catch(error => {
        console.log(error);
        dispatch(setMessages([{severity: "error", message: "保存に失敗しました。"}]));
      });
    }
  };

  const getArrayData = (data) => {
    if (!data) {
      return [];
    }
    return data.split(',');
  }

  return (
    <div>
      <div className={classes.back}>
        <Button className={classes.button} variant="contained" onClick={() => history.push(`/pages/listlight/masters`)}>
          <KeyboardReturnIcon />
          一覧に戻る
        </Button>
      </div>
      <Paper variant="outlined" className={classes.content}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormLabel component="legend">マスタ名</FormLabel>
            <TextField
              variant="outlined"
              fullWidth
              value={viewData.master.name}
              onChange={handleChange('name')}
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel component="legend">媒体リスト</FormLabel>
            <Paper variant="outlined" className={classes.chipContainer}>
              {getArrayData(viewData.master.media_names).map((media) => (
                <Chip key={media} label={media} onDelete={(e) => {handleDelMedia(media, e)}}/>
              ))}
            </Paper>
            <MediaSelectDialog onClose={handleMediaClose} open={openMedia} selectedMedias={getArrayData(viewData.master.media_names)}/>
            <div>
              <Button color="primary" variant="contained" onClick={() => { setOpenMedia(true) }}>媒体選択</Button>
            </div>
          </Grid>
          <Grid item xs={12}>
            <FormLabel component="legend">都道府県</FormLabel>
            <Paper variant="outlined" className={classes.chipContainer}>
              {getArrayData(viewData.master.prefectures).map((prefecture) => (
                <Chip key={prefecture} label={prefecture} onDelete={(e) => {handleDelPrefecture(prefecture, e)}}/>
              ))}
            </Paper>
            <PrefectureSelectDialog onClose={handlePrefectureClose} open={openPrefecture} selectedPrefectures={getArrayData(viewData.master.prefectures)}/>
            <div>
              <Button color="primary" variant="contained" onClick={() => { setOpenPrefecture(true) }}>都道府県選択</Button>
            </div>
          </Grid>
          <Grid item xs={6}>
            <FormLabel component="legend">利用できる期間</FormLabel>
            <TextField
              type="number"
              variant="outlined"
              value={viewData.master.week_count}
              onChange={handleChange('week_count')}
            />
            <span className={classes.weekLabel}>週間</span>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.action}>
              <Button variant="contained" color="primary" onClick={clickSave}>
              {isRegister ? "登録する": "保存する"}
              </Button>
              {viewData.master.id &&
                <Button variant="contained" color="secondary" onClick={clickDelete}>削除</Button>
              }
            </div>
          </Grid>
        </Grid>
        <ConfirmDialog
          msg={confirmDialog.message}
          isOpen={confirmDialog.open}
          doYes={handleYes}
          doNo={() => {setConfirmDialog({...confirmDialog, open: false})}}
        />
        <ul>
          {Object.entries(viewData.error).map(([key, value]) => (
            <li key={key}>
              {key} : {value}
            </li>
          ))}
        </ul>
      </Paper>
    </div>
  )
}
 
export default AccountDetailPage
