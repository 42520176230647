export const errorCover = (res, message, type = 0) => {
  const { status } = res.payload;
  const string = type === 0 ? "success" : "info";
  if (status < 200 || status >= 300) {
    const { data } = res.payload;
    const { detail } = data;
    var mess = "";
    if (!data) {
      mess = "Error";
    } else {
      if (!detail) {
        mess = data;
      } else {
        mess = detail;
      }
    }
    return { severity: "error", message: mess };
  } else {
    if (message !== undefined && message !== null && message !== "") {
      return { severity: string, message: message };
    } else return null;
  }
};
