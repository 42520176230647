import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { PREFECTURE_LIST } from '../../helper/listlight/listlightHelper'

const PrefectureSelectDialog: React.FC = (props) => {
  const { onClose, open, selectedPrefectures } = props;
  const [checked, setChecked] = React.useState([]);

  // Similar to componentDidMount and componentDidUpdate:
  React.useEffect(() => {
    setChecked(selectedPrefectures);
  }, [selectedPrefectures]);

  const handleClose = () => {
    onClose(null);
  };

  const handleCommit = () => {
    onClose(checked);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} scroll='paper'>
      <DialogTitle id="simple-dialog-title">都道府県リスト</DialogTitle>
      <DialogContent>
        <List>
          {PREFECTURE_LIST.map((value) => {
            const labelId = `checkbox-list-label-${value}`;

            return (
              <ListItem button key={value} onClick={handleToggle(value)}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value} />
              </ListItem>
            )
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          キャンセル
        </Button>
        <Button onClick={handleCommit} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PrefectureSelectDialog
