import moment from 'moment';

export const ACCOUNT_TYPE = {
  goalist: 'ゴーリスト',
  client: 'クライアント',
  free: 'フリー'
}

const NOTIFY_UPDATE_DATA = {
  true: '利用',
  false: '未利用'
}

const STRICT_LOGIN = {
  true: '不可',
  false: '可'
}

const INVALID = {
  true: '無効',
  false: ''
}


// export const MEDIA_LIST = [
//   'DODA',
//   'Daijob.com',
//   'Find Job',
//   'GIRLSWOMAN',
//   'Green',
//   'HOTERES',
//   'PROSEEK',
//   'Re就活',
//   'WEBAgre',
//   'Wantedly',
//   'an',
//   'paiza',
//   'type',
//   'とらばーゆ',
//   'はたらいく',
//   'はたらこねっと',
//   'イーアイデム',
//   'イーアイデム正社員',
//   'イーキャリア',
//   'エンミドルの転職',
//   'エン転職',
//   'キャリアクロス',
//   'クリエイトバイト',
//   'クリエイト転職',
//   'クリーデンス',
//   'ジェイウォーム',
//   'ジョブメドレー',
//   'タウンワーク',
//   'タウンワーク社員',
//   'ディースターNET',
//   'ドラEVER',
//   'ハローワーク',
//   'バイトル',
//   'バイトルNEXT',
//   'フロムエー',
//   'フロム・エー 社員',
//   'ブライダルキャリア',
//   'ホテル求人ドットコム',
//   'マイナビバイト',
//   'マイナビミドルシニア',
//   'マイナビ介護職',
//   'マイナビ転職',
//   'マイナビ転職エージェントサーチ',
//   'マッハバイト',
//   'リクナビNEXT',
//   'リクルートエージェント',
//   'ルーキーweb',
//   'ワークポート',
//   '女の転職type',
//   '工場ワークス',
//   '施工管理転職ナビ',
//   '日経キャリアNET',
//   '求人アスコム',
//   '求人ジャーナルネット',
//   '求人＠飲食店.COM',
//   '転職ナビ',
//   'ジョブキタ',
// ]

export const PREFECTURE_LIST = [
  '01:北海道',
  '02:青森県',
  '03:岩手県',
  '04:宮城県',
  '05:秋田県',
  '06:山形県',
  '07:福島県',
  '08:茨城県',
  '09:栃木県',
  '10:群馬県',
  '11:埼玉県',
  '12:千葉県',
  '13:東京都',
  '14:神奈川県',
  '15:新潟県',
  '16:富山県',
  '17:石川県',
  '18:福井県',
  '19:山梨県',
  '20:長野県',
  '21:岐阜県',
  '22:静岡県',
  '23:愛知県',
  '24:三重県',
  '25:滋賀県',
  '26:京都府',
  '27:大阪府',
  '28:兵庫県',
  '29:奈良県',
  '30:和歌山県',
  '31:鳥取県',
  '32:島根県',
  '33:岡山県',
  '34:広島県',
  '35:山口県',
  '36:徳島県',
  '37:香川県',
  '38:愛媛県',
  '39:高知県',
  '40:福岡県',
  '41:佐賀県',
  '42:長崎県',
  '43:熊本県',
  '44:大分県',
  '45:宮崎県',
  '46:鹿児島県',
  '47:沖縄県',
]

export const getAccountTypeName = (key) => {
  return ACCOUNT_TYPE[key.account_type]
}

export const getNotifyFlagName = (key) => {
  return NOTIFY_UPDATE_DATA[Boolean(key.notify_update_data).toString()]
}

export const getisStrictLoginName = (key) => {
  return STRICT_LOGIN[Boolean(key.is_strict_login).toString()]
}

export const getInvalidName = (key) => {
  return INVALID[Boolean(key.is_invalid).toString()]
}

export const getMasterName = (key) => {
  return key.master.name
}

export const convertDateTime = (value) => {
  return moment(value).format("YYYY/MM/DD HH:mm");
}
