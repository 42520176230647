import axios from "axios"

const api_prefix = `${process.env.REACT_APP_API_URL}/api`

export const callLoginApi = async (loginInfo) => {

    const resp = await axios.post(`${api_prefix}/login/`, loginInfo)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return resp;
}
