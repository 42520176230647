import { createSlice } from "@reduxjs/toolkit";
import { getAccountList, getMediaList } from "./asyncThunk";

const initialState = {
  data: [],
  viewData: [],
  total: 0,
  isNotice: false,
  noticeType: 0,
  message: "",
  searched: false,
  msearched: false,
  pageNumber: 0,
  pageSize: 10,
  on: false,
  acTypeArr: [
    { label: "free", value: 0 },
    { label: "paid", value: 1 },
    { label: "demo", value: 9 },
  ],
};

const slice = createSlice({
  name: "chart-account-list",
  initialState,
  reducers: {
    openAL: (state, action) => {
      state.on = true;
      return state;
    },
    clearNotice: (state, action) => {
      state.isNotice = false;
      state.message = "";
      state.noticeType = 0;
      return state;
    },
    closeAL: (state, action) => {
      state = initialState;
      return state;
    },
    changePageNumber: (state, action) => {
      state.pageNumber = action.payload;
      state.viewData = state.data.filter(
        (v, i) =>
          i >= state.pageNumber * state.pageSize &&
          i < (state.pageNumber + 1) * state.pageSize
      );
      return state;
    },
    changePageSize: (state, action) => {
      state.pageSize = action.payload;
      state.viewData = state.data.filter(
        (v, i) =>
          i >= state.pageNumber * state.pageSize &&
          i < (state.pageNumber + 1) * state.pageSize
      );
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAccountList.fulfilled, (state, action) => {
      const { total, items } = action.payload.data;
      state.data = items;
      state.pageNumber = 0;
      state.viewData = state.data.filter(
        (v, i) =>
          i >= state.pageNumber * state.pageSize &&
          i < (state.pageNumber + 1) * state.pageSize
      );
      state.total = total;
      if (!state.searched) {
        // state.isNotice = true;
        // state.noticeType = 2;
        // state.message = "チャートアカウントの一覧をローディングできました。!";
        state.searched = true;
      }
      return state;
    });
    builder.addCase(getAccountList.rejected, (state, action) => {
      state.isNotice = true;
      state.noticeType = 1;
      if (!action.payload.data) {
        state.message = "Server Error.";
      } else if (action.payload.data["code"]) {
        state.message = action.payload.data["detail"];
      } else {
        state.message = action.payload.data;
      }
      return state;
    });
    builder.addCase(getMediaList.fulfilled, (state, action) => {
      const { total, items } = action.payload.data;
      state.data = items;
      state.pageNumber = 0;
      state.viewData = state.data.filter(
        (v, i) =>
          i >= state.pageNumber * state.pageSize &&
          i < (state.pageNumber + 1) * state.pageSize
      );
      state.total = total;
      if (!state.msearched) {
        // state.isNotice = true;
        // state.noticeType = 2;
        // state.message = "チャート媒体の一覧をローディングできました。!";
        state.msearched = true;
      }

      return state;
    });
    builder.addCase(getMediaList.rejected, (state, action) => {
      state.isNotice = true;
      state.noticeType = 1;
      if (action.payload.data === undefined) {
        state.message = "Server Error.";
      } else {
        state.message = action.payload.data;
      }
      if (action.payload.data["code"]) {
        state.message = action.payload.data["detail"];
      }
      return state;
    });
  },
});

export const {
  openAL,
  clearNotice,
  closeAL,
  changePageNumber,
  changePageSize,
} = slice.actions;
export const chartAccountList = slice.reducer;
