import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme, makeStyles, withStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import {
  changeMasterDetail,
  colSelect,
  showItemDialog,
  selectAllItem,
} from "../../stores/chart/chartStore";
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "&.Mui-checked": {
      color: "rgb(44,67,137)",
    },
  },
  formGroup: {
    flexDirection: "row",
  },
  labelAll: {
    width: "100%",
  },
  title: {
    paddingBottom: 0,
  },
  titleText: {
    color: "rgb(44,67,137)",
    fontWeight: "bolder",
  },
}));
const ColDialog = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const state = useSelector((state) => state.chartDetail);
  const { openDialog, objList, selectObj, allObj } = state;
  var field = "";
  var check = false;
  for (let f in openDialog) {
    check = openDialog[f];
    if (openDialog[f]) {
      field = f;
      break;
    }
  }
  const items = objList[field];
  const selectedItem = selectObj[field];
  const all = allObj[field];
  React.useEffect(() => {
    if (selectedItem && items) {
      if (selectedItem.length === items.length) {
        dispatch(selectAllItem({ field: field, value: true }));
      } else {
        dispatch(selectAllItem({ field: field, value: false }));
      }
    }
    return () => {};
  }, [dispatch, selectedItem, items, field]);
  if (!check) {
    return <div></div>;
  }

  const handleClose = () => {
    dispatch(showItemDialog({ type: false, field: field, isConfirm: false }));
  };
  const handleChange = (v) => (event) => {
    if (all) {
      dispatch(selectAllItem({ field: field, value: false }));
    }
    let arrays = [];
    if (selectedItem.includes(v)) {
      arrays = selectedItem.filter((va) => va !== v);
    } else {
      arrays = selectedItem.concat([v]);
    }
    dispatch(colSelect({ array: arrays, field: field }));
  };
  const handleAll = () => {
    dispatch(selectAllItem({ field: field, value: !all }));
    if (!all) {
      dispatch(colSelect({ array: items, field: field }));
    } else {
      dispatch(colSelect({ array: [], field: field }));
    }
  };
  const handleConfirm = () => {
    var string = "";
    selectedItem.forEach((s) => {
      string += s + ",";
    });

    const itemp = {
      field: field,
      value: string.slice(0, string.length - 1),
    };
    dispatch(changeMasterDetail(itemp));
    dispatch(showItemDialog({ type: false, field: field, isConfirm: true }));
  };
  var list = [];
  list.push(
    <FormControlLabel
      key={0}
      className={classes.labelAll}
      control={<HRogCheckbox checked={all} onChange={handleAll} name="all" />}
      label={field === "mediaList" ? "全媒体を選択する" : "全項目を選択する"}
    />
  );
  items.forEach((value, index) => {
    list.push(
      <FormControlLabel
        key={index + 1}
        control={
          <HRogCheckbox
            checked={selectedItem.includes(value)}
            onChange={handleChange(value)}
            name={value}
          />
        }
        label={value}
      />
    );
  });

  return (
    <div className={classes.root}>
      {list.length <= 0 ? (
        "Loading..."
      ) : (
        <Dialog
          fullScreen={fullScreen}
          open={openDialog[field]}
          onClose={handleClose}
          aria-labelledby="media-dialog-title"
        >
          <DialogTitle id="media-dialog-title" className={classes.title}>
            <div className={classes.titleText}>
              {field === "mediaList" ? "納品媒体" : "集計項目"}
            </div>
          </DialogTitle>
          <DialogContent>
            <FormControl required component="fieldset">
              <FormGroup className={classes.formGroup}>{list}</FormGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="primary">
              キャンセル
            </Button>
            <Button
              onClick={handleConfirm}
              color="primary"
              autoFocus
              style={{ fontWeight: "bolder" }}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

const HRogCheckbox = withStyles({
  root: {
    color: "rgb(44,67,137)",
    "&$checked": {
      color: "rgb(44,67,137)",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default ColDialog;
