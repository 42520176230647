import { createSlice } from "@reduxjs/toolkit";
import { getDefaultLogStartAt, getDefaultLogEndAt } from "../../helper/map/mapHelper";

const defaultStart = getDefaultLogStartAt();
const defaultEnd = getDefaultLogEndAt();
// Stateの初期状態
const initialState = {
  search: "",
  startAt: defaultStart,
  endAt: defaultEnd,
};

// Sliceを生成する
const slice = createSlice({
  name: "mapLogDownload",
  initialState,
  reducers: {
    resetState (state, action) {
      state.search = ""
      state.startAt = defaultStart
      state.endAt = defaultEnd
    },
    changeViewData (state, action) {
      state[action.payload.key] = action.payload.value;
    },
  },
  extraReducers: (builder) => {},
});

// Reducerをエクスポートする
export const mapLogDownload = slice.reducer;

export const { resetState, changeViewData } = slice.actions;
