import {createAsyncThunk} from "@reduxjs/toolkit";
import {callLoginApi} from "../../api/loginApi";

export const sendLoginInfo = createAsyncThunk(
    'auth/login',
    async (arg: { loginInfo: Object},{ rejectWithValue }) => {
        const resp = await callLoginApi(arg);
        if (resp.status > 400){
            return rejectWithValue(resp)
        }
        return resp;
    }
)