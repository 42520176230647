import React from 'react'
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { resetState, changeAccountData } from "../../../stores/map/accountDetailStore";
import { setMessages } from "../../../stores/alertSnackbarStore";
import { getAccount, saveAccount, delAccount } from "../../../stores/map/asyncThunk";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { unwrapResult } from '@reduxjs/toolkit';

const useStyles = makeStyles((theme) => ({
  content: {
    margin: theme.spacing(1),
    padding: theme.spacing(3),
  },
  back: {
    padding: theme.spacing(1),

  },
  action: {
    textAlign: 'center',
    '& button': {
      margin: theme.spacing(2),
      minWidth: '100px',
    }
  },
  returnbutton:{
    border: "2px solid #3f51b5",
    color: "#3f51b5",
    backgroundColor: 'white',
  }
}));

const MODE = {
  REGISTER: 1 ,
  UPDATE: 2,
  DELETE: 3
}

const MapAccountDetailPage: React.FC = () => {
  const [confirmDialog, setConfirmDialog] = useState({message: "", open: false, mode: MODE.REGISTER});
  const { id } = useParams();
  const dispatch = useDispatch()
  const viewData = useSelector(state => state.mapAccountDetail)
  const classes = useStyles();
  const history = useHistory()
  const isRegister = id === 'new';

  // Similar to componentDidMount and componentDidUpdate:
  React.useEffect(() => {
    if (id !== "new") {
      dispatch(getAccount({id: id}))
      .then(unwrapResult)
      .catch(error => {
        history.push(`/pages/map/accounts`)
        dispatch(setMessages([{severity: "error", message: "データの取得に失敗しました。"}]));
      });
    }

    // component will unmount
    return () => dispatch(resetState());
  }, [dispatch, history, id]);

  const handleChange = (label, event) => {
    let value = null;
    if (event.target.type === 'checkbox') {
      value = event.target.checked
    } else if (event.target.type === 'radio') {
      value = event.target.value === 'true' ? true : false
    } else {
      value = event.target.value
    }
    dispatch(changeAccountData({key: label, value: value}));
  }

  const clickSave = (event) => {
    if (viewData.account.id) {
      setConfirmDialog({message: "更新してもよろしいですか？", open: true, mode: MODE.UPDATE})
    } else {
      setConfirmDialog({message: "登録してもよろしいですか？", open: true, mode: MODE.REGISTER})
    }
  }

  const clickDelete = (event) => {
    setConfirmDialog({message: "削除してもよろしいですか？", open: true, mode: MODE.DELETE})
  }

  const handleYes = () => {
    setConfirmDialog({...confirmDialog, open: false})
    if (confirmDialog.mode === MODE.DELETE) {
      dispatch(delAccount({account: viewData.account}))
        .then(unwrapResult)
        .then(result => {
          history.push(`/pages/map/accounts`)
          dispatch(setMessages([{severity: "success", message: "削除しました。"}]));
        })
        .catch(error => {
          console.log(error);
          dispatch(setMessages([{severity: "error", message: "削除に失敗しました。"}]));
        });
    } else {
      dispatch(saveAccount({account: viewData.account}))
      .then(unwrapResult)
      .then(result => {
        if (id === "new") {
          history.push(`/pages/map/accounts/${result.data.id}`)
        }
        dispatch(setMessages([{severity: "success", message: "保存しました。"}]));
      })
      .catch(error => {
        console.log(error);
        dispatch(setMessages([{severity: "error", message: "保存に失敗しました。"}]));
      });
    }
  }

  const getData = (label) => {
    return viewData.account[label] ? viewData.account[label] : "";
  }
  
  const getBooleanDataAsString = (label) => {
    return viewData.account[label] ? "true" : "false";
  }

  return (
    <div>
      <div className={classes.back}>
        <Button className={classes.returnbutton} variant="contained" onClick={() => history.push(`/pages/map/accounts`)}>
          <KeyboardReturnIcon />
            一覧に戻る
        </Button>
      </div>
      <Paper variant="outlined" className={classes.content}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormLabel component="legend">ログインID</FormLabel>
            <TextField
              variant="outlined"
              fullWidth
              value={getData("login_id")}
              onChange={(e) => {handleChange("login_id", e)}}
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel component="legend">ユーザーパスワード</FormLabel>
            <TextField
              variant="outlined"
              fullWidth
              value={getData("password")}
              onChange={(e) => {handleChange("password", e)}}
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel component="legend">ユーザー名</FormLabel>
            <TextField
              variant="outlined"
              fullWidth
              value={getData("name")}
              onChange={(e) => {handleChange("name", e)}}
            />
          </Grid>
          <Grid item xs={7}>
            <FormLabel component="legend">グループコード</FormLabel>
            <Select
              variant="outlined"
              fullWidth
              value={getData("group_code")}
              onChange={(e) => {handleChange("group_code", e)}}>
              {['amazon', 'dev', 'demo', 'free', 'free_candownload'].map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <FormLabel component="legend">利用開始日</FormLabel>
                <TextField
                  type="datetime-local"
                  variant="outlined"
                  value={getData("start_at")}
                  onChange={(e) => {handleChange("start_at", e)}}
                />
              </Grid>
              <Grid item xs={3}>
                <FormLabel component="legend">利用期限</FormLabel>
                <TextField
                  type="datetime-local"
                  variant="outlined"
                  value={getData("expire_at")}
                  onChange={(e) => {handleChange("expire_at", e)}}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <FormLabel component="legend">複数デバイスでの同時ログイン</FormLabel>
                <RadioGroup row value={getBooleanDataAsString("is_strict_login")} onChange={(e) => {handleChange("is_strict_login", e)}}>
                  <FormControlLabel value="false" control={<Radio color="primary"/>} label="可" />
                  <FormControlLabel value="true" control={<Radio color="primary"/>} label="不可" />
                </RadioGroup>
              </Grid>
              <Grid item xs={3}>
                <FormLabel component="legend">無効フラグ</FormLabel>
                <RadioGroup row value={getBooleanDataAsString("is_invalid")} onChange={(e) => {handleChange("is_invalid", e)}}>
                  <FormControlLabel value="false" control={<Radio color="primary"/>} label="有効" />
                  <FormControlLabel value="true" control={<Radio color="primary"/>} label="無効" />
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.action}>
              <Button variant="contained" color="primary" onClick={clickSave}>
                {isRegister ? "登録する": "保存する"}
              </Button>
              {viewData.account.id &&
                <Button variant="contained" color="secondary" onClick={clickDelete}>削除</Button>
              }
            </div>
          </Grid>
        </Grid>
        <ConfirmDialog
          msg={confirmDialog.message}
          isOpen={confirmDialog.open}
          doYes={handleYes}
          doNo={() => {setConfirmDialog({...confirmDialog, open: false})}}
        />
      </Paper>
    </div>
  )
}
 
export default MapAccountDetailPage
