import { createStore, applyMiddleware, combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { createBrowserHistory } from "history";
import { connectRouter, routerMiddleware } from "connected-react-router";
// import rootReducer from "./reducers";
import { accountList } from "./listlight/accountListStore";
import { accountDetail } from "./listlight/accountDetailStore";
import { masterDetail } from "./listlight/masterDetailStore";
import { masterList } from "./listlight/masterListStore";
import { mapAccountList } from "./map/accountListStore";
import { mapAccountDetail } from "./map/accountDetailStore";
import { mapLogDownload } from "./map/logDownloadStore";
import { login } from "./auth/loginStore";
import { clientList } from "./listui/clientListStore";
import { header } from "./header/headerStore";
import { registerChart } from "./chart/registerStore";
import { chartAccountList } from "./chart/chartALStore";
import { chartDetail } from "./chart/chartStore";
import { clientRegister } from "./listui/clientRegisterStore";
import { mediaSelectList } from "./listui/mediaSelectListStore";
import { alertSnackBar } from "./alertSnackbarStore";
import { menu } from "./menu/menuStore";
import { adminAccountDetail } from "./admin/accountDetailStore";
import { adminAccountList } from "./admin/accountListStore";
import { listuiFileList } from "./listui/fileListStore";
import { chartLog } from "./chart/logStore";
import { hotLog } from "./listlight/hotLogStore";
import { listuiMediaList } from "./listui/mediaListStore";
import {listuiMediaRegister} from "./listui/mediaRegisterStore";
import {listLightMediaSelectList} from "./listlight/listlightMediaSelectListStore";

// const loggerMiddleware = createLogger();

export const history = createBrowserHistory();

// const customMiddleWare = (store) => (next) => (action) => {
//   next(action);
// };

// Reducers
const reducer = combineReducers({
  router: connectRouter(history),
  listlightAccountList: accountList,
  listlightAccountDetail: accountDetail,
  listlightMasterList: masterList,
  listlightMasterDetail: masterDetail,
  mapAccountList: mapAccountList,
  mapAccountDetail: mapAccountDetail,
  mapLogDownload: mapLogDownload,
  login: login,
  header: header,
  lisuiClientList: clientList,
  registerChart: registerChart,
  chartAccountList: chartAccountList,
  lisuiClientRegister: clientRegister,
  listuiMediaSelectList: mediaSelectList,
  chartDetail: chartDetail,
  alertSnackBar: alertSnackBar,
  menu: menu,
  adminAccountList: adminAccountList,
  adminAccountDetail: adminAccountDetail,
  chartLog: chartLog,
  hotLog: hotLog,
  listuiFileList: listuiFileList,
  listuiMediaList: listuiMediaList,
  lisuiMediaRegister: listuiMediaRegister,

  listLightMediaSelectList: listLightMediaSelectList,
});

const middlewareList = [thunkMiddleware, routerMiddleware(history)];
if (process.env.NODE_ENV === "development") {
  middlewareList.push(createLogger());
}

export default function configureStore(preloadedState) {
  return createStore(
    reducer,
    preloadedState,
    applyMiddleware(...middlewareList)
  );
}
