import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {deleteClient, fetchClientDetail, saveClient} from "../../../stores/listui/asyncThunk";
import TextField from "@material-ui/core/TextField";
import SelectMediaDialog from "../../../components/listui/SelectMediaDialog";
import {
    changeClientData,
    changeErrorState,
    resetState,
    validateClient
} from "../../../stores/listui/clientRegisterStore";
import ConfirmDialog from "../../../components/ConfirmDialog";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import "../../../style/listui/ClientRegisterPage.scss"
import DeleteIcon from '@material-ui/icons/Delete';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import {makeStyles} from "@material-ui/core/styles";
import {setMessages} from "../../../stores/alertSnackbarStore";
import {unwrapResult} from "@reduxjs/toolkit";

const useStyles = makeStyles((theme) => ({
    control: (props) => ({
        width: "100%",
    }),
    headerGroup: {
        height: 50,
        width: "100%",
        transform: "translate(0, 0px) scale(1)",
        borderBottom: "1px solid rgba(0, 0, 0, 0.23)",
        zIndex: 10,
        paddingLeft: "0 !important",
        paddingTop: 7,
    },
    headerGroup_Label: {
        height: 50,
        display: "inline-flex",
        alignItems: "center",
        paddingLeft: 15,
        marginBottom: 5,
    },
    headerGroup_Button: {
        height: 50,
        display: "inline-flex",
        alignItems: "center",
        paddingLeft: 10,
        marginBottom: 5,
    },
    field: {
        "& .MuiInputBase-root": {
            paddingTop: 65,
        },
    },
    portalBtn: {
        color: "#3f51b5"
    },
    outline:{
        //border: "2px solid #3f51b5",
        //color: "#3f51b5",
        //backgroundColor: "#3f51b5",
    }
}))

const MODE = {
    REGISTER: 1,
    UPDATE: 2,
    DELETE: 3
}

const ClientRegisterPage: React.FC = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const history = useHistory()
    const viewData = useSelector(state => state.lisuiClientRegister)
    const {id} = useParams()
    const [open, setOpen] = React.useState(false);
    const [confirmDialog, setConfirmDialog] = useState({message: "", open: false, mode: MODE.REGISTER});
    const [IsSaveClick, setIsSaveClick] = useState(false)
    const [paginationInfo] = useState({
        pageNum: history.location.state && history.location.state.pageNum ? history.location.state.pageNum : 0,
        pageSize: history.location.state && history.location.state.pageSize? history.location.state.pageSize : 10,
        search: history.location.state && history.location.state.search? history.location.state.search : ""
    })

    React.useEffect(() => {
        if (id !== 'register') {
            dispatch(fetchClientDetail({id: id}))
                .then(unwrapResult)
                .catch(error => {
                    dispatch(setMessages([{severity: "error", message: "データの取得に失敗しました。"}]));
                })
        }
        // component will unmount
        return () => dispatch(resetState());
    }, [dispatch, id]);

    React.useEffect(() => {
        if (viewData.redirectPath && viewData.redirectPath.trim() !== '') {
            history.push({
                pathname: viewData.redirectPath,
                state: {
                    pageNum: paginationInfo.pageNum,
                    pageSize: paginationInfo.pageSize,
                    search: paginationInfo.search
                }
            })
        }
    }, [viewData.redirectPath, history, paginationInfo]);

    const getData = (label) => {
        return viewData.client[label] ? viewData.client[label] : "";
    }

    const getDisplayMedia = () => {
        let medias = viewData.client['media']
        if (medias) {
            return medias.map(media => {
                return media.media_name
            })
        }
        return []
    }

    const getListMediaID = () => {
        let medias = viewData.client['media']
        if (medias) {
            return medias.map(media => {
                return media.media_id
            })
        }
        return []
    }

    const getErrorFlg = (field) => {
        return viewData.fieldErrorFlg[field] ? viewData.fieldErrorFlg[field] : false
    }

    const getErrorTxt = (field) => {
        return viewData.fieldErrorTxt[field] ? viewData.fieldErrorTxt[field] : ''
    }

    const handleChange = (label, event) => {
        validateField(label, event.target.value)
        dispatch(changeClientData({key: label, value: event.target.value}));
    }

    const handleChangeMedia = (newMediaObjList) => {
        validateField("media", newMediaObjList)
        dispatch(changeClientData({key: "media", value: newMediaObjList}));
    }

    const handleClickSelectMedia = () => {
        setOpen(true);
    };

    const handleCloseSelectMedia = (value) => {
        setOpen(false);
    };

    const handleClickBack = () => {
        history.push({
            pathname: "/pages/listui/clients",
            state: {
                pageNum: paginationInfo.pageNum,
                pageSize: paginationInfo.pageSize,
                search: paginationInfo.search
            }
        })

        // history.goBack()
    }

    const handleClickDelete = () => {
        setConfirmDialog({message: "削除してもよろしいですか？", open: true, mode: MODE.DELETE})
    }

    const handleYes = () => {
        setConfirmDialog({...confirmDialog, open: false})
        if (confirmDialog.mode === MODE.DELETE) {
            dispatch(deleteClient({clientId: viewData.client.client_id}))
                .then(unwrapResult)
                .then(result => {
                    dispatch(setMessages([{severity: "success", message: "削除しました。"}]));
                })
                .catch(error => {
                    dispatch(setMessages([{severity: "error", message: "削除に失敗しました。"}]));
                });
        } else {
            dispatch(saveClient({client: viewData.client}))
                .then(unwrapResult)
                .then(result => {
                    dispatch(setMessages([{severity: "success", message: "保存しました。"}]));
                })
                .catch(error => {
                    dispatch(setMessages([{severity: "error", message: "保存に失敗しました。"}]));
                });
        }
    }

    const handleClickCallApi = () => {
        dispatch(validateClient())
        setIsSaveClick(true)
    }

    const isFormValid = () => {
        for (const [key, value] of Object.entries(viewData.fieldErrorFlg)) {
            if (key && value) return false
        }
        return true
    }

    const doClickSave = () => {
        if (isFormValid()) {
            if (id !== 'register') {
                setConfirmDialog({message: "更新してもよろしいですか？", open: true, mode: MODE.UPDATE})
            } else {
                setConfirmDialog({message: "登録してもよろしいですか？", open: true, mode: MODE.REGISTER})
            }
        } else {
            dispatch(setMessages([{severity: "error", message: "項目が必要です。"}]));
        }
    }

    if (IsSaveClick) {
        doClickSave()
        setIsSaveClick(false)
    }

    const validateField = (key, value) => {
        switch (key) {
            case "media":
                if (Object.keys(value).length === 0) {
                    triggerChangeError(key, true, "この項目は必須です。")
                } else {
                    triggerChangeError(key, false, "")
                }
                break
            default:
                if (value.trim() === '') {
                    triggerChangeError(key, true, "この項目は必須です。")
                } else {
                    triggerChangeError(key, false, "")
                }
        }
    }

    const triggerChangeError = (field, errorFlg, errorTxt) => {
        dispatch(changeErrorState({field: field, errorFlg: errorFlg, errorTxt: errorTxt}))
    }

    return (
        <div className="ClientRegister">
            <div className="BackBtn">
                <Button
                    variant="outlined"
                    onClick={handleClickBack}
                    startIcon={<KeyboardReturnIcon/>}
                >
                    一覧に戻る
                </Button>
            </div>
            <div className="ContentContainer">
                {id !== 'register' &&
                <div className="SubInfo">
                    <span>作成者：{getData("prc_user_cd")}</span>
                    <span>作成日：{getData("created_date")}</span>
                    <span>更新日：{getData("prc_date")}</span>
                </div>
                }

                <Paper variant="outlined" className="FormContent">
                    {id !== 'register' &&
                    <div className="DeleteBtn">
                        <Button
                            variant="outlined"
                            onClick={handleClickDelete}
                            startIcon={<DeleteIcon/>}
                        >削除する</Button>
                    </div>
                    }
                    <Grid container spacing={5}>
                        <Grid item xs={12}  >
                            <TextField variant="outlined" color="red" label="ログインID" value={getData("user_id")}
                                       onChange={(e) => {
                                           handleChange("user_id", e)
                                       }} error={getErrorFlg('user_id')} helperText={getErrorTxt('user_id')}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label="ユーザーパスワード" variant="outlined" color="primary" value={getData("user_pass")}
                                       onChange={(e) => {
                                           handleChange("user_pass", e)
                                       }} error={getErrorFlg('user_pass')} helperText={getErrorTxt('user_pass')}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label="ユーザー名" variant="outlined" color="primary" value={getData("user_name")}
                                       onChange={(e) => {
                                           handleChange("user_name", e)
                                       }} error={getErrorFlg('user_name')} helperText={getErrorTxt('user_name')}/>
                        </Grid>
                        {id === 'register' &&
                        <Grid item xs={12}>
                            <TextField label="ディレクトリ名" variant="outlined" color="primary" value={getData("dir_name")}
                                       onChange={(e) => {
                                           handleChange("dir_name", e)
                                       }} error={getErrorFlg('dir_name')} helperText={getErrorTxt('dir_name')}/>
                        </Grid>
                        }

                        <Grid item xs={12}>
                            <FormControl className={classes.control} error={getErrorFlg('media')}>
                                <InputLabel className={classes.headerGroup}>
                                    {/*<FormLabel className={classes.headerGroup_Label} component="legend"*/}
                                    {/*    // error={getErrorFlg('media')}*/}
                                    {/*>媒体名</FormLabel>*/}

                                    <div className={classes.headerGroup_Label}>媒体名</div>
                                    <div className={classes.headerGroup_Label}>
                                        <Button variant="contained" color="primary"
                                                onClick={handleClickSelectMedia}
                                        >選択する</Button>
                                    </div>
                                </InputLabel>
                                <TextField
                                    className={classes.field}
                                    variant="outlined"
                                    color="primary"
                                    multiline
                                    value={getDisplayMedia()}
                                    error={getErrorFlg('media')}
                                    helperText={getErrorTxt('media')}
                                />
                                <br/>

                                <SelectMediaDialog selectedValues={getListMediaID()} selectValueFn={handleChangeMedia}
                                                   open={open}
                                                   onClose={handleCloseSelectMedia}/>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <div className="ControlArea">
                        <Button className={classes.portalBtn} variant="contained"
                                onClick={handleClickBack}>キャンセル</Button>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleClickCallApi}
                        >{id === "register" ? "登録する" : "保存する"}</Button>
                    </div>
                    <ConfirmDialog
                        msg={confirmDialog.message}
                        isOpen={confirmDialog.open}
                        doYes={handleYes}
                        doNo={() => {
                            setConfirmDialog({...confirmDialog, open: false})
                        }}
                    />
                </Paper>
            </div>
        </div>
    )
}

export default ClientRegisterPage;