import React from "react";
import styles from "../style/loader.module.scss";
import {CircularProgress} from "@material-ui/core";

const Loader = () => {
  return (
    <div className={styles.loading}>
      <CircularProgress color="primary" />
    </div>
  );
};

export default Loader;
